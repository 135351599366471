import Modal from "./Modal";
import "./RequestDataUseWindow.css";
import ReactModal from "react-modal";
import RequestDataUseContent from "../Report/RequestDataUseContent";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * 자료 사용권 요청을 눌렀을 때 띄워주는 popup
 */
const PopupRequestReportLicense = ({ onSubmit, onCancel, onConfirm, className, applicationInfo }) => {
    const { t } = useTranslation();
    const ID = {
        dataUseName: "request-data-use-name-application",
        dataUseFiles: "request-data-use-files-application",
        dataUseFilesInput: "request-data-use-files-input-application",
        dataUseGroupName: "request-data-use-group-application",
        dataUseUserName: "request-data-use-username-application",
        dataUseUserContact: "request-data-use-user-contact-application",
        dataUseAffiliation: "request-data-use-affiliation-application",
        dataUseUserPosition: "request-data-use-user-position-application",
        dataUseRequestCause: "request-data-use-cause-application",
        dataUseUserEmail: "request-data-use-email-application",
    };

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={onClick}
            className={className || "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"request-data-use-modal"}
        >
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">
                {t('89')}
                <div>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={onConfirm ? onConfirm : onCancel}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
            <div className="body">
                <div id="request-data-use-application">
                    <RequestDataUseContent
                        ID={ID}
                        onCancel={onCancel}
                        applicationInfo={applicationInfo}
                        onConfirm={onConfirm}
                        onSubmit={async (files) => {
                            const payload = {
                                name: document.getElementById(ID.dataUseUserName).value,
                                email: document.getElementById(ID.dataUseUserEmail).value,
                                contact: document.getElementById(ID.dataUseUserContact).value,
                                cause: document.getElementById(ID.dataUseRequestCause).value,
                            };
                            if (files) payload.files = await Api.uploadFiles(files, false);
                            onSubmit(payload);
                        }}
                    />
                </div>
            </div>
        </ReactModal>
    );
};

/**
 * 자료 사용권을 신청했을 때 띄워주는 popup
 */
const PopupRequestedReportLicense = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const icon = <img src={process.env.PUBLIC_URL + `/icon_data_request.png`} srcSet={`${process.env.PUBLIC_URL}/icon_data_request@2x.png 2x`} alt="" />;
    const header = <div>{t('100')}</div>;
    const body = <>{t('101').split("\n").map((v, i) => <div key={i}>{v}</div>)}</>;

    return (
        <Modal
            onRequestClose={props.onRequestClose || (() => { navigate("/Mypage/RequestDataUse"); })}
            onConfirm={props.onConfirm || (() => { navigate("/Mypage/RequestDataUse"); })}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { PopupRequestReportLicense, PopupRequestedReportLicense };
