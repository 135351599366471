import React from "react";
import { useScript } from "../../common/customHook";
import { useTranslation } from "react-i18next";

const KakaoShareButton = () => {
    const { t } = useTranslation();
    useScript('https://developers.kakao.com/sdk/js/kakao.min.js');

    const shareKakao = () => {
        // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
        if (window.Kakao) {
            const kakao = window.Kakao;

            // 중복 initialization 방지
            if (!kakao.isInitialized()) {
                // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
                kakao.init(process.env.REACT_APP_KAKAO_KEY);
            }

            kakao.Share.sendDefault({
                // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
                objectType: 'feed',
                content: {
                    title: t('652'),
                    description: '',
                    imageUrl: `http://mud-kage.kakao.co.kr/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png`,
                    link: {
                        mobileWebUrl: "https://oceancloud.co.kr",
                        webUrl: "https://oceancloud.co.kr",
                    },
                },
                buttons: [
                    {
                        title: t('653'),
                        link: {
                            mobileWebUrl: "https://oceancloud.co.kr",
                            webUrl: "https://oceancloud.co.kr",
                        },
                    },
                ],
            });
        }
    };

    return (
        <button onClick={shareKakao}>
            {t('654')}
        </button>
    );
};

export default KakaoShareButton;
