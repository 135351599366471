import React from "react";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function ImageUploadPendingWindow ({onConfirm}) {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>
        {t('142')}
    </div>;
    const body = <>{t('630').split('\n').map((v, i) => <div key={i}>{v}</div>)}</>;

    return (
        <Modal
            onRequestClose={onConfirm}
            onConfirm={onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
}
