// see : https://slog.website/post/10
// see : https://www.bezkoder.com/react-refresh-token/
import axios from "axios";
import { error as E } from "@ocean-knight/shared";
import * as imageConversion from 'image-conversion';
import AWS  from "aws-sdk";
import { nanoid } from "nanoid";
import { getThumbnails } from 'video-metadata-thumbnails';
import { isIOS, isSafari } from 'react-device-detect';
import common from "./common/common";
import * as zipjs from "@zip.js/zip.js";
import dgLogger from "./common/dgLogger";

const Api = axios.create({
    withCredentials: true,
    transformResponse: [ (data) => {
        return typeof (data) === "string" ? JSON.parse(data.replace(/&lt;/gi, "<")) : data;
    }],
});

class ONException extends Error {
    constructor(code, message) {
        super(`response with code : ${code}, message : ${message}`);
        this.code = code;
    }
}

/**
 * parameter로 전달된 필터링 값에 매칭되는 그룹 목록을 획득하여 반환
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} _ids group의 _id array
 * @returns group document array
 */
const getFilterGroupList = async (payload = { _ids: [] }) => {
    const res = await Api.post("/v1/group/list/filter", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * (active 가 아닌 그룹 포함) 전체 그룹 목록을 획득하여 반환
 *
 * @returns 전체 그룹 목록
 */
const getAllGroupList = async () => {
    const res = await Api.post("/v1/group/list/all");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 웹사이트 로그인 수행
 *
 * @param {string} email email 주소
 * @param {string} password 비밀번호
 * @param {boolean} autoLogin 자동 로그인 여부
 *
 * @returns N/A
 */
const signIn = async (payload = { email: "", password: "", autoLogin: false }) => {
    const res = await Api.post("/v1/user/login", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 로그인 된 사용자의 정보를 갱신
 *
 * @param {boolean} autoLogin 자동 로그인 여부
 *
 * @returns N/A
 */
const refreshAuth = async (payload = { autoLogin: false }) => {
    const res = await Api.post("/v1/user/auto-login", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 로그인 된 사용자를 로그아웃 처리
 *
 * @returns N/A
 */
const logout = async () => {
    const res = await Api.post("/v1/user/logout");
    localStorage.removeItem("autoLogin");
    sessionStorage.clear();
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * parameter로 전달된 _id 값에 매칭되는 사용자 정보를 획득하여 반환
 *
 * @param {string} _id user의 _id
 * @param {string} email email 주소
 * @param {boolean} optPermissions permission 의 상세 정보를 포함해서 반환받을지 여부
 * @param {boolean} optGroups group 의 상세 정보를 포함해서 반환받을지 여부
 *
 * @returns 검색 된 사용자 정보
 */
const getUserInfo = async (payload = { _id: "", email: "", optPermissions: false, optGroups: false }) => {
    const res = await Api.post("/v1/user/info", payload); // _id 및 email 이 명시되지 않는다면, 자신의 정보를 획득
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * parameter로 전달된 정보로 현재(혹은 _id 로 지정한) 사용자 정보를 갱신하고, 갱신된 정보를 반환 (값이 명시된 field 만 갱신)
 *
 * @param {string} _id user의 _id
 * @param {string} email email 주소
 * @param {string} name 닉네임
 * @param {string} phone 휴대폰
 * @param {string} password 비밀번호
 * @param {string} address1 주소
 * @param {string} address2 나머지 주소
 * @param {string} about 자기소개
 * @param {string} sns sns 주소
 * @param {Object} profile_picture image file Object { name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', url: url }
 * @param {Object} organization 현재는 사용되지 않음 (set to null)
 *
 * @returns N/A
 */
const updateUserInfo = async (payload = {
    _id: "", email: "", name: "", phone: "", password: "",
    address1: "", address2: "", about: "", sns: "",
    profile_picture: null, organization: null
}) => {
    const res = await Api.post("/v1/user/update", payload); // _id 가 명시되지 않는다면, 자신의 정보를 갱신
    if (res.data.code === E.NOTUPDATED) {
        return false;
    }
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return true;
};

/**
 * 현재 로그인 된 사용자 정보를 획득하여 반환
 *
 * @param {boolean} optPermissions permission 의 상세 정보를 포함해서 반환받을지 여부
 * @param {boolean} optGroups group 의 상세 정보를 포함해서 반환받을지 여부
 *
 * @returns 현재 사용자 정보
 */
const getCurrentUserInfo = async (payload = { optPermissions: false, optGroups: false }) => {
    return await getUserInfo(payload);
};

/**
 * parameter로 전달된 필터링 값에 매칭되는 그룹원 목록을 획득하여 반환
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} name 검색할 그룹원 이름
 * @param {string[]} groups 검색할 그룹 목록
 * @param {string[]} grades 검색할 그룹 등급 목록
 * @param {string} startDate 자료 등록 시작일 filter
 * @param {string} endDate 자료 등록 종료일 filter
 * @param {boolean} isOrganization 자료활용 공익단체인 그룹원을 검색할지 여부
 * @param {number} page pagination 시 보는 페이지 번호
 * @param {Object} sort 정렬 기준
 * @param {Object} extraState state 를 갱신할때 spread 로 추가할 state
 *
 * @returns 검색 된 사용자 배열과 배열의 길이
 */
const getFilteredGroupMember = async (payload = { name: "", groups: [], grades: [], startDate: "", endDate: "", isOrganization: false, page: 0, sort: null, extraState: null }) => {
    const res = await Api.post("/v1/group/member/filter", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * parameter로 전달된 필터링 값에 매칭되는 사용자 목록을 획득하여 반환
 *
 * @param {string} name 검색할 그룹원 이름
 * @param {string[]} groups 검색할 그룹 목록
 * @param {string[]} grades 검색할 그룹 등급 목록
 * @param {boolean} isOrganization 자료활용 공익단체인 그룹원을 검색할지 여부
 * @param {number} page pagination 시 보는 페이지 번호
 * @param {Object} sort 정렬 기준
 * @param {Object} extraState state 를 갱신할때 spread 로 추가할 state
 *
 * @returns 응답으로 받은 데이터 (검색된 사용자 목록)
 */
const getFilteredUser = async (payload = { name: "", groups: [], grades: [], isOrganization: false, page: 0, sort: null, extraState: null }) => {
    const res = await Api.post("/v1/user/filter", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * parameter로 전달된 필터링 값에 매칭되는 사용자 이름 목록을 획득하여 반환
 *
 * @param {string} name 검색할 사용자 이름
 * @param {Object} sort 정렬 기준
 *
 * @returns 응답으로 받은 데이터 (검색된 사용자 이름 목록)
 */
const searchUserName = async (payload = { name: "", sort: null }) => {
    const res = await Api.post("/v1/user/searchName", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 그룹원(들) 에게 메일을 전송
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} users 메일을 받을 user의 _id array
 * @param {string} title 메일 제목
 * @param {string} contents 메일 본문
 * @param {file object[]} files 메일에 첨부 될 파일 array
 *
 * @returns N/A
 */
const sendMailToGroupMembers = async (payload = { users: [], title: "", contents: "", files: [] }) => {
    const config = {
        header: {
            "content-type": "multipart/form-data",
        },
    };

    const formData = new FormData();

    dgLogger.assert(payload.users && (payload.users.length > 0), "has no mail receiver(s)")();
    payload.users.forEach(user => formData.append('users', user));

    dgLogger.assert(payload.title, "has no mail title")();
    formData.append('title', payload.title);

    dgLogger.assert(payload.contents, "has no mail contents")();
    formData.append('contents', payload.contents);

    if ("files" in payload && payload.files.length > 0) {
        for (let i = 0; i < payload.files.length; ++i) formData.append("files", payload.files[i]);
    }

    const res = await Api.post("/v1/group/member/send-mail", formData, config);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};


/**
 * 지정된 그룹의 등급(들)을 변경(추가)
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} _id group id
 * @param {Object[]} permissions permission array
 * Object 의 format
 * ```
 *  _id: `${user.types._id}`, // if null, then will create new permission
 *  grade_name: string,
 *  grade_description: string,
 *  order: number,
 *
 * @return 변경(추가) 된 등급(들)의 정보
 */
const updateGroupPermissions = async (payload = { _id: "", permissions: null }) => {
    const res = await Api.post("/v1/group/update-permissions", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 그룹의 등급(들)을 삭제합
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} _id group id
 * @param {Object[]} permissions permission array
 *
 * permissions 의 format
 * ```
 *  _id: `${user.types._id}`, // if null, then will create new permission
 *  grade_name: string,
 *  grade_description: string,
 *  order: number,
 *
 * @return 삭제 된 후 남은 등급(들)의 정보
 */
const removeGroupPermissions = async (payload = { _id: "", permissions: [] }) => {
    const res = await Api.post("/v1/group/remove-permissions", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 그룹을 삭제합니다.
 *
 * Note. 사이트 관리자만 호출 가능
 *
 * @param {string} _id 삭제 할 group _id
 * @param {string} reason 그룹 삭제 사유
 *
 * @return N/A
 */
const removeGroup = async (payload = { _id: "", reason: "", lang: "ko" }) => {
    dgLogger.assert(payload.reason)();
    const res = await Api.post("/v1/group/remove", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 지정된 그룹원(들)의 등급을 변경
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 * @param {string[]} users  user의 _id array
 * @param {string} group group의 _id
 * @param {Object} permissions user_type의 _id
 *
 * permissions 의 format
 * ```
 *  _id: `${user.types._id}`, // if null, then will create new permission
 *  grade_name: string,
 *  grade_description: string,
 *  order: number,
 *
 * @returns N/A
 */
const updateGroupMembersPermission = async (payload = { users: [], group: "", permission: null }) => {
    const res = await Api.post("/v1/group/member/update-permissions", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 지정된 그룹원(들)을 그룹에서 탈퇴
 *
 * @param {string[]} users  user의 _id array
 * @param {string} group group의 _id
 * @param {string} reason 탈퇴 사유
 *
 * @returns N/A
 */
const withdrawGroupMembers = async (payload = { users: [], group: "", reason: "", lang: "ko" }) => {
    const res = await Api.post("/v1/group/member/withdraws", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 지정된 회원의 공익단체 요청 내역 갱신(승인/반려)
 *
 * @param {string[]} ids organization.approval.histories의 _id array
 * @param {string} state 갱신 될 상태 (utility.STATE)
 * @param {string} state_reason 갱신 사유
 *
 * @returns N/A
 */
const updateOrganizationHistory = async (payload = { ids: [], state: "", state_reason: "", lang: "ko" }) => {
    const res = await Api.post("/v1/user/organization/update-history", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 현재 사용자가 관리(Manager) 하는 그룹에 요청된 공익단체 생성(승인/반려) 요청 내역을 획득
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (공익단체 신청/승인/반려 내역)
 */
const getOrganizationHistoryAll = async (payload = { states: [], currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/user/organization/history/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 로그인 한 사용자가 신청했던 공익단체 생성(승인/반려) 요청 내역을 획득
 *
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (공익단체 신청/승인/반려 내역)
 */
const getOrganizationHistoryMine = async (payload = { currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/user/organization/history/mine", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 공익단체 생성 요청 정보를 획득
 * @param {string} _id organization.approval.histories의 _id
 *
 * @returns 응답으로 받은 데이터 (공익단체 신청 정보)
 */
const getOrganizationHistoryItem = async (_id = "") => {
    const res = await Api.get(`/v1/user/organization/history/${_id}`);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 user의 permission 중 가장 높은 permission을 탐색
 *
 * @returns permission
 */
const getCurrentUserMaxPermission = async () => {
    const payload = await getCurrentUserInfo({ optPermissions: true });
    const permissions = payload.optPermissions;
    const maxPermission = permissions.reduce((max, val) => (max.grade > val.grade ? max : val));
    return maxPermission;
};

/**
 * 지정된 회원을 사이트에서 탈퇴
 *
 * @param {string[]} users user의 _id array
 * @param {string} reason 탈퇴 사유
 * @param {boolean} banned 재가입 불가 여부
 *
 * @returns N/A
 */
const withdrawSiteMembers = async (payload = { users: [], reason: "", banned: false, lang: "ko" }) => {
    const res = await Api.post("/v1/user/withdraws", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 특정 그룹에 자료 사용권을 획득 할 수 있는 공익단체 생성 요청
 *
 * @param {string} groupId 신청 할 group의 _id
 * @param {string} name 이름
 * @param {string} affiliation 소속
 * @param {string} position 직급
 * @param {Object} certificate_files file Object {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', url: url}
 * @param {string} cause 신청 사유
 *
 * @returns N/A
 */
const requestOrgCreation = async (payload = { groupId: "", name: "", affiliation: "", certificate_files: null, cause: "" }) => {
    const res = await Api.post("/v1/user/organization/request",payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 현재 로그인 한 사용자가 관리하는 그룹의 멤버 신청(승인/반려) 내역 획득
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (그룹 멤버 신청/승인/반려 내역)
 */
const getGroupMemberHistoryAll = async (payload = { states: [], currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/group/member/history/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 로그인 한 사용자의 그룹 멤버 신청(승인/반려) 내역을 획득
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (그룹 멤버 신청/승인/반려 내역)
 */
const getGroupMemberHistoryMine = async (payload = { states: [], currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/group/member/history/mine", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 회원의 그룹 참여 요청 내역 갱신 (승인/반려)
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} ids group.member.approval.histories의 _id array
 * @param {string} state 갱신 될 상태 (utility.STATE)
 * @param {string} state_reason 갱신 사유
 *
 * @returns N/A
 */
const updateGroupMemberHistory = async (payload = { ids: [], state: "", state_reason: "", lang: "ko" }) => {
    const res = await Api.post("/v1/group/member/update-history", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 지정된 그룹에 참여 신청
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} _id group의 _id
 *
 * @returns N/A
 */
const requestJoinGroupMember = async (payload = { _id: "", lang: "ko" }) => {
    const res = await Api.post("/v1/group/member/join", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 모든 그룹 생성 요청(승인/반려) 내역을 획득
 *
 * Note. 사이트 관리자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (그룹 신청/승인/반려 내역)
 */
const getGroupHistoryAll = async (payload = { states: [], currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/group/history/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 로그인 한 사용자가 신청한 그룹 생성(승인/반려) 요청 내역을 획득
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (그룹 신청/승인/반려 내역)
 */
const getGroupHistoryMine = async (payload = { currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/group/history/mine", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 특정 그룹 생성(승인/반려) 상세 요청 내역을 획득
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} _id group.approval.histories의 _id
 */
const getGroupHistoryItem = async (_id = "") => {
    const res = await Api.get(`/v1/group/history/${_id}`);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 로그인 한 사용자가 속해있지 않은 그룹 목록을 획득
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {number} currentPage 페이지 번호, 0번인 경우 모든 item에 대해 검색
 * @param {number} itemsCountPerPage 한 페이지 출력 할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (그룹 목록)
 */
const getNotMemberedGroupList = async (payload = { currentPage: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/group/list/not-member", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 현재 로그인 한 사용자가 속해있는 그룹 목록 획득
 *
 * @param {*} payload 현재 사용하지 않음
 * @returns 응답으로 받은 데이터 (그룹 목록)
 */
const getMemberedGroupList = async (payload = {}) => {
    const res = await Api.post("/v1/group/list/member", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 지정된 그룹 생성 요청 내역 갱신 (승인/반려)
 *
 * Note. 사이트 관리자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string[]} ids group.approval.histories의 _id array
 * @param {string} state 갱신 될 상태 (utility.STATE)
 * @param {string} state_reason 갱신 사유
 *
 * @returns N/A
 */
const updateGroupHistory = async (payload = { ids: [], state: "", state_reason: "", lang: "ko" }) => {
    const res = await Api.post("/v1/group/update-history", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 그룹 생성 요청
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} name 그룹 이름
 * @param {string} user_name 유저 닉네임
 * @param {string} contact 연락처
 * @param {string} research 조사 대상
 * @param {string} rel_link 관련 링크
 * @param {Object[]} certificate_files 소속 증명 문서 file Object {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', url: url}
 * @param {Object[]} pictures 그룹 사진
 * @param {Object} representative_picture 그룹 대표 사진
 * @param {boolean} require_join_confirm 그룹 참여 승인 필요 여부
 * @param {string} about 그룹 소개
 * @param {string} cause 신청 사유
 *
 * @returns N/A
 */
const requestGroupCreation = async (payload = {
    name: "", user_name: "", contact: "", research: "", rel_link: "",
    certificate_files: [], pictures: [], representative_picture: null, require_join_confirm: false, about: "", cause: "",
    lang: "ko"
}) => {
    const res = await Api.post("/v1/group/create", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 중복된 그룹 이름이 있는지 확인
 * 중복된 그룹 이름이 있다면 error를 던지므로 catch에서 중복 되었음을 확인하도록 코드 구현 필요
 *
 * @param {string} name 그룹 이름
 *
 * @returns N/A
 */
const isDuplicatedGroupName = async (payload = { name: "" }) => {
    const res = await Api.post("/v1/group/duplicate-name", payload);
    if (res.data.code !== E.NOTFOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 중복된 (활동중인)사용자 이름이 있는지 확인
 * 중복된 (활동중인)사용자 이름이 있다면 error를 던지므로 catch에서 중복 되었음을 확인하도록 코드 구현 필요
 *
 * @param {string} name 그룹 이름
 *
 * @returns N/A
 */
const isDuplicatedUserName = async (payload = { name: "" }) => {
    const res = await Api.post("/v1/user/duplicate-name", payload);
    if (res.data.code !== E.NOTFOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 전달받은 이미지 파일의 크기를 변환하여 반환합니다.
 *
 * @param {Object} imageFile original image file
 * @param {number} thumbnailWidth thumbnail image width
 * @returns thumbnail image file object
 */
const __resizeImage = async (imageFile = null, resizeWidth = 0) => {
    if (!imageFile) return undefined;

    const imageFileUrl = URL.createObjectURL(imageFile);

    const resizeImage = await imageConversion.urltoImage(imageFileUrl);
    const resizeCanvas = await imageConversion.imagetoCanvas(resizeImage, { width: resizeWidth });
    const resizeFile = await imageConversion.canvastoFile(resizeCanvas);

    URL.revokeObjectURL(imageFileUrl);

    return resizeFile;
};

/**
 * parameter로 전달된 이미지 파일들 (썸네일,원본)을 Storage 서버에 저장
 *
 * Note. 서버에는 {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', thumbnail_url: url, watermark_url: url} 의 형식으로 저장
 *
 * @param {Object[]} imageInfos 원본 이미지 파일 및 워터마크 스트링과 thumb 이미지의 width 값을 포함하는 객체
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
 *      imageFile: `File`,
 *      thumbnailWidth: `thumbnail image width`,    // optional. 값이 없을경우, 300
 *      resizeWidth: `resize image width` // optional. 값이 없을경우, 1920
 *  }
 *  ```
 */
const uploadImage = async (imageInfo = {}) => {
    console.assert(Object.keys(imageInfo).length > 0);

    const thumbnailWidth = imageInfo.thumbnailWidth || 300;
    const resizekWidth = imageInfo.resizeWidth || 1920;
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });


    const imageFile = imageInfo.imageFile;
    if (!imageFile?.size) {
        console.log("Can't upload file due to a file size of 0");
        return;
    }

    const key = nanoid(32);
    const extension = `.${imageFile.name.split(".").pop()}`;

    let imageBlob = imageFile.type.startsWith("image/tiff") ? undefined : imageFile;
    let thumbnailKey = key + "_thumbnail" + extension;
    let watermarkKey = key + "_watermark" + extension;
    let resizeKey = key + "_resize" + extension;

    const thumbnailFile = await __thumbnailImage(imageBlob, thumbnailWidth);
    const resizeImageFile = await __resizeImage(imageBlob, resizekWidth);

    if (thumbnailFile) {
        await S3.putObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: thumbnailKey,
            ACL: 'public-read',
            // ACL을 지우면 전체 공개되지 않습니다.
            Body: thumbnailFile,
        }).promise();
    }

    if (resizeImageFile) {
        await S3.putObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: resizeKey,
            ACL: 'public-read',
            // ACL을 지우면 전체 공개되지 않습니다.
            Body: resizeImageFile,
        }).promise();
    }

    await S3.putObject({
        Bucket: process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET,
        Key: key + extension,
        Body: imageFile,
    }).promise();

    // file info = {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', thumbnail_url: url, watermark_url: url}
    const thumbnail_url = (thumbnailFile) ? `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${thumbnailKey}` : `${process.env.PUBLIC_URL}/nopreview.png`;
    const watermark_url = (thumbnailFile) ? `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${watermarkKey}` : `${process.env.PUBLIC_URL}/nopreview.png`;
    const resize_url = (thumbnailFile) ? `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${resizeKey}` : `${process.env.PUBLIC_URL}/nopreview.png`;
    const thumbnailPrivateUrl = common.getStorageUrl(thumbnailKey, process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET, 3600);

    const fileInfo = {
        name: imageFile.name,
        size: imageFile.size,
        key: key + extension,
        mime: imageFile.type,
        thumbnail_url: thumbnail_url,
        watermark_url: watermark_url,
        resize_url: resize_url,
        resizeImageSize: resizeImageFile?.size || 0,
        url: thumbnailPrivateUrl,
        no_preview: !thumbnailFile
    };

    return fileInfo;
};

/**
 * 전달받은 이미지 파일을 썸네일(파일 객체)로 변환하여 반환합니다.
 *
 * @param {Object} imageFile original image file
 * @param {number} thumbnailWidth thumbnail image width
 * @returns thumbnail image file object
 */
const __thumbnailImage = async (imageFile = null, thumbnailWidth = 0) => {
    if (!imageFile) return undefined;

    const imageFileUrl = URL.createObjectURL(imageFile);

    const thumbnailImage = await imageConversion.urltoImage(imageFileUrl);
    const thumbnailCanvas = await imageConversion.imagetoCanvas(thumbnailImage, { width: thumbnailWidth });
    const thumbnailFile = await imageConversion.canvastoFile(thumbnailCanvas);
    thumbnailFile.name = `thumbnail__${imageFile.name}`;

    URL.revokeObjectURL(imageFileUrl);

    return thumbnailFile;
};

/**
  * Uses canvas.measureText to compute and return the font in pixels.
  * desiredWidth 에 가능한 꽉 채우는 font size 를 계산해서 반환합니다.
  *
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  * @see https://www.demo2s.com/javascript/javascript-canvas-draw-text-to-fit-size.html
  *
  * @param {string} text 워터마크 정보
  * @param {string} fontFace font 정보
  * @param {number} min font의 최소 크기
  * @param {number} max font의 최대 크기
  * @param {number} desiredWidth 원하는 watermark 폰트 크기
  */
const _getFontSizeToFillFullWidth = (text="", fontFace, min, max, desiredWidth) => {
    // re-use canvas object for better performance
    const canvas = _getFontSizeToFillFullWidth.canvas || (_getFontSizeToFillFullWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");

    const measure = (min, max) => {
        if (max - min < 1) return min;
        const size = min + ((max - min) / 2);
        context.font = `${size}px ${fontFace}`;
        const metrics = context.measureText(text);
        if (metrics.width > desiredWidth) {
            return measure(min, size);
        } else {
            return measure(size, max);
        }
    };

    return measure(min, max);
};

/**
 * 전달받은 이미지 파일에 워터마크 정보를 포함한 이미지 (파일 객체) 로 변환하여 반환합니다.
 *
 * @param {*} imageFile original image file
 * @param {string[]} watermarkText 이미지에 출력할 워터마크 정보 (texts)
 * @param {*} watermarkWidth 이미지 width
 * @returns watermark processed image file object
 */
 const __watermarkedImage = async (imageFile, watermarkText, watermarkWidth = 0) => {
    if (!imageFile) return undefined;

    const imageFileUrl = URL.createObjectURL(imageFile);

    const watermarkImage = await imageConversion.urltoImage(imageFileUrl);
    watermarkWidth = watermarkWidth > watermarkImage.width ? watermarkImage.width : watermarkWidth;
    const watermarkCanvas = await imageConversion.imagetoCanvas(watermarkImage, { width: watermarkWidth });
    const watermarkCanvasCtx = watermarkCanvas.getContext("2d");

    // 워터마크 정보 출력시, 이미지를 벗어나는 경우, 이미지를 벗어나지 않는 크기로 fontsize 를 조절합니다.
    const lines = watermarkText.length;

    const width = watermarkCanvas.width;
    const height = watermarkCanvas.height;

    const linePad = 5; // (each line) bottom padding
    const rightMargin = 10;
    const bottomMargin = 10;
    const fontFace = "sans-serif";

    let fontSize = 48;
    let requiredHeight = height;

    // 한글의 경우, 영문글자보다 width 가 더 큽니다. 따라서, 글자 수만으로 width 계산을 할수 없습니다.
    // 모든 text 에 대해 canvas 를 넘치지 않는 크기를 체크해야 합니다.
    watermarkText.forEach(text => {
        // 좌/우 역백이 남도록 처리 (화면의 좌측 1/3 은 남기도록 처리) 할때 fontSize
        fontSize = _getFontSizeToFillFullWidth(text, fontFace, 9, fontSize, 0.7 * width + rightMargin * 2);
        const height = lines * (fontSize + linePad) + bottomMargin * 2; // 상/하 여백이 남도록 처리
        requiredHeight = (requiredHeight > height) ? height: requiredHeight;
    });

    watermarkCanvasCtx.textBaseline = "bottom";
    watermarkCanvasCtx.textAlign = "right";
    watermarkCanvasCtx.fillStyle = "white";
    watermarkCanvasCtx.font = `${fontSize}px ${fontFace}`;

    const rectY = height - requiredHeight - bottomMargin * 2;

    // 반투명 바탕화면
    watermarkCanvasCtx.fillStyle = 'rgba(0,0,0,0.3)';
    watermarkCanvasCtx.fillRect(0, rectY, width, requiredHeight + (bottomMargin * 2));

    // bottom 부터 top 방향으로 text 를 출력하므로, 현재 watermarkText (list) 의 끝부터 역순으로 출력되어야 합니다.
    // 따라서, watermarkText (list) 를 reverse 하여 출력합니다.
    watermarkCanvasCtx.fillStyle = 'rgba(225,225,225,1)';
    watermarkText.reduce((acc, cur) => [cur, ...acc], []).forEach((watermark, i) => {
        // rectangle 안에 들어갈 위치
        let watermarkXPos = watermarkCanvas.width - rightMargin; // base x pos
        let watermarkYPos = watermarkCanvas.height - (bottomMargin + (linePad + fontSize) * i);

        // right / bottom margin 적용한 위치
        watermarkXPos -= rightMargin;
        watermarkYPos -= bottomMargin;

        watermarkCanvasCtx.fillText(watermark, watermarkXPos, watermarkYPos);
    });

    const watermarkFile = await imageConversion.canvastoFile(watermarkCanvas);
    watermarkFile.name = `watermark__${imageFile.name}`;

    URL.revokeObjectURL(imageFileUrl);

    return watermarkFile;
};

/**
 * parameter로 전달된 이미지 파일들 (썸네일,워터마크처리,원본)을 Storage 서버에 저장
 *
 * Note. 서버에는 {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', thumbnail_url: url, watermark_url: url} 의 형식으로 저장
 *
 * @param {Object[]} imageInfos 원본 이미지 파일 및 워터마크 스트링과 thumb/watermarked 이미지의 width 값을 포함하는 객체 list
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  [{
 *      imageFile: `File`,
 *      watermarkText: [`워터마크`, `텍스트`...],
 *      thumbnailWidth: `thumbnail image width`,    // optional. 값이 없을경우, 300
 *      watermarkWidth: `watermark image width`     // optional. 값이 없을경우, 1920
 *  }, ...]
 *  ```
 * @param {function} progress 업로드 진행상황을 확인할 수 있는 callback (업로드한 size in byte, 전체 size in byte)
 */
const uploadImages = async (imageInfos = [], progress = undefined) => {
    dgLogger.assert(imageInfos && imageInfos.length > 0)();

    const defaultThumbnailWidth = 300;
    const defaultWatermarkWidth = 1920;
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const filesInfo = [];
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    let uploaded = 0;
    const totalSizeToUpload = imageInfos.reduce((a, v) => a + v.imageFile?.size || 0, 0);

    for (let i = 0; i < imageInfos.length; ++i) {
        const imageFile = imageInfos[i].imageFile;
        if (!imageFile?.size) continue; // if no proper file, skip it
        const watermarkText = imageInfos[i].watermarkText;
        const watermarkWidth = imageInfos[i].watermarkWidth;
        const thumbnailWidth = imageInfos[i].thumbnailWidth;

        // ref: https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
        // append it with 'name', 'value', 'filename'

        // notes, redkur;
        // we should set filename in append operation (without it, filename will be set as 'blob')

        const key = nanoid(32);
        const extension = `.${imageFile.name.split(".").pop()}`;

        let imageBlob = imageFile;
        let thumbnailKey = key + "_thumbnail" + extension;
        let watermarkKey = key + "_watermark" + extension;

        const thumbnailFile = await __thumbnailImage(imageBlob, thumbnailWidth ? thumbnailWidth : defaultThumbnailWidth);
        const watermarkFile = await __watermarkedImage(imageBlob, watermarkText, watermarkWidth ? watermarkWidth : defaultWatermarkWidth);

        if (thumbnailFile) {
            await S3.putObject({
                Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
                Key: thumbnailKey,
                ACL: 'public-read',
                // ACL을 지우면 전체 공개되지 않습니다.
                Body: thumbnailFile,
            }).promise();
        }

        if (watermarkFile) {
            await S3.putObject({
                Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
                Key: watermarkKey,
                ACL: 'public-read',
                // ACL을 지우면 전체 공개되지 않습니다.
                Body: watermarkFile,
            }).promise();
        }

        await S3.putObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET,
            Key: key + extension,
            Body: imageFile,
        })
        // eslint-disable-next-line no-loop-func
        .on('httpUploadProgress', ({loaded, _total}) => progress && progress((uploaded + loaded), totalSizeToUpload))
        // eslint-disable-next-line no-loop-func
        .on('complete', () => (uploaded = uploaded + imageFile.size))
        .on('retry', () => dgLogger.warn("retry upload file => " + key + extension)())
        .on('error', (e) => dgLogger.error(e)())
        .promise();

        // file info = {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', thumbnail_url: url, watermark_url: url}
        const thumbnail_url = (thumbnailFile) ? `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${thumbnailKey}` : `${process.env.PUBLIC_URL}/nopreview.png`;
        const watermark_url = (watermarkFile) ? `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET}/${watermarkKey}` : `${process.env.PUBLIC_URL}/nopreview.png`;

        filesInfo.push({
            name: imageFile.name,
            size: imageFile.size,
            key: key + extension,
            mime: imageFile.type,
            thumbnail_url: thumbnail_url,
            watermark_url: watermark_url,
        });
    }

    return filesInfo;
};

/**
 * parameter로 전달된 이미지 파일 (워터마크처리) 을 Storage 서버에 저장
 *
 * Note. 서버에는 {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', thumbnail_url: url, watermark_url: url} 의 형식으로 저장
 *
 * @param {Object[]} imageInfos 원본 이미지 파일 및 워터마크 스트링과 thumb/watermarked 이미지의 width 값을 포함하는 객체 list
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  [{
 *      name: name,
 *      size: size,
 *      key: key,
 *      mime: type,
 *      thumbnail_url: thumbnail_url,
 *      watermark_url: watermark_url,
 *      resize_url: resize_url,
 *      watermarkText: [`워터마크`, `텍스트`...],
 *      url: thumbnailPrivateUrl
 *  }, ...]
 *  ```
 * @param {function} progress 업로드 진행상황을 확인할 수 있는 callback (업로드한 size in byte, 전체 size in byte)
 */
const uploadWatermarkImage = async (imageInfos = [], progress = undefined) => {
    console.assert(imageInfos && imageInfos.length > 0);

    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    let uploaded = 0;
    const totalSizeToUpload = imageInfos.reduce((a, v) => a + v.file?.size || 0, 0);

    for (const imageInfo of imageInfos) {
        const imageFile = await fetch(imageInfo.resize_url).then((r) => r.blob());
        if (!imageFile) continue;
        const watermarkText = imageInfo.watermarkText;

        const watermarkFile = await __watermarkedImage(imageFile, watermarkText);

        await S3.deleteObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: imageInfo.resize_url.split('/').pop(),
        }).promise();

        await S3.putObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: imageInfo.watermark_url.split('/').pop(),
            ACL: 'public-read',
            // ACL을 지우면 전체 공개되지 않습니다.
            Body: watermarkFile,
        })
            // eslint-disable-next-line no-loop-func
            .on('httpUploadProgress', ({ loaded, _total }) => progress && progress((uploaded + loaded), totalSizeToUpload))
            // eslint-disable-next-line no-loop-func
            .on('complete', () => (uploaded = uploaded + imageFile.size))
            .on('retry', () => console.warn("retry upload file => " + imageInfo.name))
            .on('error', (e) => console.error("errored => " + e.tostring()))
            .promise();
    }

    return uploaded;
};

/**
 * parameter로 전달받은 key 이름의 field 로 file list 업로드
 *
 * @param {Object[]} files array of input files
 * @param {boolean} publicBucket public bucket을 사용 할 것인지 여부
 *
 * @returns {array} file info array
 */
const uploadFiles = async (files = [], publicBucket = true) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const bucketName = publicBucket ? process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET : process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET;
    const filesInfo = [];
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    for (let i = 0; i < files.length; ++i) {
        const key = nanoid(32);
        const extension = files[i]?.name ? `.${files[i]?.name?.split(".").pop()}` : "";

        await S3.putObject({
            Bucket: bucketName,
            Key: key + extension,
            ACL: publicBucket ? 'public-read' : undefined,
            // ACL을 지우면 전체 공개되지 않습니다.
            Body: files[i]
        }).promise();

        // file info = {name: 'file_name' size: 'file_size', key: file name in storage server, mime: 'file_type', url: url}
        filesInfo.push({
            name: files[i].name,
            size: files[i].size,
            key: key + extension,
            mime: files[i].type,
            url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${bucketName}/${key + extension}`,
        });
    }

    return filesInfo;
};

const uploadFile = async (file, key, publicBucket = true) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const bucketName = publicBucket ? process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET : process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET;
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    await S3.putObject({
        Bucket: bucketName,
        Key: key,
        ACL: publicBucket ? 'public-read' : undefined,
        // ACL을 지우면 전체 공개되지 않습니다.
        Body: file
    }).promise();

    return {
        name: file.name,
        size: file.size,
        key: key,
        mime: file.type,
        url: `${process.env.REACT_APP_NAVER_CLOUD_ENDPOINT}/${bucketName}/${key}`,
    };
};

/**
 * 레포트에 첨부된 파일들 원본 (이 포함된 압축파일) 을 획득
 *
 * @param {string[]} ids report의 _id 목록,
 * @param {string} lang 화면 출력 언어 (ko/en)
 * @param {boolean} isExcelonly 다운로드를 파일 없이 excel만 다운로드 하는 지 판단하는 flag
 *
 * @return {[string, blob]} 다운로드할 파일 이름 및 blob
 */
const downloadReports = async (payload = {ids: [], lang: "ko"}, isExcelonly = false) => {
    const res = await Api.post("/v1/report/download", payload, { responseType: 'blob' });

    const auth = res.headers['x-oknight-file-auth'];
    const date = res.headers['x-oknight-file-date'];
    const filename = res.headers['x-oknight-file-name'];

    if (!isExcelonly) {
        const fileInfoList = await getFileInfoList({ ids: payload.ids, lang: payload.lang });
        const name = `statistics_and_raw_data_files_requested_by_${auth}_at_${date}.zip`;

        const zipWriter = new zipjs.ZipWriter(new zipjs.BlobWriter("application/zip"));

        await zipWriter.add(filename, new zipjs.BlobReader(res.data));

        await Promise.all(fileInfoList.map(async file => {
            const reportID = file.reportID;
            const fileInfo = file.info;
            const reportTitle = file.reportTitle;
            const url = common.getStorageUrl(fileInfo.key, process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET, 60);
            const response = await axios.get(url, { responseType: 'arraybuffer' });
            const buffer = new Uint8Array(response.data);
            await zipWriter.add(`${reportTitle}_${reportID}/${fileInfo.name}`, new zipjs.Uint8ArrayReader(buffer));
        }));

        const zip = await zipWriter.close();

        return [name, zip];
    }
    return [filename, res.data];
};

/**
 * 레포트에 첨부된 파일 원본 (이 포함된 압축파일) 을 획득
 *
 * @param {string} _id report의 _id
 *
 * @return {[string, blob]} 다운로드할 파일 이름 및 blob
 */
const downloadReport = async (_id, lang = "ko") => {
    return downloadReports({ids: [_id], lang: lang});
};

/**
 * 위도와 경도를 이용하여 실제 주소를 찾아 반환
 *
 * @param {string} latlng 위도,경도 로 이루어진 string
 *
 * @returns 위도 경도에 해당하는 실제 주소
 */
const reverseGeocode = async (latlng) => {
    return await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params:{
            latlng: latlng,
            location_type: 'ROOFTOP|APPROXIMATE',
            result_type: 'street_address|political',
            language: 'ko',
            key: process.env.REACT_APP_GOOGLE_API_KEY,
        }
    })
        .then((response) => {
            const code = response.data.results[0]?.formatted_address?.split?.(' ');
            if (code && code[0] === "대한민국") {
                return code[1] || "기타";
            }
            else {
                return "기타";
            }
        })
    .catch((error) => dgLogger.error(error)());
};

/**
 * 실제 주소를 이용하여 주소 정보(위도, 경도 등)를 찾아 반환
 *
 * @param {string} address 실제 주소
 *
 * @returns 주소 정보
 */
const geocode = async (address) => {
    return await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params:{
            address: address,
            language: 'ko',
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            region: 'KR',
        }
    }).then((response => response.data.results))
    .catch((error) => dgLogger.error(error)());
};

/**
 * 지도 기반 표시에서 사용 될 자료를 반환
 * parameter 중 있는 key만 사용하여 filter
 *
 * @param {string[]} groups 그룹 필터에서 선택된 group의 _id array
 * @param {string[]} projects 프로젝트 필터에서 선택된 project의 _id array
 * @param {string[]} locations 지역 필터에서 선택된 지역 이름 array
 * @param {string} startDate 기간 필터에서 선택된 시작 날짜
 * @param {string} endDate 기간 필터에서 선택된 종료 날짜
 * @param {string} registered_by 조사자 필터에서 입력한 이름
 * @param {string} keyword 자료 검색 필터에 입력한 검색어
 * @param {string} user_id user의 _id
 *
 * @returns filter 된 report array
 */
const getReportsMap = async (payload = {
    groups: [], projects: [], locations: [], startDate: "", endDate: "",
    registered_by: "", keyword: "", page: "", itemsCountPerPage: 0, user_id: ""
}) => {
    const res = await Api.post("/v1/report/map", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 임의의 사용자를 생성, 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
const devAutoGenerateUser = async () => {
    const res = await Api.post("/v1/user/dev/auto-generate-user");
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 현재 사용자를 임의의 그룹 매니저로 등록 (그룹은 랜덤하게 선택), 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
const devMakeUser2RandomGroupManager = async () => {
    const res = await Api.post("/v1/user/dev/make-user-to-random-group-manager");
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 임의의 사용자(들)을 임의의 그룹 멤버로 등록 (사용자 및 그룹은 랜덤하게 선택), 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
 const devMakeUsers2RandomGroupMember = async () => {
    const res = await Api.post("/v1/user/dev/make-users-to-random-group-member");
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 현재 사용자를 임의의 그룹 멤버로 등록 (그룹은 랜덤하게 선택), 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
const devMakeUser2RandomGroupMember = async () => {
    const res = await Api.post("/v1/user/dev/make-user-to-random-group-member");
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 임의의 공익 단체를 생성, 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
const devMakeRandomOrganization = async () => {
    const res = await Api.post("/v1/user/dev/make-random-organization");
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 랜덤한 그룹이하에 랜덤한 정보로 프로젝트 (10개) 생성, 테스트를 위해 사용 되는 함수로 실 서버와 무관
 */
const devMakeProjectsOnRandomGroup = async () => {
    const res = await Api.post("/v1/dev/make-random-projects");
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 목록 기반 표시에서 사용 될 자료를 반환
 * parameter 중 있는 key만 사용하여 filter
 *
 * @param {string[]} groups 그룹 필터에서 선택된 group의 _id array
 * @param {string[]} projects 프로젝트 필터에서 선택된 project의 _id array
 * @param {string[]} locations 지역 필터에서 선택된 지역 이름 array
 * @param {string} startDate 기간 필터에서 선택된 시작 날짜
 * @param {string} endDate 기간 필터에서 선택된 종료 날짜
 * @param {string} registered_by 조사자 필터에서 입력한 이름
 * @param {string} keyword 자료 검색 필터에 입력한 검색어
 * @param {string} page 검색 할 page number
 * @param {number} itemsCountPerPage 한 화면에 출력 될 item 개수
 * @param {string} user_id user의 _id
 *
 * @returns filter 된 report array
 */
const getReportsList = async (payload = {
    groups: [], projects: [], locations: [], startDate: "", endDate: "",
    registered_by: "", keyword: "", page: "", itemsCountPerPage: 0, user_id: ""
}) => {
    const res = await Api.post("/v1/report/list", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 목록 기반 표시에서 사용 될 자료를 반환
 * parameter 중 있는 key만 사용하여 filter
 *
 * @param {string[]} groups 그룹 필터에서 선택된 group의 _id array
 * @param {string[]} projects 프로젝트 필터에서 선택된 project의 _id array
 * @param {string[]} locations 지역 필터에서 선택된 지역 이름 array
 * @param {string} startDate 기간 필터에서 선택된 시작 날짜
 * @param {string} endDate 기간 필터에서 선택된 종료 날짜
 * @param {string} registered_by 조사자 필터에서 입력한 이름
 * @param {string} keyword 자료 검색 필터에 입력한 검색어
 * @param {string} page 검색 할 page number
 * @param {string} field 요청할 필드
 *     - "count_per_year": 연 단위 수집 자료 건수
 *     - "count_per_month": 월 단위 수집 자료 건수
 *     - "count_per_location": 지역별 수집 자료 건수
 *     - "count_per_group": 그룹별 수집 자료 건수
 *     - "sum_trash_amount_per_location": (테라 나이츠) 지역별 해양쓰레기 구간 합(L) 데이터셋
 *     - ObjectId: 해당 필드의 값을 기반으로 한 그래프 데이터
 *     - "" or undefined: 요청 가능한 필드 목록
 * @param {string} user_id user의 _id
 * @param {string} lang 화면 출력 언어 (ko/en)
 *
 * @returns filter 된 report array
 */
const getReportsGraph = async (payload = {
    groups: [], projects: [], locations: [], startDate: "", endDate: "",
    registered_by: "", keyword: "", page: "", field: "", user_id: "",
    lang: "ko"
}) => {
    const res = await Api.post("/v1/report/graph", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};


/**
 * 다운로드 기반 표시에서 사용 될 자료를 반환
 * parameter 중 있는 key만 사용하여 filter
 *
 * @param {string[]} groups 그룹 필터에서 선택된 group의 _id array
 * @param {string[]} projects 프로젝트 필터에서 선택된 project의 _id array
 * @param {string[]} locations 지역 필터에서 선택된 지역 이름 array
 * @param {string} startDate 기간 필터에서 선택된 시작 날짜
 * @param {string} endDate 기간 필터에서 선택된 종료 날짜
 * @param {string} registered_by 조사자 필터에서 입력한 이름
 * @param {string} keyword 자료 검색 필터에 입력한 검색어
 * @param {number} skip 정렬 된 자료 중 skip 할 개수
 * @param {number} limit 최대로 가지고 올 수 있는 자료의 개수
 * @param {number} itemsCountPerPage 한 화면에 출력 될 item 개수
 * @param {string} user_id user의 _id
 *
 * @returns filter 된 report array
 */
const getDownloadableReportsList = async (payload = {
    groups: [], projects: [], locations: [], startDate: "", endDate: "",
    registered_by: "", keyword: "", skip: 0, limit: 0, itemsCountPerPage: 0, user_id: ""
}) => {
    const res = await Api.post("/v1/report/downloadable-list", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 프로젝트 추가(수정) 요청
 *
 * @param {string} _id project의 _id, 비어있다면 새롭게 추가 값이 있다면 수정
 * @param {string} participatingGroup 프로젝트가 포함 될 group의 _id
 * @param {string} name 프로젝트 이름
 * @param {string} startDate 프로젝트 시작 날짜
 * @param {string} endDate 프로젝트 종료 날짜
 * @param {string} projectArea 프로젝트 지역
 * @param {string} about 프로젝트 소개
 * @param {Object[]} pictures 프로젝트 사진 file Object array
 * @param {Object} representativePicture 프로젝트 대표 사진 Object
 *
 * @returns 프로젝트 추가(수정) 된 project의 _id
 */
const requestProjectCreation = async (payload = {
    _id: "", participatingGroup: "", name: "", startDate: "", endDate: "",
    projectArea: "", about: "", pictures: [], representativePicture: null,
}) => {
    const res = await Api.post("/v1/project/create", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 그룹 상세 정보 획득
 *
 * Note. 모든 사용자 호출 가능
 *
 * @param {string} _id group의 _id
 * @param {boolean} optManagers 매니저(들) 정보를 포함해서 반환할지 여부
 * @param {boolean} optActive 활성화 된 그룹만 반환할지 여부
 *
 * @returns 응답으로 받은 데이터 (그룹 정보)
 */
const getGroupItem = async (payload = { _id: "", optManagers: false, optActive: true, }) => {
    dgLogger.assert(payload._id, "_id is essential field")();
    const res = await Api.post(`/v1/group/info`, payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 그룹의 그룹 매니저 목록을 획득 (가입순 정렬)
 *
 * @param {string} _id group의 _id
 *
 * @returns 응답으로 받은 데이터 (그룹 매니저 정보)
*/
const getGroupManagers = async (payload = { _id: "" }) => {
    dgLogger.assert(payload._id, "_id is essential field")();
    const res = await Api.post(`/v1/group/managers`, payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 그룹 상세 정보를 갱신
 *
 * Note. 그룹 매니저 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {string} _id group의 _id
 * @param {Object[]} pictures 그룹 사진
 * @param {Object} representative_picture 그룹 대표 사진
 * @param {Ojbect} emblem_picture 그룹의 엠블렘 사진
 * @param {string} owner 그룹 대표 매니저의 _id
 * @param {string} research 조사 대상
 * @param {string} link 관련 링크
 * @param {boolean} require_join_confirm 그룹 참여 승인 필요 여부
 * @param {string} about 그룹 소개
 *
 * @returns 응답으로 받은 데이터 (갱신된 그룹 정보)
 */
const updateGroupItem = async (payload = {
    _id: "", pictures: [], representative_picture: null, emblem_picture: null,
    owner: "", research: "", link: "", require_join_confirm: false, abuot: ""
}) => {
    dgLogger.assert(payload._id, "_id is essential field")();
    const res = await Api.post(`/v1/group/update`, payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 report의 모든 정보를 반환
 *
 * @param {string} id report의 _id
 *
 * @returns report의 정보
 */
const getReportsItem = async (id) => {
    const res = await Api.get(`/v1/report/${id}`);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 Report 를 삭제 (DB 에서 삭제)
 * @param {*} _id report의 _id
 *
 * @returns N/A
 */
const removeReport = async (_id) => {
    const res = await Api.post(`/v1/report/remove-report`, {_id:_id});
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 문의하기 메일 발송
 *
 * @param {string} type 메일을 보낼 주체
 * @param {string} name 메일 보내는 사람의 이름
 * @param {string} title 메일 제목
 * @param {string} email 회신 받고자 하는 이메일
 * @param {string} content 메일 내용
 * @param {Object[]} files file object array
 *
 * @returns 성공 message
 */
const sendAskQuestion = async (payload = { type: "", name: "", title: "", email: "", content: "", files: null }) => {
    const config = {
        header: {
            "content-type": "multipart/form-data",
        },
    };

    const formData = new FormData();

    formData.append('type', payload.type);
    formData.append('name', payload.name);
    formData.append('title', payload.title);
    formData.append('email', payload.email);
    formData.append('content', payload.content);

    if ("files" in payload && payload.files.length > 0) {
        for (let i = 0; i < payload.files.length; ++i) formData.append("files", payload.files[i]);
    }

    const res = await Api.post("/v1/more/send-ask-question", formData, config);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 저장 된 약관 및 정책 반환
 *
 * @returns 약관 및 정책 HTML Tag
 */
const loadPrivacyPolicy = async (payload = { lang: "ko"}) => {
    const res = await Api.post("/v1/more/load-privacy-policy", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 약관 및 정책 저장
 *
 * @param {string} HTMLData editor로 작성 된 HTML Tag
 *
 * @returns 새롭게 저장 된 약관 및 정책 HTML Tag
 */
const savePrivacyPolicy = async (payload = { HTMLData: "", lang: "ko" }) => {
    const res = await Api.post("/v1/more/save-privacy-policy", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 저장 된 사이트 소개 반환
 *
 * @param {string} lang 얻어올 언어
 * @returns 사이트 소개 HTML Tag
 */
const loadIntroduction = async (payload = {lang: "ko"}) => {
    const res = await Api.post("/v1/more/load-introduction", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 저장 된 이메일 무단 수집 거부 반환
 *
 * @returns 이메일 무단 수집 거부 HTML Tag
 */
const loadAntiEmail = async (payload = {lang: "ko"}) => {
    const res = await Api.post("/v1/more/load-anti-email", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 사이트 소개 저장
 *
 * @param {string} HTMLData editor로 작성 된 HTML Tag
 *
 * @returns 새롭게 저장 된 사이트 소개 HTML Tag
 */
const saveIntroduction = async (payload = { HTMLData: "", lang: "ko" }) => {
    const res = await Api.post("/v1/more/save-introduction", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 이메일 무단 수집 거부 저장
 *
 * @param {string} HTMLData editor로 작성 된 HTML Tag
 *
 * @returns 새롭게 저장 된 이메일 무단 수집 거부 HTML Tag
 */
const saveAntiEmail = async (payload = { HTMLData: "", lang: "ko" }) => {
    const res = await Api.post("/v1/more/save-anti-email", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 전체 (active) 그룹 목록을 획득하여 반환
 *
 * Note. 모든 사용자 호출 가능
 *
 * @param {boolean} optManagers 매니저(들) 정보를 포함해서 반환할지 여부
 *
 * @returns 응답으로 받은 데이터 (전체 (active) 그룹 목록)
 */
const getActiveGroupListAll = async (payload = { optManagers: false }) => {
    const res = await Api.post("/v1/group/active-list/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 단체 요청 history가 없는 (active) 그룹 목록 반환
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @returns 응답으로 받은 데이터 (전체 그룹 목록)
 */
const getNotOrganizationGroupList = async () => {
    const res = await Api.post("/v1/group/not-organization-requested-list");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 로그인한 사용자가 관리하는 (active) 그룹 목록 반환
 *
 * Note. 일반 사용자 이상의 권한이 있는 사용자만 호출 가능
 *
 * @param {boolean} optManagers 매니저(들) 정보를 포함해서 반환할지 여부
 *
 * @returns 응답으로 받은 데이터 (전체 (active) 그룹 목록)
 */
const getActiveGroupListMine = async (payload = { optManagers: false }) => {
    const res = await Api.post("/v1/group/active-list/mine", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 예정된 (before start_date) 프로젝트 목록을 반환
 *
 * Note. 모든 사용자 호출 가능
 *
 * @param {string} group group의 _id, null 인 경우 모든 group 에 속한 project 획득
 * @param {boolean} optGroup group 정보를 포함해서 반환할지 여부
 * @param {number} currentPage 페이지 번호 (if 0, get all items)
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준 (예> start_date filed 를 내림차 순(-1) 정렬)
 * @param {boolean} withHidden 쿼리 결과에, 숨김 프로젝트를 포함할지 여부
 *
 * @returns 응답으로 받은 데이터 (예정된 프로젝트 목록)
 */
const getPlannedProjectListAll = async (payload = { group: "", optGroup: false, currentPage: 0, itemsCountPerPage: 0, sort: null, withHidden: false }) => {
    const res = await Api.post("/v1/project/planned-list/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 활성 (after start_date and before end_date) 프로젝트 목록을 반환
 *
 * Note. 모든 사용자 호출 가능
 *
 * @param {string} group group의 _id, null 인 경우 모든 group 에 속한 project 획득
 * @param {boolean} optGroup group 정보를 포함해서 반환할지 여부
 * @param {number} currentPage 페이지 번호 (if 0, get all items)
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준 (예> start_date filed 를 내림차 순(-1) 정렬)
 * @param {boolean} withHidden 쿼리 결과에, 숨김 프로젝트를 포함할지 여부
 *
 * @returns 응답으로 받은 데이터 (진행중인 프로젝트 목록)
 */
const getActiveProjectListAll = async (payload = { group: "", optGroup: false, currentPage: 0, itemsCountPerPage: 0, sort: null, withHidden: false }) => {
    const res = await Api.post("/v1/project/active-list/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 완료된 (after end_date) 프로젝트 목록을 반환
 *
 * Note. 모든 사용자 호출 가능
 *
 * @param {string} group group의 _id, null 인 경우 모든 group 에 속한 project 획득
 * @param {boolean} optGroup group 정보를 포함해서 반환할지 여부
 * @param {number} currentPage 페이지 번호 (if 0, get all items)
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준 (예> start_date filed 를 내림차 순(-1) 정렬)
 * @param {boolean} withHidden 쿼리 결과에, 숨김 프로젝트를 포함할지 여부
 *
 * @returns 응답으로 받은 데이터 (완료된 프로젝트 목록)
 */
const getArchivedProjectListAll = async (payload = { group: "", optGroup: false, currentPage: 0, itemsCountPerPage: 0, sort: null, withHidden: false }) => {
    const res = await Api.post("/v1/project/archived-list/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 프로젝트의 레포트 등록자 목록을 등록 건수별로 내림차순으로 정렬하여 반환
 *
 * Note. groupId 와 projectId 는 exclusive 합니다. 둘 중에 하나의 값만 설정해야 합니다.
 *
 * @param {string} groupId group의 _id, 그룹에 가장 많이 등록한 등록자 목록 획득
 * @param {string} projectId project의 _id, 프로젝트에 가장 많이 등록한 등록자 목록 획득
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
    ```
 * @returns 응답으로 받은 데이터 (등록자 목록)
 */
const getTopReporterList = async (payload = { groupId: "", projectId: "", page: 0, itemsCountPerPage: 0 }) => {
    dgLogger.assert(!(payload.groupId && payload.projectId))();
    const url = (payload.groupId)?"/v1/group/top-reporter-list/all":"/v1/project/top-reporter-list/all";
    const res = await Api.post(url, payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 Database를 초기화
 */
const devResetDatabase = async () => {
    await Api.post("/v1/dev/reset-database");
};

/**
 * 자료 사용권 요청 Api
 *
 * @param {string[]} reportIds 자료 사용권을 요청 할 report id의 array
 *
 * @returns 성공 message
 */
const requestReportLicense = async (payload = { reportIds: [], lang: "ko" }) => {
    const res = await Api.post("/v1/report/request-report-license", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 자신에게 자료 사용권 요청한 내역 반환
 *
 * @param {string[]} states 검색 할 state array
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준 (예> start_date filed 를 내림차 순(-1) 정렬)
 *
 * @returns 자료 사용권 내역과 개수
 */
const getReportLicenseAll = async (payload = { states: [], page: 0, itemsCountPerPage: 0, sort: null }) => {
    const res = await Api.post("/v1/report/get-report-license-all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 자료 사용권 승인/거부에 대한 상태 갱신
 *
 * @param {string[]} histories report.approval.histories의 _id array
 * @param {string} state 승인 또는 거부 utility.STATE
 * @param {string} reason 거부 사유
 *
 * @returns N/A
 */
const updateReportLicenseState = async (payload = { histories: [], state: "", reason: "", lang: "ko" }) => {
    const res = await Api.post("/v1/report/update-report-license-state", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 자료 사용권 내역을 조건에 맞추어 반환
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {string} userId user의 _id, 값이 없을 경우 모든 사용자 목록 획득
 * @param {string} reportId report의 _id, 값이 없을 경우 모든 report 목록 획득
 * @param {Object} sort 특정 field 를 기준으로 오름차순(1)/내림차순(-1) 정렬
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (조건에 맞는 자료 사용권 내역)
 */
const getFilteredReportLicense = async (payload = { states: [], userId: "", reportId: "", sort: null, page: 0, itemsCountPerPage: 0 }) => {
    dgLogger.assert(payload.states && payload.states.length > 0)();
    const res = await Api.post("/v1/report/license/history/filter", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 다운로드 가능한 자료인 지 여부 반환
 *
 * @param {string} reportID report의 _id
 * @returns T/F
 */
const isDownloadableReport = async (payload = { reportID: "" }) => {
    const res = await Api.post("/v1/report/check-downloadable", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 자료 사용권 내역을 조건에 맞추어 반환
 *
 * @param {string[]} states 검색 할 state array (utility.STATE) 키가 없는 경우 모든 항목 검색
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (자료 사용권 내역)
 */
const getRequestedReportLicenseAll = async (payload = { states: [], page: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/report/get-requested-report-license-all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 자료 사용권 신청을 취소
 *
 * @param {string[]} licenseIds report.approval.histories의 _id array
 *
 * @returns 성공 message
 */
const removeRequestedReportLicense = async (licenseIds) => {
    const res = await Api.post("/v1/report/remove-requested-report-license", licenseIds);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 사용자의 Notification 목록 획득
 *
 * @returns 응답으로 받은 데이터 (notifications)
 * 이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
        from: "group.approval.histories" // notification 소스 collection
        item: "620dd98334700dd4008bcc81" // notification 발생 item (from notification 소스 collection)
        _id: "62131e0454308bab246da542" // notifications._id
 *  }
 *  ```
 */
const getNotifications = async () => {
    const res = await Api.get("/v1/common/notify");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * notification collection 에서 지정된 _id 에 매칭되는 item 삭제
 *
 * @param {string} _id 삭제할 notification id (notifications._id)
 *
 * @returns N/A
 */
const removeNotification = async (_id = "") => {
    const res = await axios.post("/v1/common/remove-notify", {ids: [_id]});
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * notification collection 에서 지정된 _id 에 매칭되는 item 삭제
 *
 * @param {string[]} ids 삭제할 notification ids (list of notifications._id)
 *
 * @returns N/A
 */
 const removeNotifications = async (ids = []) => {
    const res = await axios.post("/v1/common/remove-notify", {ids: ids});
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};


/**
 * notification collection 에서 지정된 조건 에 매칭되는 item 삭제
 *
 * @param {string} from notification 이 발생한 collection 이름
 * @param {string} item notification 이 발생한 collection 의 _id
 *
 * @returns N/A
 */
const removeMatchedNotification = async (payload = { from: "", item: "" }) => {
    const res = await axios.post("/v1/common/remove-matched-notify", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * notification collection 에서 지정된 조건 에 매칭되는 item 반환
 *
 * @param {string} _id notifications의 _id
 * @param {string} from notification 이 발생한 collection 이름
 *
 * @returns 응답으로 받은 데이터 (approval collection item)
 */
const getNotificationDetail = async (payload = { _id: "", from: "" }) => {
    const res = await Api.post("/v1/common/notify-detail", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 레포트의 댓글 정렬하여 반환
 *
 * @param {string} _id report_comments의 _id
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (댓글 목록)
 */
const getReportComment = async (payload = { _id: "", page: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/report/get-comment", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 레포트에 댓글 추가
 *
 * @param {string} _id report의 _id
 * @param {string} comment 댓글 내용
 *
 * @returns N/A
 */
const addReportComment = async (payload = { _id: "", comment: "" }) => {
    const res = await Api.post("/v1/report/add-comment", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 레포트의 댓글 삭제
 *
 * @param {string} _id report_comments의 _id
 *
 * @returns N/A
 */
const removeReportComment = async (payload = { _id: "" }) => {
    const res = await Api.post("/v1/report/remove-comment", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 프로젝트의 댓글을 정렬하여 반환
 *
 * @param {string} _id project_comments의 _id
 * @param {number} page 현재 page
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 *
 * @returns 응답으로 받은 데이터 (댓글 목록)
 */
const getProjectComment = async (payload = { _id: "", page: 0, itemsCountPerPage: 0 }) => {
    const res = await Api.post("/v1/project/get-comment", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 프로젝트에 댓글 추가
 *
 * @param {string} _id report의 _id
 * @param {string} comment 댓글 내용
 *
 * @returns N/A
 */
const addProjectComment = async (payload = { _id: "", comment: "" }) => {
    const res = await Api.post("/v1/project/add-comment", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 지정된 프로젝트의 댓글을 삭제
 *
 * @param {string} _id report_comments의 _id
 *
 * @returns N/A
 */
const removeProjectComment = async (payload = { _id: "" }) => {
    const res = await Api.post("/v1/project/remove-comment", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트에 제출된 레포트의 정보 목록 반환
 *
 * @param {string} projectId project의 _id
 *
 * @returns report array
 */
const getReportsInProject = async (payload = { projectId: "" }) => {
    const res = await Api.post("/v1/project/get-report", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트에 자료를 등록한 사용자 목록 반환
 *
 * @param {string} projectId project의 _id
 * @param {number} currentPage 페이지 번호
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준
 *
 * @returns 응답으로 받은 데이터 (자료를 등록한 사람들 목록)
 */
const getProjectParticipants = async (payload = { projectId: "", currentPage: 0, itemsCountPerPage: 0, sort: null }) => {
    const res = await Api.post("/v1/project/get-project-participants", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트에 등록된 자료목록을 반환
 *
 * @param {string} projectId project의 _id
 * @param {number} currentPage 페이지 번호
 * @param {number} itemsCountPerPage 한 페이지에 출력할 아이템 개수
 * @param {Object} sort 정렬 기준
 *
 * @returns 응답으로 받은 데이터 (검색된 자료 목록)
 */
const getProjectRegister = async (payload = { projectId: "", currentPage: 0, itemsCountPerPage: 0, sort: null }) => {
    const res = await Api.post("/v1/project/get-project-register", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트의 상세 정보 반환
 *
 * @param {string} projectId project의 _id
 *
 * @returns 프로젝트의 상세 정보
 */
const getProjectInfo = async (payload = { projectId: "" }) => {
    const res = await Api.post("/v1/project/get-project-info", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트의 종료 날짜를 현재 시간으로 갱신
 *
 * @param {string} projectId project의 _id
 *
 * @returns 성공 message
 */
const endProject = async (payload = { projectId: "" }) => {
    const res = await Api.post("/v1/project/end-project", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 프로젝트 삭제
 *
 * @param {string} projectId project의 _id
 *
 * @returns 성공 message
 */
const removeProject = async (payload = { projectId: "" }) => {
    const res = await Api.post("/v1/project/remove-project", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 자료 수집 현황 반환
 *
 * @returns 수집 현황
 */
const getReportCollection = async () => {
    const res = await Api.post("/v1/common/get-report-collection");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 검색 된 주소의 정보 반환
 *
 * @param {number} currentPage 현재 page
 * @param {number} countPerPage 한 페이지에 출력할 아이템 개수
 * @param {string} keyword 검색 할 주소
 *
 * @returns 주소 정보 json
 * {
        "common": { "errorMessage": "정상", "countPerPage": "5", "totalCount": "1", "errorCode": "0", "currentPage": "1" },
        "juso": [
            {
                "zipNo": "08518",
                "emdNm": "가산동",
                "rn": "가산로3길",
                "siNm": "서울특별시",
                "sggNm": "금천구",
                "admCd": "1154510100",
                "udrtYn": "0",
                "lnbrMnnm": "238",
                "jibunAddr": "서울특별시 금천구 가산동 238-11 예다지",
                "roadAddr": "서울특별시 금천구 가산로3길 134-10 (가산동, 예다지)",
                "lnbrSlno": "11",
                "buldMnnm": "134",
                "bdKdcd": "1",
                "rnMgtSn": "115454151002",
                "liNm": "",
                "mtYn": "0",
                "buldSlno": "10",

                "roadAddrPart1": "서울특별시 금천구 가산로3길 134-10",
                "roadAddrPart2": " (가산동, 예다지)",
                "engAddr": "134-10 Gasan-ro 3-gil, Geumcheon-gu, Seoul",
                "detBdNmList": "",
                "emdNo": "01",
                "bdNm": "예다지",
                "bdMgtSn": "1154510100102380011015246",
            }
        ]
    }
 */
const __getKoAddress = async (payload = { currentPage: 0, countPerPage: 0, keyword: "" }) => {
    const res = await axios.get("https://business.juso.go.kr/addrlink/addrLinkApi.do", {
        params: {
            confmKey: process.env.REACT_APP_ADDRESS_KO_SEARCH_API_KEY,
            currentPage: payload.currentPage,
            countPerPage: payload.countPerPage,
            keyword: payload.keyword,
            resultType: 'json'
        }
    });
    if (res.data.results.common.errorCode !== '0') {
        throw new ONException(res.data.results.common.errorCode, res.data.results.common.errorMessage);
    }
    return res.data.results;
};

/**
 *
 * @param {number} currentPage 현재 page
 * @param {number} countPerPage 한 페이지에 출력할 아이템 개수
 * @param {string} keyword 검색 할 주소
 * @returns 주소 정보 json
 * {
        "common": { "errorMessage": "정상", "countPerPage": "5", "totalCount": "1", "errorCode": "0", "currentPage": "1" },
        "juso": [
            {
                "zipNo": "08518",
                "emdNm": "Gasan-dong",
                "rn": "Gasan-ro 3-gil",
                "siNm": "Seoul",
                "sggNm": "Geumcheon-gu",
                "admCd": "1154510100",
                "udrtYn": "0",
                "lnbrMnnm": "238",
                "jibunAddr": "238-11 Gasan-dong, Geumcheon-gu, Seoul",
                "roadAddr": "134-10 Gasan-ro 3-gil, Geumcheon-gu, Seoul",
                "lnbrSlno": "11",
                "buldMnnm": "134",
                "bdKdcd": "1",
                "rnMgtSn": "115454151002",
                "liNm": "",
                "mtYn": "0",
                "buldSlno": "10",

                "korAddr": "서울특별시 금천구 가산로3길 134-10",
            }
        ]
    }
 */
const __getEnAddress = async (payload = { currentPage: 0, countPerPage: 0, keyword: "" }) => {
    const res = await axios.get("https://business.juso.go.kr/addrlink/addrEngApi.do", {
        params: {
            confmKey: process.env.REACT_APP_ADDRESS_EN_SEARCH_API_KEY,
            currentPage: payload.currentPage,
            countPerPage: payload.countPerPage,
            keyword: payload.keyword,
            resultType: 'json'
        }
    });
    if (res.data.results.common.errorCode !== '0') {
        throw new ONException(res.data.results.common.errorCode, res.data.results.common.errorMessage);
    }
    return res.data.results;
};


/**
 * 검색 된 주소의 정보 반환
 *
 * @param {number} currentPage 현재 page
 * @param {number} countPerPage 한 페이지에 출력할 아이템 개수
 * @param {string} keyword 검색 할 주소
 *
 * @returns 주소 정보 json
 */
const getAddress = async (payload = { currentPage: 0, countPerPage: 0, keyword: "", lang: "ko" }) => {
    return (payload.lang == "ko")? __getKoAddress(payload) : __getEnAddress(payload);
};

/**
 * 현재 사용자의 공익 단체 권한 반환
 *
 * @retuns 공익 단체 권한
 */
const getOrganizationPermission = async () => {
    const res = await Api.post("/v1/user/organization/get-permission");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 현재 사용자의 특정 그룹에 대한 권한 반환
 *
 * @param {string} groupId group의 _id
 *
 * @returns 그룹에 대한 권한
 */
const getCurrentUserPermissionFromGroup = async (payload = { groupId: "" }) => {
    const res = await Api.post("/v1/user/get-current-user-permission", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 제출된 정보로 report를 생성 후 생성된 report의 _id 반환
 *
 * @param {string} participating_group report가 제출된 group의 _id
 * @param {string} participating_project report가 제출된 project의 _id
 * @param {Object} report_form_result report의 form정보와 사용자가 입력한 값
 *
 * @returns 생성된 report의 _id
 */
const createReportUsingResult = async (payload = { participating_group: "", participating_project: "", report_form_result: null }) => {
    const res = await Api.post("/v1/report/create-report-using-result", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const upsertReportSnapshot = async (payload) => {
    const res = await Api.post('/v1/report/snapshot', payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const fetchReportSnapshot = async (payload = { registered_by: null, as_boolean: true}) => {
    const res = await Api.get('/v1/report/snapshot', { params: payload });
    if (res.data.code != E.FOUND && res.data.code != E.NOTFOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

const deleteReportSnapshot = async (payload = { registered_by: null}) => {
    const res = await Api.delete('/v1/report/snapshot', { params: payload });
    if (res.data.code != E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }

    return res.data.payload;
};

/**
 * 특정 report_form의 정보 반환
 *
 * @param {string} report_form_id report_form의 _id
 *
 * @returns report_form의 정보
 */
const getReportForm = async (payload = { report_form_id: "" }) => {
    const res = await Api.post("/v1/report/get-report-form", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 위경도와 같은 report 목록 반환
 *
 * @param {number} lat 위도
 * @param {number} lng 경도
 *
 * @returns report 목록
 */
const getSameLocationReportsItem = async (payload = { lat: 0, lng: 0 }) => {
    const res = await Api.post("/v1/report/get-same-location-reports-item", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 사용자의 password 변경
 *
 * @param {string} email 사용자의 email
 * @param {string} password 변경하고자하느 password
 * @param {string} code mail 인증 code
 *
 * @returns N/A
 */
const resetPass = async (payload = { email: "", password: "", code: "" }) => {
    const res = await Api.post("/v1/user/reset-pass", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * parameter로 넘어온 정보를 이용하여 회원가입
 *
 * @param {string} email email 주소
 * @param {string} name 닉네임
 * @param {string} phone 휴대폰
 * @param {string} password 비밀번호
 * @param {string} address1 주소
 * @param {string} address2 나머지 주소
 * @param {string} about 자기소개
 * @param {string} sns sns 주소
 *
 * @returns N/A
 */
const signUp = async (payload = {
    email: "", name: "", phone: "", password: "",
    address1: "", address2: "", about: "", sns: ""
}) => {
    const res = await Api.post("/v1/user/signup", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};


const sendNotification = async (payload) => {
    console.assert(payload.to);
    console.assert(payload.title);
    console.assert(payload.body);

    const res = await Api.post("/v1/user/notification", payload);
    if (res.data.code != E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * storage server에 있는 bucket의 ACL 설정
 *
 * @param {string} bucketName storage server에서 사용할 bucket의 이름
 */
const setBucketACL = async (payload = { bucketName: "" }) => {
    const res = await Api.post("/v1/dev/set-bucket-acl", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * storage server에 있는 file 삭제
 *
 * @param {array} fileKeys file key array
 * @param {boolean} publicBucket public bucket target 인 지 여부
 */
const deleteFiles = (fileKeys = [], publicBucket = true) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const bucketName = publicBucket ? process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET : process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET;
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    fileKeys.forEach(async (key) => {
        await S3.deleteObject({
            Bucket: bucketName,
            Key: key,
        }).promise();
    });
};

/**
 * stroage server에 있는 image 삭제
 *
 * @param {array} imageKeys image key array
 */
const deleteImages = (imageKeys) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    imageKeys.forEach(async (key) => {
        const [fileName, extension] = key.split(".");

        await S3.deleteObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: fileName + "_thumbnail." + extension,
        }).promise();

        await S3.deleteObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PUBLIC_BUCKET,
            Key: fileName + "_watermark." + extension,
        }).promise();

        await S3.deleteObject({
            Bucket: process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET,
            Key: key,
        }).promise();
    });
};

/**
 * report_form 생성
 * payload에 어떠한 id가 있는가에 따라 만들어진 form이 적용 되는 collection 변경
 * group_id와 project_id는 exclusive
 *
 * @param {Object[]} formItems report form item array
 * @param {string} group_id group의 _id
 * @param {string} project_id project의 _id
 *
 * @returns 생성된 report_form
 */
const createReportForm = async (payload = { formItems: [], group_id: "", project_id: "" }) => {
    const res = await Api.post("/v1/report/create-report-form", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};


const registerObjectDetectionModel = async (payload = {}) => {
    const res = await Api.post("/v1/ai/register-object-detection-model", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const getObjectDetectionModelList = async(payload = {currentPage: 0, itemsCountPerPage: 0}) => {
    const res = await Api.get("/v1/ai/get-object-detection-model-list");
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const getObjectDetectionModel = async(payload = {_id: null/*latest*/}) => {
    const res = await Api.get("/v1/ai/get-object-detection-model", { params: payload });
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const removeObjectDetectionModel = async(payload = {_id: null}) => {
    console.assert(payload._id != null);
    const res = await Api.post("/v1/ai/remove-object-detection-model", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * report_form 갱신
 *
 * @param {Object[]} formItems report form item array
 *
 * @returns 갱신된 report_form_id 리스트
 */
const updateReportForm = async (payload = { formItems: [] }) => {
    const res = await Api.post("/v1/report/update-report-form", payload);
    if (res.data.code !== E.UPDATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * report form item의 정보 목록 반환
 *
 * @param {string} _id report_form의 _id
 *
 * @returns report_form_item의 정보 목록
 */
const getReportFormItemArray = async (payload = { _id: "" }) => {
    const res = await Api.post("/v1/report/get-report-form-item-array", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

/**
 * 특정 그룹에 속해있는 프로젝트 이름 목록 반환
 *
 * @param {string[]} groups group의 _id array
 * @param {Object} sort 정렬 방식
 * @param {List} project 추가로 출력할 field 이름
 * @param {boolean} withHidden 쿼리 결과에, 숨김 프로젝트를 포함할지 여부
 *
 * @returns project name array
 */
const getProjectListAll = async (payload = { groups: [], sort: null, project: undefined, withHidden: false }) => {
    const res = await Api.post("/v1/project/list/all", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const frontEndLogger = async (payload) => {
    const res = await Api.post("/v1/common/front-end-logger", payload);
    if (res.data.code !== E.CREATED) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
};

/**
 * 자료 다운로드에 필요한 file info list 반환
 *
 * @param {string[]} ids report의 _id 목록
 * @param {string} lang 화면 출력 언어 (ko/en)
 * @returns file info list
 */
const getFileInfoList = async (payload = { ids: [], lang : "ko" }) => {
    const res = await Api.post("/v1/report/get-file-info-list", payload);
    if (res.data.code !== E.FOUND) {
        throw new ONException(res.data.code, res.data.payload.message);
    }
    return res.data.payload;
};

const moveS3Bucket = async (payload = { sourceBucket: "", destinationBucket: "", keys: [], acl: "" }) => {
    const endpoint = new AWS.Endpoint(process.env.REACT_APP_NAVER_CLOUD_ENDPOINT);
    const S3 = new AWS.S3({
        endpoint: endpoint,
        region: process.env.REACT_APP_NAVER_CLOUD_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_NAVER_CLOUD_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_NAVER_CLOUD_SECRETKEY
        }
    });

    return payload.keys.map(key =>
        S3.copyObject({
            Bucket: payload.destinationBucket,
            CopySource: `/${payload.sourceBucket}/${key}`,
            Key: key,
            ACL: payload.acl
        }, (err, data) => {
            S3.deleteObject({ Bucket: payload.sourceBucket, Key: key });
        })
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    post: Api.post,
    get: Api.get,
    devAutoGenerateUser,
    devMakeUser2RandomGroupManager,
    devMakeUser2RandomGroupMember,
    devMakeUsers2RandomGroupMember,
    devMakeRandomOrganization,
    devMakeProjectsOnRandomGroup,
    signIn,
    refreshAuth,
    logout,
    getUserInfo,
    updateUserInfo,
    getCurrentUserInfo,
    getFilterGroupList,
    getAllGroupList,
    getFilteredGroupMember,
    getFilteredUser,
    searchUserName,
    sendMailToGroupMembers,
    updateGroupMembersPermission,
    updateGroupPermissions,
    removeGroupPermissions,
    withdrawGroupMembers,
    withdrawSiteMembers,
    requestOrgCreation,
    getGroupMemberHistoryAll,
    getGroupMemberHistoryMine,
    updateGroupMemberHistory,
    requestJoinGroupMember,
    getGroupHistoryAll,
    getGroupHistoryMine,
    getGroupHistoryItem,
    getNotMemberedGroupList,
    getMemberedGroupList,
    updateGroupHistory,
    requestGroupCreation,
    isDuplicatedGroupName,
    isDuplicatedUserName,
    removeGroup,
    uploadFile,
    uploadFiles,
    uploadImage,
    uploadImages,
    uploadWatermarkImage,
    downloadReport, // deprecated
    downloadReports,
    getCurrentUserMaxPermission,
    getOrganizationHistoryAll,
    getOrganizationHistoryMine,
    getOrganizationHistoryItem,
    updateOrganizationHistory,
    reverseGeocode,
    geocode,
    getReportsMap,
    getDownloadableReportsList,
    getReportsList,
    getReportsGraph,
    requestProjectCreation,
    getPlannedProjectListAll,
    getActiveProjectListAll,
    getArchivedProjectListAll,
    getGroupManagers,
    getGroupItem,
    updateGroupItem,
    getReportsItem,
    getReportForm,
    removeReport,
    sendAskQuestion,
    loadPrivacyPolicy,
    savePrivacyPolicy,
    loadIntroduction,
    saveIntroduction,
    loadAntiEmail,
    saveAntiEmail,
    getActiveGroupListAll,
    getNotOrganizationGroupList,
    getActiveGroupListMine,
    getTopReporterList,
    devResetDatabase,
    requestReportLicense,
    getReportLicenseAll,
    updateReportLicenseState,
    getRequestedReportLicenseAll,
    removeRequestedReportLicense,
    getFilteredReportLicense,
    isDownloadableReport,
    getProjectComment,
    addProjectComment,
    removeProjectComment,
    getReportComment,
    addReportComment,
    removeReportComment,
    getReportsInProject,
    getProjectParticipants,
    getProjectRegister,
    getProjectInfo,
    endProject,
    removeProject,
    getReportCollection,
    getAddress,
    getNotifications,
    removeNotification,
    removeNotifications,
    removeMatchedNotification,
    getNotificationDetail,
    getOrganizationPermission,
    getCurrentUserPermissionFromGroup,
    createReportUsingResult,
    getSameLocationReportsItem,
    resetPass,
    signUp,
    setBucketACL,
    deleteFiles,
    deleteImages,
    getProjectListAll,
    createReportForm,
    updateReportForm,
    getReportFormItemArray,
    frontEndLogger,
    getFileInfoList,
    moveS3Bucket,
    upsertReportSnapshot,
    fetchReportSnapshot,
    deleteReportSnapshot,
    registerObjectDetectionModel,
    getObjectDetectionModelList,
    getObjectDetectionModel,
    removeObjectDetectionModel,
    sendNotification,
};
