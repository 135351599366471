import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function CanTCreateProjectWindow ({onConfirm}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>{t('647')}</div>;
    const body = (
        <>
            <div>{t('648')}</div>
            <div>{t('649')}</div>
        </>
    );

    return (
        <Modal
            onRequestClose={() =>{
                if (onConfirm) onConfirm();
                navigate(`/Project`);
            }}
            onConfirm={() =>{
                if (onConfirm) onConfirm();
                navigate(`/Project`);
            }}
            icon={icon}
            header={header}
            body={body}
        />
    );
}
