import React, { useEffect, useState, useContext, useRef } from "react";
import { error as E } from "@ocean-knight/shared";
import { dummy } from "@ocean-knight/shared";
import { useNavigate } from "react-router-dom";
import { POPUP as GeneralPopup } from "../../common/defines";
import Api from "../../Api";
import PostcodeWindow from "../PostcodeWindow";
import PasswordConfirmForm from "../common/PasswordConfirmForm";
import PostcodeForm from "../common/PostcodeForm";
import PhoneForm from "./../common/PhoneForm";
import common from "../../common/common";
import { AppContext } from "../../AppContext";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Button, Container, Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import "./SignupEmail.css";
import { RequestMailCodeWindow, WrongMailCodeWindow } from "../common/MailAuthenticationWindow";
import { InputText } from "../Input";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";

const ID = {
    mail: "register-email",
    mailFeedback: "mail-feedback",
    nickname: "register-nickname",
    duplicatedName: "register-duplicated-name",
    verifyMailCode: "verify-mail-code",
    postcodePopup: "register-postcode-popup",
    mailButton: "register-mail-button",
    password: "register-password",
    passwordConfirm: "register-password-confirm",
    phone: "register-phone",
    address1: "register-address1",
    address2: "register-address2",
    about: "register-about",
    sns: "register-sns",
    duplicateButton: "register-duplicate-button"
};

const POPUP = {
    ...GeneralPopup,
    SignUpBannedEmail:            1009,
    SignUpDuplicatedEmail:        1010,
    SignUpVerifyingEmail:         1011,
    Welcome:                      1012,
    TimeOutEmailCode:             1013,
    WrongEmailCode:               1014,
};

export default function SignupEmail() {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const [mail, setMail] = useState({ state: E.NONE, address: null });
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        isLoading: false,
    });

    let timer = useRef(null);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({ email: '', nickname: '', password: '', passwordConfirm: '', phone: '', address1: '' });

    useEffect(() => {
        document.getElementById("App").className = "login";
        if (mail.state === E.VERIFY) {
            timer.current = setTimeout(() => {
                setMail({ state: E.NONE, address: null });
                setState({ popup: POPUP.None, popupTarget: null });
            }, 1000 * 60 * 5); // 5 minutes
        }

        else if (mail.state === E.VERIFIED) {
            clearTimeout(timer.current);
            timer.current = null;
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
        };
    }, [mail]);

    const onDuplicateNickname = async () => {
        try {
            const nickname = document.getElementById(ID.nickname).value.trim();
            if (!nickname) { return false; }

            await Api.isDuplicatedUserName({ name: nickname });
            setErrors({ ...errors, nickname: { state: true, message: t('323')} });
            document.getElementById(ID.nickname).classList.remove("invalid");
            return true;
        } catch (e) {
            setErrors({ ...errors, nickname: { state: false, message: t('324')} });
            document.getElementById(ID.nickname).classList.add("invalid");
            return false;
        }
    };

    const onSubmit = async (e) => {
        const email = document.getElementById(ID.mail);
        const nickname = document.getElementById(ID.nickname);
        const password = document.getElementById(ID.password);
        const passwordConfirm = document.getElementById(ID.passwordConfirm);
        const phone = document.getElementById(ID.phone);
        const address1 = document.getElementById(ID.address1);
        const address2 = document.getElementById(ID.address2);
        const about = document.getElementById(ID.about);
        const sns = document.getElementById(ID.sns);
        let valid = true;

        if (!isValid([nickname]) || !await onDuplicateNickname()) valid = false;
        isValid([password, passwordConfirm, address1]) || (valid = false);
        if (isValid([email]) && mail.state !== E.VERIFIED) {
            const errorCollection = { email: { state: false, message: t('321') } };
            email.classList.add("invalid");
            valid = false;
            setErrors((prev) => ({
                ...prev, ...errorCollection
            }));
        }

        if (!valid) {
            common.scrollToInvalidElement();
            return;
        }

        setState({ ...state, isLoading: true });

        const payload = {
            email: mail.address,
            name: nickname.value.trim(),
            password: password.value.trim(),
            phone: phone.value.trim(),
            address1: address1.value.trim(),
            address2: address2.value.trim(),
            about: about.value.trim(),
            sns: sns.value.trim(),
            displayLang: common.getLang(),
        };

        Api.signUp(payload)
            .then((res) => {
                return Api.getCurrentUserInfo({ optPermissions: true });
            })
            .then((payload) => {
                sessionStorage.setItem("name", payload.name);
                common.storePermissionsInfo(payload.optPermissions);
                setState((prev) => ({...prev, popup: POPUP.Welcome, popupTarget: null, isLoading: false }));
            })
            .catch((e) => {
                if (e.code === E.NOT_CREATED) {
                    dgLogger.warn("user not created")();
                    setErrors({ ...errors, nickname: { state: false, message: t('324') } });
                    document.getElementById(ID.nickname).classList.add("invalid");
                    setState((prev) => ({...prev, popup: POPUP.None, popupTarget: null, isLoading: false }));
                    common.scrollToInvalidElement();
                }
                else {
                    dgLogger.error(e)();
                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                    context.setLoggedIn(false);
                }
            });
    };

    const verifyMailCode = (e) => {
        let code = document.getElementById(ID.verifyMailCode).value.trim();
        if (!code) {
            dgLogger.warn("code is not defined")();
            return;
        }

        Api.post("/v1/user/verify-mail-code", {
            code: code,
            email: mail.address,
        })
            .then((res) => {
                if (res.data.code === E.NOTFOUND) {
                    dgLogger.warn("code is not matched")();
                    setState({ ...state, popup: POPUP.WrongEmailCode });
                }
                else {
                    setMail({ state: E.VERIFIED, address: mail.address });
                    setState({ popup: POPUP.None, popupTarget: null });
                }
            })
            .catch((err) => {
                dgLogger.error(err)();
                navigate("/", { replace: true });
            });
    };

    const verifyMail = (e) => {
        let email = document.getElementById(ID.mail);
        if (!isValid([email])) return;

        Api.post("/v1/user/verify-mail", {
            email: email.value,
            lang: common.getLang()
        })
            .then((res) => {
                if (res.data.code === E.FOUND) {
                    if (res.data.payload.state === E.DUPLICATED) {
                        dgLogger.warn("duplicated email")();
                        setState({ popup: POPUP.SignUpDuplicatedEmail, popupTarget: null });
                    } else if (res.data.payload.state === E.BANNED) {
                        dgLogger.warn("banned email")();
                        setState({ popup: POPUP.SignUpBannedEmail, popupTarget: null });
                    }
                    return;
                }
                setMail({ state: res.data.payload.state, address: email.value });
                setState({ popup: POPUP.SignUpVerifyingEmail, popupTarget: null });
            })
            .catch((err) => {
                dgLogger.error(err)();
                navigate("/", { replace: true });
            });
    };

    const popupRequestMailCode = (mail) => {
        return (
            <RequestMailCodeWindow
                onRequestClose={() => {
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={verifyMailCode}
                onCancel={() => {
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                mail={mail}
            />
        );
    };

    const popupDuplicatedMail = (mail) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_impossible.png`} srcSet={`${process.env.PUBLIC_URL}/pop_impossible@2x.png 2x, ${process.env.PUBLIC_URL}/pop_impossible@3x.png 3x`} alt="" />;
        const header = <div>{t('317')}</div>;
        const body = (
            <>
                <div className="c-4270e0">{document.getElementById(ID.mail).value}</div>
                <div>{t('318')}</div>
            </>
        );

        return (
            <Modal
                onRequestClose={() => {
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() =>{
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };
    const popupBannedMail = (mail) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_impossible.png`} srcSet={`${process.env.PUBLIC_URL}/pop_impossible@2x.png 2x, ${process.env.PUBLIC_URL}/pop_impossible@3x.png 3x`} alt="" />;
        const header = <div>{t('319')}</div>;
        const body = (
            <>
                <div className="c-4270e0">{document.getElementById(ID.mail).value}</div>
                <div>{t('320')}</div>
            </>
        );

        return (
            <Modal
                onRequestClose={() => {
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() =>{
                    setMail({ state: E.NONE, address: null });
                    setState({ popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupPostcode = () => {
        return (
            <div id={ID.postcodePopup}>
                <PostcodeWindow
                    onComplete={(address1, address2) => {
                        document.getElementById(ID.address1).value = address1;
                        isValid([document.getElementById(ID.address1)]);
                        document.getElementById(ID.address2).value = address2;
                        if (address1) document.getElementById(ID.address2).removeAttribute("disabled");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={ () =>
                        setState({ ...state, popup: POPUP.None, popupTarget: null })
                    }
                />
            </div>
        );
    };

    const popupWelcome = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/OCEAN-Logo.png`} srcSet={`${process.env.PUBLIC_URL}/OCEAN-Logo@2x.png 2x, ${process.env.PUBLIC_URL}/OCEAN-Logo@3x.png 3x`} alt="" />;
        const header = <div>{t('345')}</div>;
        const name = document.getElementById(ID.nickname).value;
        const body = <Trans i18nKey={'346'} values={{ '%s': name}} components={{tag: <span className="c-4270e0"/>}} />;

        return (
            <Modal
                onRequestClose={() => {
                    context.setLoggedIn(true);
                    navigate("/Mypage/RequestGroupMember", { replace: true });
                }}
                onConfirm={() => {
                    context.setLoggedIn(true);
                    navigate("/Mypage/RequestGroupMember", { replace: true });
                }}
                icon={icon}
                header={header}
                body={body}
                footer={t('347')}
            />
        );
    };

    // 동작 변경으로 사용하지 않습니다.
    // const popupTimeOutEmailCode = () => {
    //     return (
    //         <TimeOutMailCodeWindow
    //             onRequestClose={() => {
    //                 setMail({ state: E.NONE, address: null });
    //                 setState({ popup: POPUP.None, popupTarget: null });
    //             }}
    //             onConfirm={() => {
    //                 setMail({ state: E.NONE, address: null });
    //                 setState({ popup: POPUP.None, popupTarget: null });
    //             }}
    //         />
    //     );
    // };

    const popupWrongEmailCode = () => {
        return (
            <WrongMailCodeWindow
                onRequestClose={() => setState({ ...state, popup: POPUP.SignUpVerifyingEmail })}
                onConfirm={() => setState({ ...state, popup: POPUP.SignUpVerifyingEmail })}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Postcode) return popupPostcode();
        if (state.popup === POPUP.SignUpBannedEmail) return popupBannedMail(mail.address);
        if (state.popup === POPUP.SignUpDuplicatedEmail) return popupDuplicatedMail(mail.address);
        if (state.popup === POPUP.SignUpVerifyingEmail) return popupRequestMailCode(mail.address);
        if (state.popup === POPUP.Welcome) return popupWelcome();
        // if (state.popup === POPUP.TimeOutEmailCode) return popupTimeOutEmailCode();
        if (state.popup === POPUP.WrongEmailCode) return popupWrongEmailCode();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors((prev) => ({
            ...prev, ...errorCollection
        }));
        return flag;
    };

    return (
        <Container>
            <LoadingSpinner isOpen={state.isLoading} />
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Join membership</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('292')}</span>
            </div>
            <div
                className="signup-email-page"
                style={{
                    // border: "solid 1px",
                    // paddingBottom: "1rem",
                    textAlign: "left",
                }}
            >
                {popups()}

                <div className="signup notosanskr-600 font-size-40">
                    <span>{t('292')}</span>{" "}
                    {process.env.NODE_ENV !== "production" && (
                    <Button
                        onClick={() => {
                            let r = dummy.randomSignupInfo();
                            document.getElementById(ID.mail).value = r.email;
                            document.getElementById(ID.nickname).value = r.nickname;
                            document.getElementById(ID.password).value = r.password;
                            document.getElementById(ID.passwordConfirm).value = r.password;
                            document.getElementById(ID.phone).value = r.phone;
                            document.getElementById(ID.address1).value = r.address1;
                            document.getElementById(ID.address2).value = r.address2;
                            document.getElementById(ID.about).value = r.about;
                            document.getElementById(ID.sns).value = r.sns;
                        }}
                    >
                        Auto Fill
                    </Button>
                    )}
                </div>
                <Row className="gx-0 first-row">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title email-address-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t('284')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div className="email-wrap">
                            <InputText className="item" name="email" id={ID.mail} placeholder={t('293')} onBlur={(e) => isValid([e.target])} onKeyPress={common.blankBlocking} disabled={mail.state === E.VERIFIED} />
                            <button id={ID.mailButton} onClick={verifyMail} disabled={mail.state === E.VERIFIED}>
                                {mail.state === E.VERIFIED ? t('316') : t('294')}
                            </button>
                            <div className="notosanskr-400 font-size-14 errors c-f00">{errors.email?.message}</div>
                        </div>
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title nickname-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t('90')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <InputText
                                className="item"
                                id={ID.nickname}
                                name="nickname"
                                placeholder={t('322')}
                                onBlur={async (e) => {
                                    if (isValid([e.target]))
                                        await onDuplicateNickname();
                                }}
                                maxLength={20}
                            />
                            {/* <button id={ID.duplicateButton} onClick={onDuplicateNickname}>중복 확인</button> */}
                        </div>
                        {errors.nickname?.state && errors.nickname?.message ?
                            <div className="notosanskr-400 font-size-14 errors c-ccc" id={ID.duplicatedName}>{errors.nickname?.message}</div>
                            :
                            <div className="notosanskr-400 font-size-14 errors c-f00" id={ID.duplicatedName}>{errors.nickname?.message}</div>
                        }
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title password-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t('285')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <PasswordConfirmForm className="item" font="notosanskr-400 font-size-14" passwordId={ID.password} confirmId={ID.passwordConfirm} isValid={isValid} errors={errors} />
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title phone-title notosanskr-500 font-size-17">
                            {t('327')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <PhoneForm phoneId={ID.phone}
                            errors={errors}
                            font="notosanskr-400 font-size-14"
                        />
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title address-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t('329')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <PostcodeForm
                            address1Id={ID.address1}
                            address2Id={ID.address2}
                            isValid={isValid}
                            errors={errors}
                            onClick={() => {
                                setState({ ...state, popup: POPUP.Postcode, popupTarget: null });
                            }}
                            font="notosanskr-400 font-size-14"
                        />
                        <div className="c-f00">
                            {t('339')}
                        </div>
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title about-title notosanskr-500 font-size-17">
                            {t('340')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <textarea className="item" id={ID.about} name="about" placeholder={t('341')} maxLength={50} />
                        </div>
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title sns-title notosanskr-500 font-size-17">
                            {t('342')}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <InputText className="item" id={ID.sns} name="sns" placeholder={t('343')} maxLength={500} />
                        </div>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <button className="signup-button notosanskr-400 font-size-16" onClick={onSubmit}>{t('344')}</button>
                </div>
            </div>
        </Container>
    );
}
