import "./VendorUI4.css";
import { useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const VendorUI4View = ({ title, description, type, value, invalid, errorMessage, isMobile, sub_items, onChangeHandle }) => {
    const { t } = useTranslation();
    const VendorUI4Item = useCallback(
        ({ value, sub_item, invalid }) => {
            return (
                <tr className="">
                    <td className="maxw-400px pr-4px pt-5px pb-5px align-items-center notosanskr-17 nanumsquare-12:sm">
                        <Row className="gx-0 align-items-center">
                            <Col className="col-md-1 col-auto font-size-18 lh-1.33 ls--0.9px c-000">•</Col>
                            <Col className="col-md-11 pl-8px pl-6px:sm text-break">{sub_item.name}</Col>
                        </Row>
                    </td>
                    <td className="pl-4px pr-4px pt-5px pb-5px">
                        <button
                            className="btn btn-primary w-45px h-45px d-flex"
                            onClick={(e) => {
                                onChangeHandle(sub_item, 1);
                            }}
                        >
                            <span className="font-size-32 lh-1 align-middle w-100 h-100">+</span>
                        </button>
                    </td>
                    <td className="pl-4px pr-4px pt-5px pb-5px">
                        <button
                            className="btn btn-primary w-45px h-45px d-flex"
                            onClick={(e) => {
                                onChangeHandle(sub_item, -1);
                            }}
                        >
                            <span className="font-size-32 lh-1 align-middle w-100 h-100">−</span>
                        </button>
                    </td>
                    <td className="pl-4px pr-4px pt-5px pb-5px">
                        <input value={value.count} readOnly={true} className={`notosanskr-17 nanumsquare-14:sm w-93px maxw-93px:sm ${invalid} text-end`} type="text" disabled={true} />
                    </td>
                </tr>
            );
        },
        [onChangeHandle]
    );

    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type} text-start`}>
                {isMobile && description && <label className="top-description nanumsquare-14 ls--0.7px font-size-15">{description}</label>}

                <table>
                    <thead className="text-center notosanskr-17b nanumsquare-13b:sm">
                        <tr>
                            <td className="maxw-400px pt-15px pb-15px">{t('516')}</td>
                            <td className="pt-15px pb-15px">{t('466')}</td>
                            <td className="pt-15px pb-15px">{t('659')}</td>
                            <td className="pt-15px pb-15px">{t('517')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {sub_items.map((sub_item) => (
                            <VendorUI4Item key={sub_item._id} value={value.find((v) => v._id == sub_item._id)} sub_item={sub_item} invalid={invalid} />
                        ))}
                        <tr className="notosanskr-17b nanumsquare-14b:sm">
                            <td className="text-center">{t('518')}</td>
                            <td colSpan={3} className="pl-4px pr-4px pt-5px pb-5px text-center">
                                <input
                                    value={value.reduce((acc, item) => acc + item?.count, 0).toLocaleString()}
                                    readOnly={true}
                                    className={`maxw-140px w-102px:sm maxw-102px:sm ${invalid} text-center fw-bold`}
                                    type="text"
                                    disabled={true}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {errorMessage && <label className="error">{errorMessage}</label>}
                {!isMobile && description && <label className="bottom-description nanumsquare-14 ls--0.7px font-size-15">{description}</label>}
            </Col>
        </>
    );
};

export default VendorUI4View;
