import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const UserInfoUpdateWindow = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_change_save.png`} srcSet={`${process.env.PUBLIC_URL}/pop_change_save@2x.png 2x, ${process.env.PUBLIC_URL}/pop_change_save@3x.png 3x`} alt="" />;
    const header = <div>{t('454')}</div>;
    const body = <div>{t('455')}</div>;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { UserInfoUpdateWindow };
