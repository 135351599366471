import common from "../../common/common";
import Modal from "../common/Modal";
import { useTranslation, Trans } from "react-i18next";

function PopupRequestJoinGroup(props) {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_group_join.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_join@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_join@3x.png 3x`} alt="" />;
    const header = <div>{t('225')}</div>;
    const body = <Trans i18nKey={'226'} values={{ "%s": common.i18nGroupName(props.groupName)}} components={{tag: <span className="c-4270e0"/>}} />;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={async () => {
                await props.onConfirm();
            }}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
}

function PopupRequestedJoinGroup(props) {
    const { t } = useTranslation();
    if (props.require_join_confirm) {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_join.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_join@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_join@3x.png 3x`} alt="" />;
        const header = <div>{t('227')}</div>;
        const body = <>{t('228').split("\n").map((v, i) => <div key={i}>{v}</div>)}</>;

        return (
            <Modal
                onRequestClose={props.onClick}
                onConfirm={props.onClick}
                icon={icon}
                header={header}
                body={body}
            />
        );
    } else {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_join.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_join@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_join@3x.png 3x`} alt="" />;
        const header = <div>{t('380')}</div>;
        const body = <div>{t('381')}</div>;

        return (
            <Modal
                onRequestClose={props.onClick}
                onConfirm={props.onClick}
                icon={icon}
                header={header}
                body={body}
            />
        );
    }
}

function PopupJoinGroupRejectReason(props) {
    const { t } = useTranslation();
    const header = <div>{t('382')}</div>;
    const body = <div>{t('383')}</div>;

    return (
        <Modal
            onRequestClose={props.onClick}
            onConfirm={props.onClick}
            header={header}
            body={body}
            reason={props.state_reason}
        />
    );
}

function PopupJoinGroupApproved(props) {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_group_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_complete@3x.png 3x`} alt="" />;
    const header = <div>{t('380')}</div>;
    const body = <div>{t('381')}</div>;

    return (
        <Modal
            onRequestClose={props.onClick}
            onConfirm={props.onClick}
            icon={icon}
            header={header}
            body={body}
        />
    );
}

export { PopupRequestJoinGroup, PopupRequestedJoinGroup, PopupJoinGroupRejectReason, PopupJoinGroupApproved };
