import React, { useEffect, useState, useRef, useCallback } from "react";
import GoogleMap from "google-map-react";
import Supercluster from "points-cluster";
import Cluster from "./Cluster";
import Marker from "./Marker";
import "./Map.css";
import { IsPC } from "../common/MediaQuery";
import * as Config from "../common/MapConfig";
import dgLogger from "../../common/dgLogger";

// MAP에서 사용 되는 MAX ZOOM
const CLUSTER_MAX_ZOOM = 14;
const MAX_ZOOM = 15;

/**
 * @param {*} props properties
    ```
    {
        markerClickable: T/F (화면상에 표시된 marker 클릭 가능여부)
    }
    ```
 * @returns JSX
 */
export default function Map(props) {
    const [state, setState] = useState({ clusters: [] });
    const mapRef = useRef();
    const mapsRef = useRef();
    const mapOptions = useRef();
    const thumbnailMarkerUse = useRef({data: {}, id: ""});
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const isPc = IsPC();
    const index = useRef(0);

    // props의 data에 따라 zoom level과 center를 바꾸어 주는 함수
    const changeBounds = useCallback(() => {
        if (!(props.data.length && mapRef.current && mapsRef.current)) return;
        const bounds = new mapsRef.current.LatLngBounds();
        props?.data?.forEach(data => {
            bounds.extend(new mapsRef.current.LatLng(data.lat, data.lng));
        });
        mapRef?.current.fitBounds(bounds);
    }, [props.data]);

    /**
     * Cluster 정보를 가지고 오는 함수
     *
     * @param {string} group group의 이름, 현재는 그룹이 1개만 선택이 가능하지만 추후 복수 선택을 위하여 설정
     * @param {number} radius 현재 zoom level에 따른 radius 값
     * @returns 생성 된 Cluster object
     */
    const getClusters = useCallback((group, radius) => {
        return new Promise((resolve, reject) => {
            try {
                const clusters = Supercluster(props.data, {
                    minZoom: 0,
                    maxZoom: CLUSTER_MAX_ZOOM,
                    radius: radius,
                });

                return resolve(clusters({
                    zoom: mapOptions.current.zoom,
                    bounds: {
                        nw: {
                            lat: 90,
                            lng: -180,
                        },
                        se: {
                            lat: -90,
                            lng: 180
                        }
                    }
                }));

            } catch (e) {
                reject(e);
            }
        });
    }, [props.data]);

    /**
     * Cluster 생성
     *
     * @param {Objecct} clusters 생성된 Cluster를 모아 둘 object
     * @param {number} radius cluster의 radius, pixel 단위
     * @param {string} group group의 이름, 초기 기획에서는 그룹 별 다른 색으로 표시하도록 했기 때문에 추후 사용을 할 수 있어 남겨진 상태
     */
    const createCluster = useCallback(async (clusters, radius, group) => {
        const __clusters = await getClusters(group, radius);
        clusters[group] = __clusters.map(({ wx, wy, numPoints, points }) => (
            {
                lat: wy,
                lng: wx,
                numPoints,
                id: index.current++,
                points,
                size: getSize(numPoints),
            }));
    }, [getClusters]);

    /**
     * props가 바뀌거나 Map에 동작에 의해 보이는 Data가 바뀌었을 때 Update 해주는 함수
     * createCluster 호출 시 groupName, rgba 값은 추후 수정 될 예정입니다.
     */
    const updateCluster = useCallback(() => {
        if (!props.data) return;
        let clusters = {};
        let radius = getRadius();
        const promise1 = createCluster(clusters, radius, "groupName");
        Promise.all([promise1]).then(() => {
            setState((prev) => ({
                ...prev, clusters: clusters,
            }));
        });
    }, [createCluster, props.data]);

    // Map에 표시 되는 data가 바뀌었을 때 실행
    useEffect(() => {
        if (mapOptions.current !== undefined && props.data !== undefined) {
            changeBounds();
            updateCluster();
        };
    }, [props.data, changeBounds, updateCluster]);

    /**
     * 현재 임의의 값으로 설정 하였으며, 추후 디자인에 따라 수정 될 수 있습니다.
     *
     * @returns zoom level에 따른 radius
     */
    const getRadius = () => {
        switch (mapOptions.current.zoom) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return 110;
            case 6:
                return 99;
            case 7:
                return 88;
            case 8:
                return 77;
            case 9:
                return 66;
            case 10:
                return 55;
            case 11:
                return 44;
            default:
                return 33;
        }
    };

    /**
     * 클러스터에 포함 된 데이터의 개수에 따라 클러스터의 크기를 리턴해주는 함수, range는 수정 예정
     *
     * @param {*} numPoints 클러스터에 포함 된 데이터의 개수
     * @returns
     */
    const getSize = (numPoints) => {
        const n = 1;
        if (numPoints > 1000) {
            return (10 * 10) + n;
        }
        if (numPoints > 500) {
            return (10 * 9) + n;
        }
        if (numPoints > 100) {
            return (10 * 8) + n;
        }
        if (numPoints > 50) {
            return (10 * 7) + n;
        }
        if (numPoints > 10) {
            return (10 * 6) + n;
        }
        return (10 * 4) + n;
    };

    /**
     * google api 의 MaxZoomService 를 통해, (이미지가 보이는) 최대 줌을 얻어오도록 처리.
     *
     * @param {Object} position {lat, lng} 로 이루어진 Object
     * @param {function} cb callback function
     *
     * @returns N/A
     */
    const maxZoomAtLatLng = (position, cb) => {
        const maps = mapsRef?.current;
        const map = mapRef?.current;
        if (!maps || !map) { return (cb && cb()); }

        maps.MaxZoomService.prototype.getMaxZoomAtLatLng(position, (response) => {
            if (response.status !== 'OK') {
                dgLogger.warn("Error in MaxZoomService")();
            } else {
                const currentZoom = map.getZoom();
                if (currentZoom > (response.zoom - 3)) {
                    map.setZoom(response.zoom - 3);
                }
            }
            cb && cb();
        });
    };

    /**
     * 지도 상태가 바뀔 때 마다 호출되는 handler
     *
     * @param {*} center map의 가운데 gps
     * @param {*} zoom map의 zoom level
     * @param {*} bounds map의 구석 네 개의 gps
     */
    const handleMapChange = ({ center, zoom, bounds }) => {
        mapOptions.current = {
            center,
            zoom,
            bounds,
        };

        if (!mapRef.current || !mapsRef.current) return;
        if (mapOptions.current) updateCluster(); // already called in handleApiLoaded
        if (props.mapChangeNavigate) props.mapChangeNavigate(center.lat, center.lng, zoom);
    };

    /**
     * 지도 최초 로드 시 콜백
     */
    const handleApiLoaded = ({ map, maps }) => {
        mapRef.current = map;
        mapsRef.current = maps;

        if (props.center.lat === Config.lat && props.center.lng === Config.lng && props.zoom === Config.zoom) {
            changeBounds();
        }

        // 지도가 로드될때, 지도에 이미지가 보이지 않을정도로 zoom 되어 있다면, 적정한 수준으로 zoom-out 되도록 처리
        maxZoomAtLatLng({ lat: mapRef.current.center.lat(), lng: mapRef.current.center.lng() }, () => updateCluster());
    };

    // thumbnail을 띄우기 위해 사용자가 선택한 marker의 정보 저장
    const selectMarker = (info) => {
        thumbnailMarkerUse.current.id = info.id;
        thumbnailMarkerUse.current.data = info.data;

        forceUpdate();
    };

    return (
        <div className={props.className? props.className : "map"} style={props.style ? props.style : { height: '679px', width: '100%' }}>
            <GoogleMap
                options={{ mapTypeControl: true, gestureHandling: isPc ? "greedy" : "cooperative", mapTypeId: "hybrid", maxZoom: MAX_ZOOM, }}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY, region: "KR" }}
                center={props.center}
                zoom={props.zoom}
                onGoogleApiLoaded={handleApiLoaded}
                onChange={handleMapChange}
                yesIWantToUseGoogleMapApiInternals
                onClick={() => selectMarker({data: {}, id: ""})}
            >

                {Object.keys((state.clusters) ? state.clusters : []).map((k) => {
                    return (
                        state.clusters[k].map(item => {
                            if (item.numPoints === 1) {
                                return (
                                    <Marker
                                        id={item.points[0]._id}
                                        lat={item.points[0].lat}
                                        lng={item.points[0].lng}
                                        hover={false}
                                        preventClick={props.preventMarkerClick}
                                        map={mapRef.current}
                                        maps={mapsRef.current}
                                        mapOptions={mapOptions.current}
                                        selectMarker={selectMarker}
                                        thumbnailMarkerUse={thumbnailMarkerUse.current.id === item.points[0]._id}
                                        data={thumbnailMarkerUse.current.id === item.points[0]._id? thumbnailMarkerUse.current.data : undefined}
                                        CLUSTER_MAX_ZOOM={CLUSTER_MAX_ZOOM}
                                    />
                                );
                            }
                            return (
                                <Cluster
                                    id={item.id}
                                    lat={item.lat}
                                    lng={item.lng}
                                    size={item.size}
                                    count={item.numPoints}
                                    map={mapRef.current}
                                    mapChangeNavigate={props.mapChangeNavigate}
                                />
                            );
                        })
                    );
                })}
            </GoogleMap>
        </div>
    );
};
