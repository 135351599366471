import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import Pagination from "../common/Pagination";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useNotification } from "../../common/customHook";
import { mutate } from "swr";
import "./ObjectDetectionModel.css";
import { IsMobile, IsPC } from "../common/MediaQuery";
import { useIsMount } from "../../common/customHook";
import dgLogger from "../../common/dgLogger";
import { InputText } from "../Input";
import ImageDropZone2 from "../common/ImageDropZone2";
import CryptoJS from "crypto-js";
import Semver from "semver";

import { Trans, useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    RemoveModelFailure: 1000,
};

const TAB_ID = {
    registerAiModel: "register-ai-model",
    listAiModel: "list-ai-model",
};

const ID = {
    modelVersion: "model-version",
    modelClasses: "model-classes",
    modelFile: "model-file",
    modelType: "model-type",
    modelDescription: "model-description",
};

const MAX_FILES = 1;
const MAX_SIZE = 1024 * 1024 * 100; // 100MB

function modelVersion() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = (now.getUTCMonth() + 1).toString().padStart(2, "0"); // UTC 월 정보
    const day = now.getUTCDate().toString().padStart(2, "0");
    const hour = now.getUTCHours().toString().padStart(2, "0");
    const minute = now.getUTCMinutes().toString().padStart(2, "0");
    const second = now.getUTCSeconds().toString().padStart(2, "0");

    return `${year}${month}${day}.${hour}${minute}${second}.0`;
}

class ChecksumService {
    async sha256(file) {
        let sha256 = CryptoJS.algo.SHA256.create();
        const sliceSize = 10_485_760; // 10 MiB
        let start = 0;

        try {
            while (start < file.size) {
                const slice = await this.__readSlice(file, start, sliceSize);
                const wordArray = CryptoJS.lib.WordArray.create(slice);
                sha256 = sha256.update(wordArray);
                start += sliceSize;
            }
        } catch (e) {
            return undefined;
        }

        sha256.finalize();

        return sha256._hash.toString();
    }

    async __readSlice(file, start, size) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const slice = file.slice(start, start + size);

            fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(slice);
        });
    }
}

export default function ObjectDetectionModel() {
    const { t } = useTranslation();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        activeTab: TAB_ID.registerAiModel, // 현재 tab
        models: [],
    });
    const [model, setModel] = useState({
        version: modelVersion(),
        classes: [],
        file: undefined,
        type: undefined,
    });

    const navigate = useNavigate();
    const isMount = useIsMount();
    const [errors, setErrors] = useState({});
    const paginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });

    useEffect(() => {
        const hasPermission = common.hasSiteAdminPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            if (!isMount.current) return;
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: `현재 권한으로는 접근할 수 없습니다.`,
            }));
            return;
        } else {
            Api.getObjectDetectionModelList({
                currentPage: paginationOption.current.currentPage,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            }).then((payload) => {
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                setState((prev) => ({ ...prev, models: payload.models }));
            });
        }
    }, [isMount]);

    const popups = () => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            case POPUP.RemoveModelFailure:
                return (
                    <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        setState({...state, popup: POPUP.None, popupTarget: undefined});
                    }}
                    />
                );
            case POPUP.None:
                break;
            default:
                console.log(`un-handled popup (${state.popup})`);
                break;
        }
    };

    const validateFields = async () => {
        let flag = true;
        const errorCollection = {};
        const modelVersion = document.getElementById(ID.modelVersion);
        const modelClasses = document.getElementById(ID.modelClasses);
        const modelFile = document.getElementsByClassName(ID.modelFile)[0];
        const modelType = document.getElementById(ID.modelType);

        common.validateInputField(errorCollection, ID.modelVersion, modelVersion) || (flag = false);

        (common.validateInputField(errorCollection, ID.modelClasses, modelClasses) &&
            common.validateInputFieldEx(
                errorCollection,
                ID.modelClasses,
                modelClasses,
                () => {
                    const classes = modelClasses.value
                        .split(",")
                        .map((item) => item.trim())
                        .filter((item) => item.length > 0);
                    return classes.length > 0;
                },
                "1개 이상의 class 가 등록되어야 합니다."
            )
        ) || (flag = false);

        common.validateFileField(errorCollection, ID.modelFile, modelFile, (model.file && [model.file]) || []) || (flag = false);
        common.validateInputField(errorCollection, ID.modelType, modelType) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const onDropModelFile = (acceptedFiles, fileRejections) => {
        // 모든 파일 업로드 가능
        const [, allFiles, result] = common.validateOnDrop(acceptedFiles, fileRejections, (model.file && [model.file]) || [], MAX_FILES, MAX_SIZE);
        if (!result.valid) {
            return setState((prev) => ({
                ...prev,
                popup: POPUP.ValidateFileField,
                popupTarget: {
                    // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: !result.maxFiles ? MAX_FILES : -1,
                    MAX_SIZE: !result.maxSize ? MAX_SIZE / (1024 * 1024) : -1,
                },
            }));
        }

        if (allFiles.length > 0) {
            setModel({...model, file: allFiles[0]});
        }
    };

    const clearForm = (e) => {
            // clear fields
            const version = modelVersion();
            document.getElementById(ID.modelVersion).value = version;
            document.getElementById(ID.modelClasses).value = "";
            document.getElementById(ID.modelType).value = "";
            document.getElementById(ID.modelDescription).value = "";
            document.querySelector(`input#${ID.modelFile}`).value = "";

            setModel({...model,
                version: version,
                classes: [],
                file: undefined,
                type: undefined
            });
    };

    const onSubmit = async () => {
        const modelVersion = document.getElementById(ID.modelVersion).value.trim();
        const modelClasses = document.getElementById(ID.modelClasses).value
        .replace(/\]/g, '')
        .replace(/\[/g, '')
        .replace(/'/g, '')
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0);
        const modelType = document.getElementById(ID.modelType).value.trim();
        const modelDescription = document.getElementById(ID.modelDescription).value.trim();

        try {
            const extension = model.file?.name ? `.${model.file?.name?.split(".").pop()}` : "";

            const info = await Api.uploadFile(model.file, `app-res/${modelVersion}${extension}`, false);
            const hash = await (new ChecksumService()).sha256(model.file);

            const payload = {
                version: modelVersion,
                url: info.url,
                size: info.size,
                hash: hash,
                classes: modelClasses,
                type: modelType,
                description: modelDescription
            };

            const response = await Api.registerObjectDetectionModel(payload);

            Api.getObjectDetectionModelList({
                currentPage: paginationOption.current.currentPage,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            }).then((payload) => {
                paginationOption.current.totalItemsCount = payload.totalItemsCount;
                clearForm();
                setState({ ...state, activeTab:TAB_ID.listAiModel, models: payload.models });
            });
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    return (
        <div id="object-detection-model">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">{t("875")}</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("876")}</div>
                </Col>
            </Row>

            <Tabs activeKey={state.activeTab} id="uncontrolled-tab" className="uncontrolled-tab" onSelect={(k) => {
                setState({ ...state, activeTab: k });
                clearForm();
            }}>
                <Tab className={TAB_ID.registerAiModel} eventKey={TAB_ID.registerAiModel} title={t("878")}>
                    <Row className="gx-0 request-form-title notosanskr-500 font-size-26 c-333">
                        <Col className="col-auto">{t("878")}</Col>
                    </Row>
                    <Row className="item-row gx-0">
                        <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                            <Row className="gx-0 align-items-center h-100">
                                <Col>{t("880")}</Col>
                            </Row>
                        </Col>
                        <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                            {model.version && <InputText id={ID.modelVersion} disabled={true} defaultValue={model.version} placeholder={t("880")} className="w-75" />}
                        </Col>
                    </Row>
                    <Row className="item-row gx-0">
                        <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                            <Row className="gx-0 align-items-center h-100">
                                <Col>{t("881")}</Col>
                            </Row>
                        </Col>
                        <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                            <div className="caution notosanskr-400 font-size-14 c-f00">{t("885")}</div>
                            <div>
                                <InputText
                                    id={ID.modelClasses}
                                    name="modelClasses"
                                    defaultValue={""}
                                    required={true}
                                    className="w-75"
                                />
                            </div>
                            {errors?.[ID.modelClasses]?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.[ID.modelClasses]?.message}</div>}
                        </Col>
                    </Row>
                    <Row className="item-row gx-0">
                        <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                            <Row className="gx-0 align-items-center h-100">
                                <Col>{t("882")}</Col>
                            </Row>
                        </Col>
                        <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                            <div className="file-group">
                                <ImageDropZone2
                                    className={`create-group-files notosanskr-400 font-size-14 c-999 ${ID.modelFile}`}
                                    inputFileId={ID.modelFile}
                                    // imgId={ID.createGroupFiles}
                                    disabled={false}
                                    // maxFiles={MAX_FILES}
                                    accept=""
                                    ref={onDropModelFile}
                                    multiple={false}
                                    placeholder={ model.file?.path ? model.file?.path : t("97")  }
                                />
                            </div>
                            {errors?.[ID.modelFile]?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.[ID.modelFile]?.message}</div>}
                        </Col>
                    </Row>
                    <Row className="item-row gx-0">
                        <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                            <Row className="gx-0 align-items-center h-100">
                                <Col>{t("883")}</Col>
                            </Row>
                        </Col>
                        <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                            <div>
                                <InputText
                                    id={ID.modelType}
                                    name={ID.modelType}
                                    placeholder={t("888")}
                                    defaultValue={""}
                                    required={true}
                                    maxLength={20}
                                    className="w-75"
                                />
                            </div>
                            {errors?.[ID.modelType]?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.[ID.modelType]?.message}</div>}
                        </Col>
                    </Row>
                    <Row className="item-row gx-0">
                        <Col className="col-sm-auto col-sm item-title notosanskr-500 font-size-17 c-000" xs={12}>
                            <Row className="gx-0 align-items-center h-100">
                                <Col>{t("884")}</Col>
                            </Row>
                        </Col>
                        <Col className="item notosanskr-400 font-size-14 col-sm" xs={12}>
                            <textarea
                                id={ID.modelDescription}
                                name={ID.modelDescription}
                                disabled={!onSubmit}
                                defaultValue={""}
                                className="w-75"
                                placeholder={t("886")}
                            ></textarea>
                            {errors?.[ID.modelDescription]?.message && <div className="notosanskr-400 font-size-14 c-f00 mt-10px">{errors?.[ID.modelDescription]?.message}</div>}
                        </Col>
                    </Row>
                    <Row className="gx-0 confirm-btn-group" style={{ textAlign: "right" }}>
                        <Col>
                            <button
                                id={"register-ai-model-submit"}
                                className="save-btn notosanskr-400 font-size-18 c-white"
                                onClick={async () => {
                                    if (!(await validateFields())) {
                                        common.scrollToInvalidElement();
                                        return;
                                    }
                                    onSubmit();
                                }}
                            >
                                {t("887")}
                            </button>
                        </Col>
                    </Row>
                </Tab>
                <Tab className={TAB_ID.listAiModel} eventKey={TAB_ID.listAiModel} title={t("879")}>
                    <br/>
                    <Row className="item-row gx-0 align-items-center notosanskr-500 font-size-18">
                        <Col className="item col-3 text-center">{t("880")}</Col>
                        <Col className="item col-3">{t("881")}</Col>
                        <Col className="item col-2 text-center">{t("883")}</Col>
                        <Col className="item col-3">{t("884")}</Col>
                        <Col className="item col-1 text-center">{t("465")}</Col>
                    </Row>
                    {state.models.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center notosanskr-500 font-size-16">
                            <Col className="item col-3 c-333 text-center text-break">{item.version}</Col>
                            <Col className="item col-3 c-333 text-break">{`[${item.classes.join(", ")}]`}<p/>{item.classes.map((v,i) => <div key={i}>{`index ${i} -> "${v}"`}</div>)}</Col>
                            <Col className="item col-2 c-333 text-center text-break">{item.type}</Col>
                            <Col className="item col-3 c-333 text-break">{item.description}</Col>
                            <Col className="item col-1 c-333 text-center text-break">
                            <button
                                className="remove-model notosanskr-400 font-size-16"
                                type="button" onClick={async (_) => {
                                    try {
                                        await Api.removeObjectDetectionModel({_id: item._id});
                                    } catch (e) {
                                        dgLogger.error(e)();
                                        setState({ ...state, popup: POPUP.RemoveModelFailure, popupTarget: e.message });
                                        return;
                                    }

                                    Api.getObjectDetectionModelList({
                                        currentPage: paginationOption.current.currentPage,
                                        itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                                    }).then((payload) => {
                                        paginationOption.current.totalItemsCount = payload.totalItemsCount;
                                        setState((prev) => ({ ...prev, models: payload.models }));
                                    });
                                }}>
                                {t("465")}
                            </button>
                            </Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getObjectDetectionModelList({
                                currentPage: newPage,
                                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    paginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    paginationOption.current.currentPage = newPage;
                                    setState({...state, models: payload.models});
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                                });
                        }}
                        page={paginationOption.current.currentPage}
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
