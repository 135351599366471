import React from "react";
import ReactModal from "react-modal";
// import RequestOrganizationContent from "../Mypage/RequestOrganizationContent";
// import "./OrganizationInfoWindow.css";
import RequestGroupCreationContent from "../Mypage/RequestGroupCreationContent";
import "./GroupInfoApplicationWindow.css";
import { useTranslation } from "react-i18next";

const ID = {
    createGroupRequireJoinConfirm: "create-group-require-join-confirm-application",
    createGroupName: "create-group-name-application",
    createGroupUserName: "create-group-username-application",
    createGroupContact: "create-group-contact-application",
    createGroupResearch: "create-group-research-application",
    createGroupRelLink: "create-group-link-application",
    createGroupAbout: "create-group-about-application",
    pictureDrop: "create-group-representative-picture-drop-application",
    picturesInput: "create-group-pictures-input-application",
    createGroupFilesInput: "create-group-files-input-application",
    createGroupFiles: "create-group-files-application",
};

/**
 * 그룹 정보를 출력 합니다.
 *
 * @param {*} props properties
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
 *      group: 그룹 정보,
 *      onClick: 닫기 버튼의 callback,
 *      className: custom class list (in string)
 *  }
 *  ```
 * @returns 화면에 출력할 html
 */
export default function GroupInfoApplicationWindow({ onClick, className, group }) {
    const { t } = useTranslation();

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={onClick}
            className={className || "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"group-application-modal"}
        >
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">
                {t('403')}
                <div style={{ position: "absolute", right: "32px" }}>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={onClick}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>

            <div className="body">
                <div id="group-application" className="content">
                    <RequestGroupCreationContent ID={ID} applicationInfo={group} />
                    <div className="button-group gx-0 text-end notosanskr-14-500 notosanskr-12-500:sm">
                        <button className="confirm nanumsquareb-14" onClick={onClick}>
                            {t('79')}
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}
