import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { useIsMount } from "../../common/customHook";
import ImageDropZone from "../common/ImageDropZone";
import Api from "../../Api";
import { AppContext } from "../../AppContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Button, Row, Col, OverlayTrigger, Tooltip, Tabs, Tab } from "react-bootstrap";
import "./CreateProject.css";
import CustomCheckbox from "../common/CustomCheckbox";
import common from "../../common/common";
import { InputText } from "../Input";
import CanTCreateProjectWindow from "../common/CanTCreateProjectWindow";
import LoginRecommendWindow from "../common/LoginRecommendWindow";
import { PopupValidateFile, PopupValidateImage } from "../common/ValidateModal";
import { POPUP as GeneralPopup } from "../../common/defines";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import LoadingSpinner from "../common/LoadingSpinner";
import { nanoid } from "nanoid";
import DynamicForm from "../common/DynamicForm";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import { LOCATION2STRTABLEMAP } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const TAB_ID = {
    infos: "info",
    forms: "forms",
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 200; // 200MB

export default function CreateProject() {
    const { t } = useTranslation();
    const location = useLocation();
    const [state, setState] = useState({
        groups: [],
        selectedGroup: null,
        canTCreateProject: true,

        /* default file 구조 : { url: null, file: null, id: null, }*/
        pictures: location.state?.pictures.map((picture) => ({ url: picture.url, file: { name: picture.name, custom: true, size: picture.size }, key: picture.key })) || null,
        representative_picture: location.state ? { url: location.state.representative_picture?.url, file: { name: "", custom: true }, key: location.state.representative_picture?.key } : null,
        info: location.state,
        isLoading: false,

        activeTab: TAB_ID.infos, // 현재 보고 있는 tab
        hasFormErrors: false,
    });
    const isMount = useIsMount();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({ group: '', name: '', pictures: '', startDate: '', endDate: '', startTime: '', endTime: '', research: '', location: '', about: '' });
    const deletedFilesKey = useRef([]);
    const dynamicForm = useRef();

    const getGroupList = useCallback(async () => {
        if (location.state?.modifyPermission) {
            return [location.state?.group?._id];
        }
        else {
            const info = await Api.getCurrentUserInfo({ optPermissions: true });
            return info.optPermissions
                .filter(permission => permission.can_create_project)
                .map(permission => permission.group);
        }
    }, [location.state?.modifyPermission, location.state?.group?._id]);

    useEffect(() => {
        document.getElementById("App").className = "project";
        if (context.loggedIn) {
            (async () => {
                const groupList = await getGroupList();

                if (groupList.length) {
                    await Api.getFilterGroupList({
                        _ids: groupList, // 로그인 사용자 프로젝트를 생성 할 수 있는 그룹 _id 목록
                    })
                        .then((payload) => {
                            if (!isMount.current) return;
                            const selectedGroup = payload.length > 1 ? payload.find(group => group._id === state.info?.group._id) : payload[0];

                            setState((prev) => ({
                                ...prev,
                                groups: payload,
                                selectedGroup: selectedGroup
                            }));
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                        });
                }
                else {
                    setState((prev) => ({
                        ...prev, canTCreateProject: false,
                    }));
                }
            })();
        }
    }, [context.loggedIn, isMount, getGroupList, state.info?.group._id]);

    useEffect(() => {
        if (state.pictures) {
            const representativePicture = document.getElementById("image");
            const errorCollection = {};

            if (!state.pictures.length) {
                errorCollection.pictures = { state: false, message: t('15') };
                representativePicture.classList.add("invalid");
            }
            else {
                errorCollection.pictures = { state: false, message: "" };
                representativePicture.classList.remove("invalid");
            }

            setErrors((prev) => ({ ...prev, ...errorCollection }));
        }
    }, [state.pictures, t]);

    useEffect(() => {
        if (state.info) {
            const element = document.getElementById("participating-group");
            if (element) element.value = state.info?.group?._id || "";
        }
    }, [state.groups, state.info]);

    /**
     *
     * @param {*} groups option에 삽입 될 array
     * @returns 생성 된 option html tag
     */
    const selectOptions = (groups) => {
        return groups.map((group) => (
            <option key={group._id} value={group._id}>
                {common.i18nGroupName(group.name)}
            </option>
        ));
    };

    const onDropPictureFiles = useCallback((acceptedFiles, fileRejections) => {
        // 이미지 파일 업로드 가능
        const [newFiles, , result] = common.validateOnDrop(
            acceptedFiles,
            fileRejections,
            state.pictures?.map(picture => picture.file) || [],
            MAX_FILES,
            MAX_SIZE,
            ["image/"]);
        if (!result.valid) {
            return setState(prev => ({...prev,
                popup: POPUP.ValidateFileField,
                popupTarget: { // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: MAX_FILES,
                    MAX_SIZE: MAX_SIZE,
                    MIME_TYPE: (!result.mimeType) ? "image/*" : null
                }
            }));
        }

        const readFile = (files, pictures) => {
            if (files.length === 0) {
                return setState((prev) => ({ ...prev, pictures: state.pictures?.concat(pictures) || pictures, representative_picture: state.representative_picture?.key ? state.representative_picture : pictures[0] }));
            }
            const file = files.shift();
            const reader = new FileReader();
            reader.onloadend = () => {
                const newPictures = [...pictures, { url: reader.result, file: file, key: `__${nanoid()}` }];
                readFile(files, newPictures);
            };
            reader.readAsDataURL(file);
        };
        (newFiles && newFiles.length > 0) && readFile(newFiles, []);
    }, [state.pictures, state.representative_picture]);

    /**
     * image 등록이 되어 있다면 image와 image를 삽입 할 수 있는 버튼을 반환
     * image 등록이 되어 있지 않다면 image를 삽입 할 수 있는 버튼을 반환
     *
     * @returns 생성 된 html tag
     */
    const isImage = () => {
        return (
            <Row className={`gx-0 ${state.pictures?.length > 1 && "image-row"}`}>
                <Col id="image" className="col-auto image-drop-zone">
                    <ImageDropZone
                        ref={onDropPictureFiles}
                        disabled={state.pictures?.length >= MAX_FILES}
                        maxFiles={MAX_FILES}
                        multiple={true}
                        droppableText=""
                        droppingText=""
                        accept={"image/*"} />
                </Col>

                {state.pictures?.map((picture) => (
                    <Col key={picture.key} className="col-auto image-wrap">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{picture.file.name}</Tooltip>}>
                            <div className="each-image">
                                <div className="upload-image-wrap">
                                    {picture.key === state.representative_picture?.key && (
                                        <button disabled className="representative notosanskr-400 font-size-14 c-white">
                                            {t('149')}
                                        </button>
                                    )}
                                    <button
                                        className="remove-picture c-white"
                                        onClick={() => {
                                            picture.url.startsWith("https") && deletedFilesKey.current.push(picture.key);
                                            const pictures = state.pictures.reduce((acc, cur) => {
                                                if (cur.key !== picture.key) acc.push(cur);
                                                return acc;
                                            }, []);

                                            const representative_picture = pictures.find((picture) => picture.key === state.representative_picture.key) || (pictures.length && pictures[0]);
                                            setState({ ...state, pictures: pictures, representative_picture: representative_picture });
                                        }}
                                    />
                                    <img
                                        id={picture.key}
                                        src={picture.url}
                                        alt="Representative"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setState({ ...state, representative_picture: picture });
                                        }}
                                    />
                                </div>
                            </div>
                        </OverlayTrigger>
                    </Col>
                ))}
            </Row>
        );
    };

    const popups = () => {
        if (state.popup === POPUP.ValidateFileField) {
            const target = state.popupTarget;
            if (target.MIME_TYPE) {
                return (
                    <PopupValidateImage
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    />
                );
            } else {
                return (
                    <PopupValidateFile
                        maxFiles={target.MAX_FILES}
                        maxSizeInMB={(target.MAX_SIZE) / (1024 * 1024)}
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    />
                );
            }
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    /**
     * 모든 field의 값이 입력 되었는지 판단하는 함수
     * 종료일시 미정 체크 시 종료 일시는 입력 되지 않아도 통과
     *
     * @returns true or false
     */
    const validateFields = () => {
        let flag = true;
        const errorCollection = {};
        const checked = document.getElementById(`undecided`).checked;
        const group = document.getElementById(`participating-group`);
        const name = document.getElementById(`name`);
        const representativePicture = document.getElementById(`image`);
        const startDate = document.getElementById(`start-date`);
        const endDate = document.getElementById(`end-date`);
        const location = document.getElementById(`location`);
        const about = document.getElementById(`about`);

        common.validateInputField(errorCollection, 'group', group) || (flag = false);
        common.validateInputField(errorCollection, 'name', name) || (flag = false);
        common.validateInputField(errorCollection, 'name', name) || (flag = false);
        common.validateFileField(errorCollection, 'pictures', representativePicture, state.pictures) || (flag = false);
        common.validateInputField(errorCollection, 'startDate', startDate) || (flag = false);

        if (!endDate.value.trim() && !checked) {
            errorCollection.endDate = { state: false, message: t('15') };
            endDate.classList.add("invalid");
            flag = false;
        }
        else {
            errorCollection.endDate = { state: false, message: "" };
            endDate.classList.remove("invalid");
        }

        !state.info?.built_in && (common.validateInputField(errorCollection, 'location', location) || (flag = false));
        common.validateInputField(errorCollection, 'about', about) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    useEffect(() => {
        if (state.hasFormErrors) {
            setState(prev => ({...prev, hasFormErrors: false}));
            common.scrollToInvalidElement();
        };
    }, [state.hasFormErrors, state.activeTab]);

    /**
     * project 게시 버튼을 눌렀을 때 api를 호출하는 함수
     */
    const projectSubmit = async () => {
        if (!validateFields()) {
            return setState({ ...state, hasFormErrors: true, activeTab: TAB_ID.infos });
        }

        if (dynamicForm.current) {
            const valid = dynamicForm.current.validate();
            if (!valid) {
                return setState({ ...state, hasFormErrors: true, activeTab: TAB_ID.forms });
            }
        }

        setState({ ...state, isLoading: true });

        const checked = document.getElementById(`undecided`).checked;
        const [prevFile, currentFile] = state.pictures.reduce((acc, picture) => {
            if (picture.file.custom) acc[0].push(location.state?.pictures.find(statePicture => statePicture.key === picture.key));
            else acc[1].push(picture.file);
            return acc;
        }, [[], []]);

        try {
            if (deletedFilesKey.current.length) {
                Api.deleteFiles(deletedFilesKey.current);
            }

            const payload = {
                _id: state.info?._id,
                participatingGroup: document.getElementById(`participating-group`).value,
                name: state.info?.built_in ? state.info.name : document.getElementById(`name`).value,
                startDate: common.convertToIsoDate(document.getElementById(`start-date`).value),
                endDate: checked || state.info?.built_in ? undefined : common.convertToIsoDate(document.getElementById(`end-date`).value),
                projectArea: state.info?.built_in ? "" : document.getElementById(`location`).value,
                about: document.getElementById(`about`).value,
                pictures: prevFile,
            };

            if (currentFile.length) {
                payload.pictures.push(...(await Api.uploadFiles(currentFile)));
            }
            const index = state.pictures.findIndex((picture) => picture.key === state.representative_picture.key);
            payload.representativePicture = payload.pictures[index];

            const created = await Api.requestProjectCreation(payload);
            dynamicForm.current && (await dynamicForm.current.submit(created));

            navigate(`/Project/${created}`);
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    /**
     * 종료일시 미정 버튼을 눌렀을 때 종료 일시를 활성/비활성화 해주는 함수
     */
    const endDateTimeDisabled = () => {
        const checked = document.getElementById(`undecided`).checked;
        const endDate = document.getElementById(`end-date`);
        const errorCollection = {};

        if (checked) {
            endDate.disabled = true;
            endDate.value = "";
            endDate.classList.remove("invalid");
            errorCollection.endDate = { state: true, message: "" };
            errorCollection.endTime = { state: true, message: "" };
        }
        else {
            endDate.disabled = false;
            if (!endDate.value) {
                endDate.classList.add("invalid");
                errorCollection.endDate = { state: false, message: t('15') };
            }
        }

        setErrors({ ...errors, ...errorCollection });
    };

    const popupCanTCreateProject = () => {
        return (
            <CanTCreateProjectWindow />
        );
    };

    const isDateEmpty = (e) => {
        if (!e.target.value)
            e.target.required = true;
        else
            e.target.required = false;
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    if (context.loggedIn === false) {
        return (
            <LoginRecommendWindow />
        );
    }

    return (
        <Container>
            <LoadingSpinner isOpen={state.isLoading} />
            <span className="create-project-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff project">Project</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">
                    {t('213')}
                </span>
            </span>
            {popups()}
            {!state.canTCreateProject ? (
                popupCanTCreateProject()
            ) : (
                <div className="create-project-page notosanskr-400 font-size-14">
                    <Row className="gx-0 align-items-center">
                        <Col className="notosanskr-600 font-size-40">
                            {t('252')}
                        </Col>
                        {process.env.NODE_ENV !== "production" && (
                            <Col className="col-auto text-end">
                                <Button
                                    style={{ fontSize: "16px" }}
                                    onClick={() => {
                                        Api.devMakeProjectsOnRandomGroup()
                                            .then(() => {
                                                dgLogger.info("make random project success")();
                                            })
                                            .catch((e) => dgLogger.error(e)());
                                    }}
                                >
                                    Make random Projects
                                </Button>
                            </Col>
                        )}
                    </Row>

                    <Tabs
                        activeKey={state.activeTab}
                        id="uncontrolled-tab"
                        className="uncontrolled-tab"
                        onSelect={(key) => {
                            if (state.activeTab === key) return;

                            switch (key) {
                                case TAB_ID.infos:
                                    setState({ ...state, activeTab: key });
                                    break;
                                case TAB_ID.forms:
                                    setState({ ...state, activeTab: key });
                                    break;
                                default:
                                    return;
                            }
                        }}
                    >
                    <Tab className={TAB_ID.infos}
                        eventKey={TAB_ID.infos}
                        title={t('253')}
                    >
                    <div className="create-project-table">
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('38')}
                            </Col>
                            <Col className="content col-sm" xs={12}>
                                <select
                                    id="participating-group"
                                    name="group"
                                    onChange={(e) => {
                                        const valid = isValid([e.target]);
                                        valid &&
                                            setState({
                                                ...state,
                                                selectedGroup: state.groups.find((group) => group._id === e.target.value),
                                            });
                                        return valid;
                                    }}
                                    disabled={state.groups?.length === 1 || state.info?.group.name}
                                    defaultValue={state.info?.group._id || ""}
                                >
                                    {state.groups?.length > 1 && (
                                        <option value="" disabled selected={true}>
                                            {t('255')}
                                        </option>
                                    )}
                                    {selectOptions(state.groups ? state.groups : [])}
                                </select>
                                <div className="c-f00 mt-6px">{errors?.group?.message}</div>
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('256')}
                            </Col>
                            <Col className="content col-sm" xs={12}>
                                <InputText
                                    id="name"
                                    name="name"
                                    maxLength={50}
                                    onBlur={(e) => isValid([e.target])}
                                    defaultValue={state.info?.name}
                                    disabled={state.info?.built_in}
                                />
                                <div className="c-f00 mt-6px">{errors?.name?.message}</div>
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('257')}
                            </Col>
                            <Col className="content image col-sm" xs={12}>
                                <div style={{ display: "flex", flexFlow: "column" }}>{isImage()}</div>
                                <div className="c-f00 mt-6px">{errors?.pictures?.message}</div>
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('239')}
                            </Col>
                            <Col className="content date-item col-sm" xs={12}>
                                <div className="date-item-wrap">
                                    <input
                                        type="date"
                                        // data-placeholder="시작일시"
                                        id="start-date"
                                        onBlur={(e) => {
                                            isDateEmpty(e);
                                            common.dateVerify(e, "start-date", "end-date");
                                        }}
                                        onChange={(e) => {
                                            isDateEmpty(e);
                                            common.setDateRange(e, "start-date", "end-date");
                                            isValid([e.target]);
                                        }}
                                        max={(state.info?.end_date && !state.info?.end_date.startsWith("+01000")) ? common.convertToDate(new Date(state.info?.end_date)) : "9999-12-31"}
                                        name="startDate"
                                        defaultValue={common.convertToDate(new Date(state.info?.start_date))}
                                        disabled={state.info?.built_in}
                                        required={true}
                                    />
                                    <span className="tilde nanumsquareb-17">~</span>
                                    <input
                                        type="date"
                                        // data-placeholder="종료일시"
                                        id="end-date"
                                        onBlur={(e) => {
                                            isDateEmpty(e);
                                            common.dateVerify(e, "start-date", "end-date");
                                        }}
                                        onChange={(e) => {
                                            isDateEmpty(e);
                                            common.setDateRange(e, "start-date", "end-date");
                                            isValid([e.target]);
                                        }}
                                        max="9999-12-31"
                                        min={common.convertToDate(new Date(state.info?.start_date))}
                                        name="endDate"
                                        defaultValue={state.info?.built_in ? "" : !state.info?.end_date.startsWith("+01000") ? common.convertToDate(new Date(state.info?.end_date)) : ""}
                                        disabled={state.info?.built_in || state.info?.end_date.startsWith("+01000")}
                                        required={true}
                                    />
                                    <span className="undecided">
                                        <CustomCheckbox
                                            className="d-inline-flex align-items-center"
                                            id="undecided"
                                            onChange={() => {
                                                endDateTimeDisabled();
                                            }}
                                            defaultChecked={state.info?.built_in || state.info?.end_date.startsWith("+01000")}
                                            disabled={state.info?.built_in}
                                        >
                                            {t('260')}
                                        </CustomCheckbox>
                                    </span>
                                </div>
                                <div className="c-f00 mt-6px">
                                    {errors?.startDate?.message
                                        ? errors.startDate.message
                                        : errors?.endDate?.message
                                        ? errors.endDate.message
                                        : errors?.startTime?.message
                                        ? errors.startTime.message
                                        : errors?.endTime?.message
                                        ? errors.endTime.message
                                                : ""}
                                </div>
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('240')}
                            </Col>
                            <Col className="content col-sm" xs={12}>
                                <select
                                    id="location"
                                    name="location"
                                    onChange={(e) => isValid([e.target])}
                                    defaultValue={state.info?.project_area || ""}
                                    disabled={state.info?.built_in}
                                >
                                    <option value="" disabled>
                                        {t('261')}
                                    </option>
                                    {Object.keys(LOCATION2STRTABLEMAP).map((v) => (
                                        <option value={v}>{t(`${LOCATION2STRTABLEMAP[v]}`)}</option>
                                    ))}
                                </select>
                                <div className="c-f00 mt-6px">{errors?.location?.message}</div>
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="project-item-title col-auto notosanskr-500 font-size-17 d-flex align-items-center col-sm" xs={12}>
                                {t('241')}
                            </Col>
                            <Col className="content col-sm" xs={12}>
                                <textarea
                                    id="about"
                                    name="about"
                                    maxLength={500}
                                    onBlur={(e) => isValid([e.target])}
                                    defaultValue={state.info?.about}
                                />
                                <div className="c-f00 mt-6px">{errors?.about?.message}</div>
                            </Col>
                        </Row>
                    </div>
                    </Tab>
                    <Tab className={TAB_ID.forms}
                        eventKey={TAB_ID.forms}
                        title={t('254')}
                        disabled={!state.selectedGroup?.built_in}
                    >
                        <div className="overflow-auto">
                            <div className="create-project-table create-project-form">
                                {state.selectedGroup?.built_in && (
                                    <DynamicForm
                                        key={state.selectedGroup?._id}
                                        report_form_id={state.info?.report_form_id || state.selectedGroup?.report_form_id}
                                        project_id={state.info?._id}
                                        ref={dynamicForm}
                                        hideSubmit={true}
                                    />
                                )}
                            </div>
                        </div>
                    </Tab>
                    </Tabs>
                    <div style={{ textAlign: "right" }}>
                        <input
                            type="submit"
                            className="notosanskr-400 font-size-16 c-fff"
                            onClick={projectSubmit}
                            id="submit"
                            value={state.info ? t('537') : t('262')}
                        />
                    </div>
                </div>
            )}
        </Container>
    );
}
