import React from "react";
import ReactModal from "react-modal";
import RequestOrganizationContent from "./../Mypage/RequestOrganizationContent";
import "./OrganizationInfoWindow.css";
import { useTranslation } from "react-i18next";

const ID = {
    organizationName: "create-organization-name-application",
    organizationFiles: "create-organization-files-application",
    organizationFilesInput: "create-organization-files-input-application",
    organizationGroupName: "create-organization-group-application",
    organizationUserName: "create-organization-username-application",
    organizationUserContact: "create-organization-user-contact-application",
    organizationAffiliation: "create-organization-affiliation-application",
    organizationUserPosition: "create-organization-user-position-application",
    organizationRequestCause: "create-organization-cause-application",
};

/**
 * 단체 정보를 출력 합니다.
 *
 * @param {*} props properties
 *  이는 다음과 같은 format 을 갖습니다.
 *  ```
 *  {
 *      organization: 단체 정보,
 *      onClick: 닫기 버튼의 callback,
 *      className: custom class list (in string)
 *  }
 *  ```
 * @returns 화면에 출력할 html
 */
export default function OrganizationInfoWindow({ onClick, className, organization }) {
    const { t } = useTranslation();
    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={onClick}
            className={className || "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"organization-application-modal"}
        >
            <div className="title nanumsquareeb-18">
                {t('657')}
                <div style={{ position: "absolute", right: "32px" }}>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={onClick}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>

            <div id="organization-application">
                <RequestOrganizationContent ID={ID} applicationInfo={organization} />
            </div>

            <div className="text-end">
                <button className="confirm nanumsquareb-14" onClick={onClick}>
                    {t('79')}
                </button>
            </div>
        </ReactModal>
    );
}
