import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { POPUP as GeneralPopup } from "../../common/defines";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Container, Row, Col } from "react-bootstrap";
import { useIsMount } from "../../common/customHook";
import Api from "../../Api";
import Editor from "../TinyEditor";
import "./Signup.css";
import Modal from "../common/Modal";
import CustomCheckbox from "../common/CustomCheckbox";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import common from "../../common/common";
import i18next from "i18next";

const POPUP = {
    ...GeneralPopup,
    Terms:            1050,
    TermsWarning:     1051,
};

export const PopupTermWarning = ({onRequestClose, onConfirm}) => {
    const icon = <img src={process.env.PUBLIC_URL + `/icon_accept_terms.png`} srcSet={`${process.env.PUBLIC_URL}/icon_accept_terms@2x.png 2x, ${process.env.PUBLIC_URL}/icon_accept_terms@3x.png 3x`} alt="" />;
    const header = <div>{i18next.t('314')}</div>;
    const body =  <div>{i18next.t('315').split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

    return (
      <Modal
        onRequestClose={onRequestClose}
        onConfirm={onConfirm}
        icon={icon}
        header={header}
        body={body}
      />
    );
};

export function Signup() {
    const { t } = useTranslation();
    const [state, setState] = useState({
        isAgreeTerm: false,
        popup: POPUP.None,
        popupTarget: null,
        HTMLData: "",
    });
    const navigate = useNavigate();
    const isMount = useIsMount();

    useEffect(() => {
        document.getElementById("App").className = "login";
        Api.loadPrivacyPolicy({
            lang: common.getLang()
        })
            .then((payload) => {
                if (!isMount.current) return;
                const HTMLData = payload.HTMLData;
                setState((prev) => ({
                    ...prev, HTMLData: HTMLData ? HTMLData : ""
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [isMount]);

    // 디자인 변경으로 기능도 함께 수정되어 현재 사용 되지 않는 popup
    // const popupTerms = () => {
    //     return (
    //         <Modal
    //             show={true}
    //             size="lg"
    //             id="signup-terms"
    //             backdrop="static"
    //             keyboard={false}
    //             centered
    //             onHide={() => {
    //                 setState({ ...state, popup: POPUP.None, popupTarget: null });
    //             }}
    //         >
    //             <Modal.Body>
    //                 <div>
    //                     &lt;이용약관&gt; 제1장 총칙 제1조 (목적) 이 약관은 “[회사명]”(이하 “[회사명]”)가 제공하는 “[서비스명] 및 관련 제반 서비스” (이하 ”서비스”)의 이용과 관련하여 회사와 회원과의
    //                     권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
    //                 </div>
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button
    //                     onClick={() => {
    //                         document.getElementById('accept-terms').checked = true;
    //                         setState({ ...state, isAgreeTerm: true, popup: POPUP.None, popupTarget: null });
    //                     }}
    //                 >
    //                     동의
    //                 </Button>
    //                 <Button
    //                     onClick={() => {
    //                         document.getElementById('accept-terms').checked = false;
    //                         setState({ ...state, isAgreeTerm: false, popup: POPUP.None, popupTarget: null });
    //                     }}
    //                 >
    //                     취소
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // };

    const popups = () => {
        if (state.popup === POPUP.TermsWarning) return <PopupTermWarning
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
        />;

        // if (state.popup === POPUP.Terms) return popupTerms();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <Container>
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Join membership</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('292')}</span>
            </div>
            {popups()}

            <div className="signup-page">
                <div className="membership notosanskr-700 font-size-36">Membership</div>

                <Row className="gx-0" style={{justifyContent: "space-between"}}>
                    <Col className="col-auto editor-wrap">
                        <div className="editor">
                            <Editor
                                key={true}
                                viewer={true}
                                HTMLData={state.HTMLData ? state.HTMLData : ""}
                            />
                        </div>
                        <div className="accept-terms notosanskr-400 font-size-14 notosanskr-12:sm">
                            <CustomCheckbox id="accept-terms">
                                <span className="">
                                {t('311')}
                                </span>
                            </CustomCheckbox>
                        </div>
                    </Col>
                    <Col className="button-wrap">
                        <Row className="gx-0">
                        <button
                            className="signup-email-button notosanskr-400 font-size-18"
                            onClick={() => {
                                if (!document.getElementById("accept-terms").checked) {
                                    setState({ ...state, popup: POPUP.TermsWarning, popupTarget: null });
                                } else {
                                    navigate("/Login/SignupEmail", { replace: false });
                                }
                            }}
                        >
                            <img className="icon" src={process.env.PUBLIC_URL + `/icon_mail2.png`} alt="" />
                            {t('312')}
                        </button>
                        </Row>
                        {process.env.REACT_APP_SNS_REGISTER === "true" && <>
                        <Row className="gx-0">
                        {/*TODO 구현되지 않은 항목 제거 #1193*/}
                        <button
                            className="signup-facebook-button notosanskr-400 font-size-18"
                            disabled
                        >
                            <img className="icon" src={process.env.PUBLIC_URL + `/icon_facebook.png`} alt="" />
                            {t('660')}
                        </button>
                        </Row>
                        <Row className="gx-0">
                        {/*TODO 구현되지 않은 항목 제거 #1193*/}
                        <button
                            className="signup-kakao-button notosanskr-400 font-size-18"
                            disabled
                        >
                            <img className="icon" src={process.env.PUBLIC_URL + `/icon_kakao.png`} alt="" />
                            {t('661')}
                        </button>
                        </Row>
                    </>}
                        <Row className="gx-0">
                        <div className="notosanskr-400 font-size-16" >
                            <span>
                                {t('313')}
                            </span>
                            <CustomButtonLink className="login-button c-fff notosanskr-400 font-size-18" to="/Login/SignIn">
                                {t('9')}
                                {/* <img className="" src={process.env.PUBLIC_URL + `/icon_lock@3x.png`} /> */}
                            </CustomButtonLink>
                            {/* <img className="login-button" src={process.env.PUBLIC_URL + `/login_button.png`} onClick={() => navigate("/Login/SignIn")} /> */}
                        </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}
