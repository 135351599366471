import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { error as E } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import Api from "../../Api";
import { POPUP as GeneralPopup } from "../../common/defines";
import common from "../../common/common";
// import { useSWRConfig } from 'swr';
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import { Container, Row, Col } from "react-bootstrap";
import "./SignIn.css";
import { InputText } from "../Input";
import CustomCheckbox from "../common/CustomCheckbox";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

const ID = {
    email: "signin-email",
    password: "signin-password",
    autoLogin: "signin-auto-login",
    loginFailure: "signin-login-failure",
    loginFailureReason: "signin-login-failure-reason",
};

const POPUP = {
    ...GeneralPopup,
    LoginFailure: 1046,
};

export default function SignIn(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({ popup: POPUP.None, popupTarget: null });
    const navigate = useNavigate();
    const context = useContext(AppContext);
    // const {mutate} = useSWRConfig();
    const [errors, setErrors] = useState({email: '', password: ''});

    useEffect(() => {
        document.getElementById("App").className = "login";
    }, []);

    const login = (e) => {
        let email = document.getElementById(ID.email);
        let password = document.getElementById(ID.password);

        if (!isValid([email, password])) {
            common.scrollToInvalidElement();
            return;
        }

        let autoLogin = document.getElementById(ID.autoLogin).checked;

        Api.signIn({
            email: email.value,
            password: password.value,
            autoLogin: autoLogin,
            displayLang: common.getLang(),
        })
            .then((payload) => {
                // notify to RN mobile app
                window.ReactNativeWebView?.postMessage(JSON.stringify({login: true, _id: payload._id}));

                return Api.getCurrentUserInfo({ optPermissions: true });
            })
            .then(async (payload) => {
                // dgLogger.info("login success")();
                Api.fetchReportSnapshot({ registered_by: payload._id, as_boolean: true })
                    .then(isReportSnapshot => {
                        if (isReportSnapshot) {
                            localStorage.setItem(payload._id, isReportSnapshot);
                        }
                        else {
                            localStorage.removeItem(payload._id, isReportSnapshot);
                        }
                    });

                context.setLoggedIn(true);
                sessionStorage.setItem("name", payload.name);
                sessionStorage.setItem("_id", payload._id);
                common.storePermissionsInfo(payload.optPermissions);

                const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                let navigatePathname = undefined;
                do {
                    navigatePathname = prevPathname.splice(-1)[0];
                } while (navigatePathname?.startsWith("/Login"));

                sessionStorage.setItem("prevPathname", prevPathname);
                navigate(navigatePathname || "/", { replace: true });
            })
            .catch((e) => {
                dgLogger.error(e)();
                setState({ ...state, popup: POPUP.LoginFailure, popupTarget: e });
                context.setLoggedIn(false);
            });
    };

    if (context.loggedIn) {
        return (
            <GeneralErrorWindow
                message={state.popupTarget}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    }

    const popupLoginFailure = () => {
        const icon = (<img src={process.env.PUBLIC_URL + `/pop_login_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_login_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_login_error@3x.png 3x`} alt="" />);
        const header = <div>{t('287')}</div>;
        const body = <div>{state.popupTarget.code === E.BANNED ? t('289').split('\n').map((v, i) => <div key={i}>{v}</div>) : t('288').split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.LoginFailure) return popupLoginFailure();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    return (
        <Container>
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Login</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('9')}</span>
            </div>
            {popups()}
            <div className="login-page">
                <div className="d-none d-sm-block">
                    <span className="ocean font-size-46">OCEAN</span>
                    <span className="knights font-size-36">Cloud</span>
                </div>
                <div className="login-text notosanskr-500 font-size-36">
                    Login
                </div>

                <div className="form-group control">
                    <InputText
                        className="signin-email notosanskr-400 font-size-17 ls--0.43px form-control"
                        id="signin-email"
                        name="email"
                        placeholder={t('284')}
                        onBlur={(e) => isValid([e.target])}
                        onKeyPress={(e) => e.key === "Enter" ? login() : common.blankBlocking}
                    />
                    <span className='icon-email'></span>
                </div>
                <div className="notosanskr-400 font-size-17 c-f00 mb-16px mt-10px">{errors.email?.message}</div>

                <div className="form-group control">
                    <input
                        className="signin-password notosanskr-400 font-size-17 ls--0.43px form-control"
                        type="password"
                        id="signin-password"
                        name="password"
                        placeholder={t('285')}
                        autoComplete="on"
                        onBlur={(e) => isValid([e.target])}
                        onKeyPress={(e) => e.key === "Enter" ? login() : common.blankBlocking}
                    />
                    <span className='icon-key'></span>
                </div>
                <div className="notosanskr-400 font-size-17 c-f00 mb-16px mt-10px">{errors.password?.message}</div>

                <div>
                    <button className="login notosanskr-400 font-size-17" onClick={login}>
                        {t('9')}
                    </button>
                </div>

                <div className="signin-auto-login notosanskr-400 font-size-15">
                    <CustomCheckbox
                        type="checkbox"
                        id="signin-auto-login"
                        name="auto-login"
                        // defaultChecked={localStorage.getItem("autoLogin")}
                        onChange={(e) => {
                            if (e.target.checked) {
                                localStorage.setItem("autoLogin", "true");
                            } else {
                                localStorage.setItem("autoLogin", "false");
                            }
                        }}
                    >
                        {t('290')}
                    </CustomCheckbox>
                </div>

                {process.env.REACT_APP_SNS_LOGIN === "true" && <>
                    <button className="facebook-login notosanskr-400 font-size-17" disabled>{/*TODO 구현되지 않은 항목 제거 #1193*/}<div><img className="sns-icon" src={process.env.PUBLIC_URL + `/icon_facebook.png`} alt="" />페이스북 계정으로 로그인</div></button>
                    <button className="kakao-login notosanskr-400 font-size-17" disabled>{/*TODO 구현되지 않은 항목 제거 #1193*/}<div><img className="sns-icon" src={process.env.PUBLIC_URL + `/icon_kakao.png`} alt="" />카카오톡 계정으로 로그인</div></button>
                </>}
                <Row className="gx-0">
                    <Col className="pe-2 notosanskr-400 font-size-16">
                        <CustomButtonLink className="login-password" to="/Login/Password">
                            {t('291')}
                        </CustomButtonLink>
                    </Col>
                    <Col className="ps-2 notosanskr-400 font-size-16">
                        <CustomButtonLink className="login-signup" to="/Login/Signup">
                        {t('292')}
                        </CustomButtonLink>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}
