import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { POPUP as GeneralPopup } from "../../common/defines";
import useCreateReportHook from "./CreateReportHook";
import CreateReportView from "./CreateReportView";
import { useGetCurrentUserInfo, useGetStoredReport, useGetTempReport } from "./common/common";
import { useLocation, useNavigate } from "react-router-dom";
import useSizeContext from "./Context/SizeContext";
import useGPSContext from "./Context/GPSContext";
import useDatetimeContext from "./Context/DatetimeContext";
import common from "../../common/common";
import ImageUploadPendingWindow from "../common/ImageUploadPendingWindow";
import dgLogger from "../../common/dgLogger";
import { AppContext } from "../../AppContext";
import LoginRecommendWindow from "../common/LoginRecommendWindow";
import GroupParticipationWindow from "../common/GroupParticipationWindow";
import CheckForTempReportWindow from "../common/CheckForTempReportWindow";
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ImageUploadPending: 1006,
    CheckForTempReport: 1007,
};

export default function CreateReport() {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const [valid, setValid] = useState(true);
    const [popup, setPopup] = useState(POPUP.None);
    const [isLoading, setIsLoading] = useState(true);
    const [groupList, setGroupList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [reportFormItemList, setReportFormItemList] = useState([]);

    // progress 관련 ref
    const progressbarRef = useRef(null);
    const watermarkUploadedSize = useRef(0);

    // request payload에 삽입 되어야 할 data
    const reportFormId = useRef(null);
    const selectedGroupId = useRef(null);
    const selectedProjectId = useRef(null);

    // child component 에 연결 될 ref array
    const childRefList = useRef([]);

    // 현재 로그인 된 사용자의 정보
    const currentUserInfo = useGetCurrentUserInfo();

    // 수정하기 버튼을 통해 넘어온 data
    const storedReport = useGetStoredReport();

    // 임시 저장 된 데이터 팝업을 통해 넘어온 data
    const tempReport = useGetTempReport();

    // 임시 저장 된 report의 item result
    const tempReportResult = useRef(null);

    // list의 길이만큼 하위 컴포넌트를 참조할 ref 생성
    // validation 시 invalid class가 추가, 삭제 되어야 할 element를 참조 할 ref 생성
    const createChildRefList = useCallback((list) => {
        childRefList.current = list.map((_) => React.createRef());
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    const { SizeContext, sizeContextValue } = useSizeContext();
    const { setResizedUploadSize, setOriginalUploadedSize, getResizedUploadSize } = sizeContextValue;

    const { GPSContext, GPSContextValue } = useGPSContext();
    const { setGPS } = GPSContextValue;

    const { DatetimeContext, datetimeContextValue } = useDatetimeContext();
    const { setDatetime } = datetimeContextValue;

    const {
        getGroupList,
        getGroupItem,
        getProjectList,
        getReportForm,
        createReportFromItemResult,
        getCurrentReportDateValue,
        updateLocationResult,
        isUploadPending,
        updateFileReuslt,
        compareFile,
        createReportUsingResult,
        getDeletedFilesKey,
        getAddedFilesKey,
        deleteImages,
        upsertReportSnapshot,
        deleteReportSnapshot,
        hasReportSnapshotLocalStorage
    } = useCreateReportHook(t);

    // 초기 접근 시 background image를 수정하기 위해 class name 수정
    useEffect(() => {
        document.getElementById("App").className = "create-report";
    }, []);

    // 임시 저장 된 값이 local storage에 있고
    // 임시 저장 된 데이터 팝업을 통하지 않고 자료 제출 페이지에 접근한 경우
    useEffect(() => {
        if (!tempReport && hasReportSnapshotLocalStorage(currentUserInfo?._id)) {
            setPopup(POPUP.CheckForTempReport);
        }
        else {
            setPopup(POPUP.None);
        }
    }, [currentUserInfo?._id, tempReport, hasReportSnapshotLocalStorage]);

    // 10초마다 현재 데이터를 임시 저장
    useEffect(() => {
        if (reportFormItemList.length) {
            const interval = setInterval(() => {
                upsertReportSnapshot({
                    childRefList,
                    reportFormItemList,
                    tempReportResult,
                    storedReport,
                    tempReport,
                    selectedGroupId,
                    selectedProjectId,
                    currentUserInfo,
                    reportFormId
                });
            }, 10 * 1000);

            return () => clearInterval(interval);
        }
    }, [currentUserInfo, reportFormItemList, storedReport, tempReport, upsertReportSnapshot]);

    // 자료 제출에 필요한 정보 획득
    const getDefaultInfo = useCallback(async () => {
        setIsLoading(true);
        // 수정하기 버튼으로 넘어온 값이 있는 경우
        if (storedReport || tempReport) {
            // group _id
            selectedGroupId.current = storedReport?.participating_group || tempReport?.participating_group;
            // project _id
            selectedProjectId.current = storedReport?.participating_project || tempReport?.participating_project;
            // report form _id
            reportFormId.current = storedReport?.report_form_id || tempReport?.report_form_id;

            const groupList = await getGroupItem(selectedGroupId.current);
            const projectList = await getProjectList(selectedGroupId.current);
            const reportForm = await getReportForm(reportFormId.current);

            createChildRefList(reportForm);
            setGroupList(groupList);
            setProjectList([projectList.find(x => x._id === selectedProjectId.current)]);
            setReportFormItemList(reportForm);
            setIsLoading(false);

            setResizedUploadSize(tempReport?.report_form_item_result.reduce((acc, cur) => {
                switch (cur.type) {
                    case "file":
                        return acc + cur.value.reduce((a, c) => a + c.resizeImageSize || 0, 0);
                    case "vendor-ui-2":
                        return acc + cur.value.reduce((a, c) => a + c.value.find(v => v.type == "file")?.value?.[0]?.resizeImageSize || 0, 0);
                    case "vendor-ui-3":
                        return acc + cur.value.reduce((a, c) => a + c.file?.resizeImageSize || 0, 0);
                    default:
                        return acc;
                }
            }, 0) || 0);
        }
        // 자료제출 버튼으로 페이지가 열린 경우
        else {
            const groupList = await getGroupList();
            setGroupList(groupList);
            setProjectList([]);
            setReportFormItemList([]);
            setIsLoading(false);
        }
    }, [storedReport, tempReport, getGroupItem, getProjectList, getReportForm, getGroupList, createChildRefList, setResizedUploadSize]);

    // context에 저장되어 있는 값 초기화
    const resetContext = useCallback(() => {
        setResizedUploadSize(0);
        setOriginalUploadedSize(0);
        setGPS({ latitude: null, longitude: null });
        setDatetime("");
    }, [setResizedUploadSize, setOriginalUploadedSize, setGPS, setDatetime]);

    // page에서 사용되는 값을 초기 값으로 변환
    const resetPage = useCallback(() => {
        resetContext();

        // request payload에 삽입 되어야 할 data
        reportFormId.current = null;
        selectedGroupId.current = null;
        selectedProjectId.current = null;

        getDefaultInfo();
    }, [resetContext, getDefaultInfo]);

    // Mount가 되지는 않았지만, 새롭게 route가 된 경우 page reset
    // 자료 제출 페이지에서 다시 자료 제출 페이지를 이동 할 때 동작
    useEffect(() => {
        resetPage();
    }, [location.key, resetPage]);

    // 그룹 id를 이용하여 프로젝트 목록을 획득하여 저장
    // 선택 된 그룹 id를 저장
    const setProjectListByGroupId = useCallback(async (value) => {
        selectedGroupId.current = value;
        const projectList = await getProjectList(selectedGroupId.current);
        setProjectList(projectList);
    }, [getProjectList]);

    // 그룹을 선택하였을 때 프로젝트 목록을 저장하는 함수 호출
    const groupSelectOnChange = useCallback(async (e) => {
        setProjectListByGroupId(e.target.value);
    }, [setProjectListByGroupId]);

    // groupList 길이가 1이면, 프로젝트 목록을 저장하는 함수 호출
    useEffect(() => {
        if (groupList.length === 1 && groupList[0]?._id !== selectedGroupId.current) {
            setProjectListByGroupId(groupList[0]?._id);
        }
    }, [groupList, setProjectListByGroupId]);

    // 프로젝트 id를 이용하여 자료 제출 폼을 획득하여 저장
    // 선택 된 프로젝트 id를 저장
    const setReportFromItemListByProjectId = useCallback(async (value) => {
        selectedProjectId.current = value;
        const currentProject = projectList.find(project => project._id === selectedProjectId.current);

        if (reportFormId.current !== currentProject.report_form_id) {
            reportFormId.current = currentProject.report_form_id;
            const reportForm = await getReportForm(reportFormId.current);

            resetContext();
            createChildRefList(reportForm);
            setReportFormItemList(reportForm);
        }
    }, [projectList, getReportForm, resetContext, createChildRefList]);

    // 프로젝트를 선택했을 때 자료 제출 폼을 저장하는 함수 호출
    const projectSelectOnChange = useCallback(async (e) => {
        setReportFromItemListByProjectId(e.target.value);
    }, [setReportFromItemListByProjectId]);

    // projectList가 갱신 되었을 때 자료 제출 폼을 저장하는 함수 호출
    useEffect(() => {
        if (projectList.length && projectList[0]?._id !== selectedProjectId.current) {
            setReportFromItemListByProjectId(projectList[0]?._id);
        }
    }, [projectList, setReportFromItemListByProjectId]);

    // progress bar 출력을 위한 function
    const progress = useCallback((loaded, _total) => {
        const pcnt = ((watermarkUploadedSize.current + loaded) / getResizedUploadSize()) * 100;
        progressbarRef.current.updateProgress(pcnt > 100 ? 100 : pcnt);
    }, [getResizedUploadSize]);

    // validation에 실패 했을 때 scroll
    useEffect(() => {
        if (!valid) {
            common.scrollToInvalidElement();
            setValid(true);
        }
        return () => ({});
    }, [valid]);

    // report form item result의 특정 type을 update 하기 위해 사용 되는 함수
    // hook으로 옮기기에는 컴포넌트에 종속 되어 있는 값들을 많이 사용하기 때문에 컴포넌트에 유지
    // location은 geocode 삽입을 위한 동작
    // file이 업로드 되어 있는 item들은 watermark file을 upload하고, file object를 update하는 동작
    const updateReportFromItemResult = useCallback(async (reportFormItemResult) => {
        const promise = [];
        const date = getCurrentReportDateValue(childRefList, reportFormItemList);

        for (const item of reportFormItemResult) {
            const type = item.type;
            const value = item.value;
            const representative_picture = item.representative_picture;

            switch (type) {
                case "location":
                    await updateLocationResult(item);
                    break;
                case "file": {
                    if (!value?.length) {
                        continue;
                    }

                    const isPending = isUploadPending(value);
                    // cloud에 정상적으로 upload 되지 않은 file이 있다면 update 중단
                    if (isPending) {
                        return [true, reportFormItemResult];
                    }

                    const { filePromise, transformRepresentativePicture, transformValue } = await updateFileReuslt({
                        email: currentUserInfo.email,
                        value,
                        representative_picture,
                        date,
                        watermarkUploadedSize,
                        progress,
                    });
                    promise.push(...filePromise);
                    item.representative_picture = transformRepresentativePicture;
                    item.value = transformValue;

                    break;
                }
                case "vendor-ui-2": {
                    if (!value?.length) {
                        continue;
                    }
                    // 전체 value 중 file type item list
                    const fileItemList = value.map(item => {
                        const findItem = item.value.find(item => item.type === "file"); // value object
                        return findItem.value; //value object 밑에 있는 value
                    });

                    // value object 밑에 있는 value의 0번째에 file object가 있으므로 해당 값으로 새로운 array 생성
                    const fileList = fileItemList.map(item => item[0]);

                    const isPending = isUploadPending(fileList);
                    // cloud에 정상적으로 upload 되지 않은 file이 있다면 update 중단
                    if (isPending) {
                        return [true, reportFormItemResult];
                    }

                    const { filePromise, transformValue } = await updateFileReuslt({
                        email: currentUserInfo.email,
                        value: fileList,
                        representative_picture,
                        date,
                        watermarkUploadedSize,
                        progress,
                    });
                    promise.push(...filePromise);

                    // 기존 value replace
                    fileItemList.forEach((_, index) => {
                        const findFileItem = transformValue.find(value => compareFile(value, fileItemList[index][0]));
                        fileItemList[index][0] = findFileItem;
                    });
                    break;
                }
                case "vendor-ui-3": {
                    if (!value?.length) {
                        continue;
                    }

                    const fileList = value.map(item => item?.file);

                    const isPending = isUploadPending(fileList);
                    // cloud에 정상적으로 upload 되지 않은 file이 있다면 update 중단
                    if (isPending) {
                        return [true, reportFormItemResult];
                    }

                    const { filePromise, transformValue } = await updateFileReuslt({
                        email: currentUserInfo.email,
                        value: fileList,
                        representative_picture,
                        date,
                        watermarkUploadedSize,
                        progress,
                    });
                    promise.push(...filePromise);

                    // 기존 value replace
                    value.forEach(item => {
                        const findFileItem = transformValue.find(value => compareFile(value, item?.file));
                        if (item) item.file = findFileItem;
                    });
                    break;
                }
                default:
                    break;
            }
        };

        await Promise.all(promise);

        return [false, reportFormItemResult];
    }, [currentUserInfo, reportFormItemList, getCurrentReportDateValue, compareFile, isUploadPending, progress, updateFileReuslt, updateLocationResult]);

    // 모든 component의 validation을 실행한 뒤 결과를 반환
    // 1개의 component라도 실패한다면 false
    const componentValidation = useCallback(() => {
        const validReuslt = childRefList.current.reduce((acc, child) => {
            const childValid = child.current?.validation?.();
            return acc && childValid;
        }, true);

        return validReuslt;
    }, []);

    // 사용자가 작성한 report를 back end에 전송
    const submitReport = useCallback(async () => {
        const reportFormItemResult = createReportFromItemResult(childRefList, reportFormItemList);
        const [isPending, updatedReportFormItemResult] = await updateReportFromItemResult(reportFormItemResult);

        // cloud에 정상적으로 upload 되지 않은 file이 있다면 submit 중단
        if (isPending) {
            setPopup(POPUP.ImageUploadPending);
            setIsLoading(false);
            return;
        }

        const payload = {
            _id: storedReport?._id || tempReport?.report_id,
            participating_group: selectedGroupId.current,
            participating_project: selectedProjectId.current,
            report_form_result: {
                report_form_id: reportFormId.current,
                report_form_item_result: updatedReportFormItemResult,
            },
        };

        return createReportUsingResult(payload);
    }, [reportFormItemList, storedReport, tempReport, createReportFromItemResult, createReportUsingResult, updateReportFromItemResult]);

    const submitOnClick = useCallback(async (e) => {
        setIsLoading(true);

        // set state to disabled
        e.target.disabled = true;
        // change text to "제출하는 중..."
        const originalInnerText = e.target.innerText;
        e.target.innerText = storedReport || tempReport?.is_modifying ? t('535') : t('536');

        const validReuslt = componentValidation();

        if (validReuslt) {
            try {
                const reportID = await submitReport();
                if (reportID) {
                    const deletedFilesKey = [...(tempReport?.deleted_files_key || []), ...getDeletedFilesKey(childRefList)];
                    deleteImages(deletedFilesKey);
                    deleteReportSnapshot(currentUserInfo?._id);
                    navigate(`/Report/${reportID}`);
                }
            }
            catch (e) {
                dgLogger.error(e)();
            }
        }
        else {
            console.log("form items have vaildation failed items");
            setValid(validReuslt);
        }
        // set state to enabled
        e.target.innerText = originalInnerText;
        e.target.disabled = false;
        setIsLoading(false);
    }, [storedReport, currentUserInfo?._id, tempReport?.is_modifying, tempReport?.deleted_files_key, componentValidation, submitReport, getDeletedFilesKey, deleteImages, deleteReportSnapshot, navigate, t]);

    const tempReportSaveOnClick = useCallback(() => {
        upsertReportSnapshot({
            childRefList,
            reportFormItemList,
            tempReportResult,
            storedReport,
            tempReport,
            selectedGroupId,
            selectedProjectId,
            currentUserInfo,
            reportFormId
        });
    }, [currentUserInfo, reportFormItemList, storedReport, tempReport, upsertReportSnapshot]);

    const tempReportRemoveOnClick = useCallback(() => {
        deleteReportSnapshot(currentUserInfo?._id);
        const addedFilesKey = [...(tempReport?.added_files_key || []), ...getAddedFilesKey(childRefList)];
        deleteImages(addedFilesKey);
        common.navigateBack(navigate);
    }, [currentUserInfo?._id, tempReport?.added_files_key, deleteImages, deleteReportSnapshot, getAddedFilesKey, navigate]);

    const Popups = useCallback(() => {
        if (popup === POPUP.ImageUploadPending) {
            return (
                <ImageUploadPendingWindow
                    onConfirm={() => setPopup(POPUP.None)}
                />
            );
        }
        else if (popup === POPUP.CheckForTempReport) {
            return (
                <CheckForTempReportWindow
                    onClose={() => setPopup(POPUP.None)}
                />
            );
        }
        else return <></>;
    }, [popup]);

    // context의 초기 값은 null이 삽입되어 있어, 로그인 상태 판별이 불가하므로 확실하게 false가 되었을 때 처리
    if (context.loggedIn === false) {
        return <LoginRecommendWindow />;
    }
    // 기본 적으로 null group에 가입 되기 때문에 최소 1개의 그룹에는 가입이 되어 있으므로 1개 이하로 판단
    else if (context.loggedIn === true && currentUserInfo?.groups?.length <= 1) {
        return <GroupParticipationWindow />;
    }

    const viewProps = {
        progressbarRef,
        isLoading,
        groupList,
        projectList,
        reportFormItemList,
        childRefList,
        previousData: storedReport || tempReport,
        tempReport,
        groupSelectDisabled: Boolean(storedReport || tempReport),
        projectSelectDisabled: Boolean(!groupList.length || storedReport || tempReport?.is_modifying),
        reportFormId,
        currentUserInfo,
        isModifying: Boolean(storedReport || tempReport?.is_modifying),
        groupSelectOnChange,
        projectSelectOnChange,
        submitOnClick,
        tempReportSaveOnClick,
        tempReportRemoveOnClick,
        Popups
    };

    return (
        <SizeContext.Provider value={sizeContextValue}>
            <GPSContext.Provider value={GPSContextValue}>
                <DatetimeContext.Provider value={datetimeContextValue}>
                    <CreateReportView
                        key={location.key}
                        {...viewProps}
                    />
                </DatetimeContext.Provider>
            </GPSContext.Provider>
        </SizeContext.Provider>
    );
}
