import { useEffect, useRef, useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from 'swr';
import Api from "../Api";
import { error as E } from "@ocean-knight/shared";
import { AppContext } from "../AppContext";
import dgLogger from "./dgLogger";

/**
 * @example <caption>사용법</caption>
    const Div = () => {
      const [state, setState] = useState(0);
      const isMount = useIsMount();
       useEffect(() => {
        (async function foo() {
          try {
            const val = await fakeFetch();
             if (isMount.current) {
              setState(val);
            }
          } catch (error) {
            dgLogger.error(error)();
            setState(error.message);
          }
        })();
      }, [isMount]);
       return <div style={{ backgroundColor: "red" }}>{state}</div>;
    };
 * @see https://jcon.tistory.com/190
 * @returns mount 상태(T/F)
 */
const useIsMount = () => {
    let isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;

        return () => {
            isMount.current = false;
        };
    }, []);

    return isMount;
};

/**
 * @example <caption>사용법</caption>
    export default function Navigation(props) {
        const { notifications } = useNotification();
        return (
            {notifications && notifications.length > 0 ? `🔔${notifications.length > 9 ? "9+" : notifications.length}` : undefined }
        );
    }
 * @returns notification (object) list
 */
const useNotification = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const fetcher = _ => Api.getNotifications().then(payload => payload);

    const { data, error } = useSWR(context.loggedIn ? "useNotification" : null, fetcher);
    if (error?.code === E.UNAUTHORIZED) {
        Api.logout()
            .then(() => {
                // dgLogger.info("logout success")();
            })
            .catch(e => {
                dgLogger.error(e)();
            })
            .finally(() => {
                context.setLoggedIn(false);
                navigate("/");
            });
    }

    return {
        notifications: error ? [] : data,
    };
};

const useUserinfo = (loggedIn) => {
    const context = useContext(AppContext);
    const fetcher = _ => Api.getCurrentUserInfo({ optPermissions: true }).then(payload => payload);
    const { data, error } = useSWR(context.loggedIn ? "useUserinfo" : null, fetcher);

    return {
        userInfo: error ? null : data,
    };
};

const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

const useScroll = () => {
    const [scrollY, setScrollY] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (loading) {
                setScrollY(window.pageYOffset);
                setLoading(false);
            }
        });
        return () => {
            setLoading(true);
        };
    }, [loading]);

    return {
        scrollY
    };
};

/**
 * state 가 갱신된 후 callback 이 있다면, callback 을 호출
 * @param {*} initialState 
 * @returns [state, setState(newState, (newState) => {...})]
 * @see https://velog.io/@yhko1992/useState-%EC%97%90-%EB%91%90%EB%B2%88%EC%A7%B8-%EC%9D%B8%EC%9E%90%EB%A1%9Ccallback-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0
 */
const useStateWithCallback = (initialState) => {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);
  
    // useCallback을 이용해서 cb의 reference를 유지해준다
    // 그리고 Ref에 저장한다
    // 그리고 state를 업데이트 한다.
    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; 
      setState(state);
    }, []);

    // useEffect를 이용해서 setStateCallback을 통해 state가 업데이트 되면 useEffect안에 있는 함수가 실행된다.
    // Ref안에 저장해두었던 두번째 인자 ,callback 함수에 업데이트 된 state를 넣고 실행한다.
    // 그다음 ReF를 초기화 시켜 다음 들어올 callback을 받을 준비를 한다.
    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);
  
    return [state, setStateCallback];
};

export {
    useIsMount,
    useNotification,
    useUserinfo,
    useScript,
    useScroll,
    useStateWithCallback
};
