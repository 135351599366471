import React, { useState, forwardRef, useEffect, useCallback, useRef, useMemo, useImperativeHandle } from 'react';
import WithTextHandle from "../hoc/WithTextHandle";
import useSpinnerBoxHook from './SpinnerBoxHook';
import SpinnerBoxView from "./SpinnerBoxView";
import { validateFloat } from '../common/common';
import common from '../../../common/common';

const SpinnerBox = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { title, description, required, unit } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const pasteData = useRef("");
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        textValidation,
        setSpinnerBoxValue,
        getSpinnerBoxValue
    } = useSpinnerBoxHook(ref);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setSpinnerBoxValue(defaultValue);
    }, [defaultValue, setSpinnerBoxValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getSpinnerBoxValue();
        const { valid, errorCollection } = textValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getSpinnerBoxValue, textValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    const onBlurHandle = useCallback((e) => {
        setSpinnerBoxValue(e.target.value);
        validation();
    }, [validation, setSpinnerBoxValue]);

    // 입력 된 값이 바뀌었을 떄 호출 되는 handler
    // 입력 된 값이 float 형태에 맞지 않을 때 형태를 보정하여 value 수정
    const onChangeHandle = useCallback((e) => {
        const value = validateFloat(e, pasteData.current);
        e.target.value = value;
        pasteData.current = "";
    }, []);

    // 복사 붙여 넣기로 입력 된 값이 있다면 해당 값 저장
    const onPasteHandle = useCallback((e) => {
        pasteData.current = e.clipboardData.getData("Text");
    }, []);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        unit,
        type,
        defaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        onBlurHandle,
        onChangeHandle,
        onPasteHandle
    };

    return (
        <SpinnerBoxView {...viewProps} />
    );
});

export default WithTextHandle(SpinnerBox);
