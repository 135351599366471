import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Api from "../../Api";
import Comment from "../common/Comment";
import Map from "../common/Map";
import * as Config from "../common/MapConfig";
import { useIsMount } from "../../common/customHook";
import TopReporterSection from "./TopReporterSection";
import ParticipantsSection from "./ParticipantsSection";
import RegisterSection from "./RegisterSection";
import Modal from "../common/Modal";
import { Container, Col, Row } from "react-bootstrap";
import { POPUP as GeneralPopup } from "../../common/defines";
import common from "../../common/common";
import { PopupRemoveProject } from "../common/RemoveWindow";
import ImageGallery from "react-image-gallery";
import "./DetailedProject.css";
import ImagePreviewWindow from "../common/ImagePreviewWindow";
import GeneralErrorWindow from '../common/GeneralErrorWindow';
import LoadingSpinner from "../common/LoadingSpinner";
import { Mobile, PC, IsPC, IsTablet, MobileOrTablet, TabletOrPC } from "../common/MediaQuery";
import { LinkCopyWindow } from "../common/LinkCopyWindow";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";
import { LOCATION2STRTABLEMAP } from "@ocean-knight/shared/src/utility";

const POPUP = {
    ...GeneralPopup,
    ImagePreview: 1004,
    LinkCopy: 1005,
};

export default function DetailedProject() {
    const { t } = useTranslation();
    const [state, setState] = useState({
        info: null,
        data: [],
        popup: POPUP.None,
    });
    const isPc = IsPC();
    const { _id } = useParams();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const mapOption = useRef(common.getDefaultMapSetting());
    const imageGalleryRef = useRef();
    const isTablet = IsTablet();

    useEffect(() => {
        document.getElementById("App").className = "project-detail";
        (async () => {
            try {
                const payload = { projectId: _id };
                const data = await Api.getReportsInProject(payload);
                const info = await Api.getProjectInfo(payload);
                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    data: data,
                    info: info
                }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        })();
    }, [_id, isMount]);

    const endProject = async () => {
        if (new Date(state.info.end_date) < new Date()) {
            alert(t('525'));
            setState({ ...state, popup: POPUP.None });
            return;
        }

        try {
            const payload = { projectId: _id };
            await Api.endProject(payload);
            const info = await Api.getProjectInfo(payload);
            setState({
                ...state,
                info: info,
                popup: POPUP.None
            });
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    const removeProject = useCallback(() => {
        const payload = { projectId: _id };

        Api.removeProject(payload)
            .then(() => {
                const keys = state.info?.pictures.map((picture) => picture.key);
                Api.deleteFiles(keys);
                const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                sessionStorage.setItem("prevPathname", prevPathname);
                navigate("/Project", { replace: true });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [_id, state.info?.pictures, navigate]);

    const popupEndProject = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/icon_project_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_project_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_project_close@3x.png 3x`} alt="" />;
        const header = <div>{t('243')}</div>;
        const body = <div>{t('244')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None })}
                onConfirm={endProject}
                onCancel={() => setState({ ...state, popup: POPUP.None })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Update) return popupEndProject();
        if (state.popup === POPUP.Remove) return (<PopupRemoveProject id="remove-project" onConfirm={() => removeProject()} onCancel={() => setState({ ...state, popup: POPUP.None })}/>);
        if (state.popup === POPUP.LinkCopy) return <LinkCopyWindow onConfirm={() => setState({ ...state, popup: POPUP.None })} />;
        if (state.popup === POPUP.ImagePreview) {
            return (
                <ImagePreviewWindow
                    images={state.info?.pictures.map((picture) => ({ watermark: picture.url, thumbnail: picture.url }))}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    index={state.popupTarget}
                />
            );
        }
    };

    /**
     * 사용자가 프로젝트를 종료(완료) 처리 할수 있는지 확인 합니다.
     * * 현재 사용자가 프로젝트를 생성했고, 아직 완료일이 지나지 않았다면, 종료 가능합니다.
     * @returns T/F
     */
    const isClosableProject = () => {
        if (state.info?.built_in) return false;

        const hasPermission = state.info.completePermission;
        const active = new Date(state.info.end_date).getTime() > new Date().getTime() + (1000 * 30);

        return hasPermission && active;
    };

    const getButtons = () => {
        return (
            <Col className="button-group text-end">
                {isClosableProject() && (
                    <button className="complete-project notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-fff" onClick={() => setState({ ...state, popup: POPUP.Update })}>
                        {t('243')}
                    </button>
                )}
                {state.info.modifyPermission &&
                    <CustomButtonLink className="modify-project notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-fff" to="/CreateProject" state={state.info}>
                        {t('245')}
                    </CustomButtonLink>
                }
                {(state.info.deletePermission && !state.info?.built_in) && (
                    <button className="remove-project notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-fff" onClick={() => setState({ ...state, popup: POPUP.Remove })}>
                        {t('246')}
                    </button>
                )}
            </Col>
        );
    };

    const getProjectInfo = () => {
        return (
            <Row className="gx-0">
                <Col className="info">
                    <Row className="gx-0 info-row-head">
                        <Col className="">
                            <Row className="gx-0 align-items-center">
                                <Col className="project-name notosanskr-600 font-size-28 c-333 ls--1.4px text-start text-break">{state.info.name}</Col>
                            </Row>
                        </Col>
                        <Col className="text-end share col-auto">
                            <button
                                onClick={() => {
                                    common.copyUrlToClipboard(window.location.href);
                                    setState({ ...state, popup: POPUP.LinkCopy });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="gx-0 info-row-body align-items-center text-start">
                        <Col className="info-row-inner-body">
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('236')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px">{state.info.user ? state.info.user.name : ""}</Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('237')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px">{new Date(state.info.register_date).toLocaleDateString()}</Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('238')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px">{state.info.group ? common.i18nGroupName(state.info.group.name) : ""}</Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('239')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px">
                                    {new Date(state.info.start_date).toLocaleDateString()} ~ {new Date(state.info.end_date).getFullYear() < 10000 && new Date(state.info.end_date).toLocaleDateString()}
                                </Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('240')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px">{t(`${LOCATION2STRTABLEMAP[state.info.project_area] || state.info.project_area}`)}</Col>
                            </Row>
                            <Row className="gx-0 info-row align-items-center">
                                <Col className="col-auto d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px mr-8px">•</Col>
                                <Col className="w-140px maxw-140px d-inline-block notosanskr-500 font-size-18 c-000 lh-1.33 ls--0.9px col-auto">{t('241')}</Col>
                                <Col className="notosanskr-400 font-size-16 c-333 ls--0.8px" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{state.info.about}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <TabletOrPC>
                        <Row className="gx-0 align-items-center">
                            {getButtons()}
                        </Row>
                    </TabletOrPC>
                </Col>
            </Row>
        );
    };

    const getProjectTalk = () => {
        return (
            <Col className="project-talk">
                <Row className="gx-0 align-items-center top-bar">
                    <Col className="col-auto notosanskr-300 font-size-30 title">
                        <Trans i18nKey={'250'} components={{tag: <span className="d-block:sm notosanskr-600 font-size-30"></span>}} />
                    </Col>
                    <Col>
                        <div className="line" />
                    </Col>
                    <Col className="col-auto">
                        <div className="dot" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Comment
                            _id={_id}
                            getComment={Api.getProjectComment}
                            addComment={Api.addProjectComment}
                            removeComment={Api.removeProjectComment}
                        />
                    </Col>
                </Row>
            </Col>
        );
    };

    if (state.popup === POPUP.GeneralError)
        return (
            <GeneralErrorWindow
                message={state.popupTarget}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    if (!state.info) return <LoadingSpinner isOpen={true} />;

    return (
        <div id="project-detail">
            {popups()}
            <Container>
                <span className="project-detail-sub-title">
                    <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff c-fff project-detail">Project</span>
                    <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('3')}</span>
                </span>
            </Container>
            <Mobile>
                <div className="d-flex" style={{backgroundColor: "#f2f4f7", marginBottom: "27px"}}>
                    <Container>
                        {getButtons()}
                    </Container>
                </div>
            </Mobile>
            <Container className="project-info">
                <Row className="gx-0">
                    <Col className="images maxw-660px" lg={12}>
                        <ImageGallery
                            ref={imageGalleryRef}
                            showNav={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            thumbnailPosition={"bottom"}
                            originalClass={".original"}
                            onClick={() => setState({ ...state, popup: POPUP.ImagePreview, popupTarget: imageGalleryRef.current.getCurrentIndex() })}
                            items={
                                state.info?.pictures
                                    ? state.info?.pictures.map((picture) => ({
                                          original: picture?.url,
                                          thumbnail: picture?.url,
                                          originalWidth: "659px",
                                          originalHeight: `${isPc ? "440px" : isTablet ? "332px" : "224px"}`,
                                          originalClass: "project-info-images-original",
                                          thumbnailWidth: "101px",
                                          thumbnailHeight: `${isPc ? "87px" : isTablet ? "82px" : "78px"}`,
                                          thumbnailClass: "project-info-images-thumbnail",
                                      }))
                                    : []
                            }
                        />
                    </Col>
                    <Col>
                        {getProjectInfo()}
                    </Col>
                </Row>
            </Container>

            <div className="project-record">
                <RegisterSection projectId={_id} itemsCountPerPage={4} />
            </div>

            <Container className="project-contributes">
                <ParticipantsSection projectId={_id} itemsCountPerPage={7} />
            </Container>

            <Container className="reports">

                <Row className="gx-0">
                    <PC>
                        {getProjectTalk()}
                    </PC>
                    <Col>
                        <Row>
                            <Col className="top-reporter-section">
                                <TopReporterSection projectId={_id} group={state.info.group._id}/>
                            </Col>
                        </Row>
                        {state.data?.length > 0 &&
                            <Row>
                                <Col>
                                    <Map
                                        style={{
                                            height: `${isPc ? "609px" : "563px"}`,
                                            borderRadius: "6px",
                                            overflow: "hidden",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            marginBottom: `${isPc && !isTablet ? "0px" : "30px"}`,
                                        }}
                                        data={state.data ? state.data : undefined}
                                        center={{ lat: mapOption.current.lat, lng: mapOption.current.lng }}
                                        zoom={mapOption.current.zoom}
                                    />
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                <MobileOrTablet>
                    <Row>
                        {getProjectTalk()}
                    </Row>
                </MobileOrTablet>
            </Container>
        </div>
    );
}
