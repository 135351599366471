import React, { useState } from "react";
import { PopupRequestReportLicense, PopupRequestedReportLicense } from "../common/RequestDataUseWindow";
import { POPUP as GeneralPopup } from "../../common/defines";
import CustomCheckbox from "../common/CustomCheckbox";
import "./ReportLicenseButton.css";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const POPUP = {
    ...GeneralPopup,
    RequestDataUseWindow: 1004,
};

export default function ReportLicenseButton(props) {
    const { t } = useTranslation();
    const [popup, setPopup] = useState(POPUP.None);
    const navigate = useNavigate();

    /**
     * popup을 현재 state에 따라 띄워주는 함수
     */
    const popups = () => {
        if (popup === POPUP.Request) return (<PopupRequestReportLicense onSubmit={(payload) => {
            props.requestReportLicense(payload);
            props.resetCheckbox();
            setPopup(POPUP.Requested);
        }} onCancel={() => {
            setPopup(POPUP.None);
        }} />);
        if (popup === POPUP.Requested) return (<PopupRequestedReportLicense
            onRequestClose={() => navigate("/Mypage/RequestDataUse") }
            onConfirm={() => navigate("/Mypage/RequestDataUse") }
            // onConfirm={() => {
            //     setPopup(POPUP.None);
            // }}
        />);
    };

    /**
     * 전체 checkbox를 눌렀을 때 현재 page의 모든 checkbox를 동일한 상태로 만들어주는 함수
     */
    const requestReportLicenseAllCheck = (e) => {
        const flag = e.target.checked;
        for (let i = 0; i < props.itemsCountPerPage; i++) {
            if (document.getElementById(props.id_prefix + i) && document.getElementById(props.id_prefix + i).labels[0].style.display !== "none") {
                document.getElementById(props.id_prefix + i).checked = flag;
            }
        }
        document.getElementById(props.button_id).disabled = !flag;
    };

    return (
        <div className="report-license">
            {popups()}
            <Row className="align-items-center">
                <Col className="text-start">
                    <CustomCheckbox
                        id="all-check"
                        onChange={requestReportLicenseAllCheck}
                    >
                        <span className="notosanskr-15 notosanskr-13:sm">
                            {t('71')}
                        </span>
                    </CustomCheckbox>
                </Col>
                <Col className="text-end">
                    <button
                        className="c-fff notosanskr-400 font-size-16"
                        onClick={() => setPopup(POPUP.Request)}
                        id={props.button_id}
                    >
                        {t('72')}
                    </button>
                </Col>
            </Row>
        </div>
    );
}
