import "./Title.css";
import { Col } from "react-bootstrap";
import { InputText } from "../../Input";
import { useTranslation } from "react-i18next";

const MAX_TITLE_LENGTH = 20;

const TitleView = ({
    title,
    description,
    type,
    defaultValue,
    invalid,
    errorMessage,
    onBlurHandle
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Col className="item-title notosanskr-500 font-size-17">{title}</Col>
            <Col className={`item-full-content flex-wrap ${type}`}>
                {description && <label className="top-description notosanskr-500 font-size-15">{description}</label>}
                <InputText
                    className={invalid}
                    placeholder={t('146', {'%d': MAX_TITLE_LENGTH})}
                    maxLength={MAX_TITLE_LENGTH}
                    name={type}
                    onBlur={onBlurHandle}
                    defaultValue={defaultValue}
                />
                <label className="error">
                    {errorMessage}
                </label>
            </Col>
        </>
    );
};

export default TitleView;
