import React from "react";
import Modal from "../../common/Modal";
import "./WithdrawSiteMemberWindow.css";
import { WithdrawMember } from "./WithdrawMemberWindow";
import { useTranslation } from "react-i18next";

const WithdrawSiteMember = (props) => {
    const { t } = useTranslation();
    return <WithdrawMember targets={props.users} title={t('495')} onCancel={props.onCancel} onConfirmed={props.onConfirmed} className={props.className} rejoin={false} />;
};

const WithdrawSiteMemberRequest = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_join_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_join_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_join_withdraw@3x.png 3x`} alt="" />;
    const header = <div>{t('495')}</div>;
    const body = <div>{t('497')}</div>;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={props.onConfirmed}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const WithdrawSiteMemberRequested = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_join_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_join_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_join_withdraw@3x.png 3x`} alt="" />;
    const header = <div>{t('498')}</div>;
    const body = <div className="c-4270e0">{t('447')} {props.reason}</div>;

    return (
        <Modal
            onRequestClose={ props.onConfirmed}
            onConfirm={ props.onConfirmed}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { WithdrawSiteMember, WithdrawSiteMemberRequest, WithdrawSiteMemberRequested };
