import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const PopupRemoveProject = (props) => {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/icon_project_delete.png`} srcSet={`${process.env.PUBLIC_URL}/icon_project_delete@2x.png 2x, ${process.env.PUBLIC_URL}/icon_project_delete@3x.png 3x`} alt="" />;
    const header = <div>{t('246')}</div>;
    const body = <div>{t('247')}</div>;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const PopupRemoveReport = (props) => {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/icon_data_delete2.png`} srcSet={`${process.env.PUBLIC_URL}/icon_data_delete2@2x.png 2x, ${process.env.PUBLIC_URL}/icon_data_delete2@3x.png 3x`} alt="" />;
    const header = <div>{t('85')}</div>;
    const body = <div>{t('103')}</div>;

    return (
        <Modal
            onRequestClose={props.onCancel}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { PopupRemoveProject, PopupRemoveReport };
