import React, { useState, useRef, useEffect, useCallback } from "react";
import { useIsMount } from "../../common/customHook";
import Api from "../../Api";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterSection.css";
import { ReportCard } from './../Report/ReportCard';
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";
import common from "../../common/common";

export default function RegisterSection(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        register : []
    });
    const isMount = useIsMount();
    const currentPage = useRef(1);
    const paginationOption = useRef({ itemsCountPerPage: props.itemsCountPerPage ? props.itemsCountPerPage : 4, pageRangeDisplayed: null, totalItemsCount: 0 });

    const getProjectRegister = useCallback(async (page) => {
        const payload = await Api.getProjectRegister({
            projectId: props.projectId,
            currentPage: page,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            sort: { register_date: -1 },
        });
        currentPage.current = page;
        paginationOption.current.totalItemsCount = payload.totalItemsCount;
        return payload.register;
    }, [props.projectId]);

    useEffect(() => {
        getProjectRegister(currentPage.current)
            .then((register) => {
                if (!isMount.current) return;
                setState(prev => ({...prev, register: [...prev.register, ...register] }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [getProjectRegister, isMount]);

    const moreClick = (e) => {
        e.target.disabled = true;

        const page = currentPage.current + 1;
        getProjectRegister(page)
            .then((register) => {
                setState(prev => ({...prev, register: [...prev.register, ...register] }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            })
            .finally(() => e.target.disabled = false);
    };

    return (
        <Container id="register-section">
            <Row className="gx-0 align-items-center top-bar">
                <Col className="notosanskr-300 font-size-30 title text-start ls--1px col-auto">
                    <Trans i18nKey={'242'} components={{tag: <span className="d-block:sm notosanskr-600 font-size-30"></span>}}/>
                </Col>
                <Col>
                    <div className="line" />
                </Col>
                <Col className="count notosanskr-400 font-size-14 c-white col-auto text-center lh-0.95 ls--0.7px">
                    <span className="notosanskr-800 font-size-21 c-92c83d pe-2">{paginationOption.current.totalItemsCount}</span>{t('234')}
                </Col>
            </Row>
            <Row className="gx-0 report-card-row">
                {state.register.map((report) => (
                    <Col key={report._id} className="report-card-wrap col-auto">
                        <ReportCard
                            checkbox={false}
                            report_id={report._id}
                            registered_by={report.registered_by}
                            date={report.date}
                            register_date={report.register_date}
                            group_name={common.i18nGroupName(report.group_name)}
                            report_file_url={report.report_file_url}
                            title={report.title}
                        />
                    </Col>
                ))}
            </Row>

            {paginationOption.current.totalItemsCount > state.register.length && (
                <Row key={state.register.length} className="view-more-row gx-0 align-items-center">
                    <Col>
                        <div className="line" />
                    </Col>
                    <Col className="col-auto">
                        <button className="more notosanskr-400 font-size-18 lh-1.33 ls--0.9px c-white" onClick={moreClick}>
                            {t('4')}
                        </button>
                    </Col>
                    <Col>
                        <div className="line" />
                    </Col>
                </Row>
            )}
        </Container>
    );
};
