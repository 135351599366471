import { useCallback, useContext, useImperativeHandle } from "react";
import useDatetimeContext from "../Context/DatetimeContext";
import { useTranslation } from "react-i18next";

export default function useTimeRangeHook(ref) {
    const { t } = useTranslation();
    const { DatetimeContext } = useDatetimeContext();
    const datetimeContext = useContext(DatetimeContext);

    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const timeRangeValidation = useCallback(({ start, end, required }) => {
        return ref.current?.timeRangeValidation?.({ start, end, required });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setTimeRangeValue = useCallback((start, end) => {
        ref.current?.setValue?.({ start, end }); // iso date 삽입
    }, [ref]);

    const getTimeRangeValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    // 현재 context에 있는 datetime 반환
    const getContextDatetimeValue = useCallback(() => {
        return datetimeContext.getDatetime();
    }, [datetimeContext]);

    /**
     * 시작 시간으로부터 30분을 추가 한 시간 반환, 자정이 넘는 시간이 된다면 23:59으로 고정 반환
     * @param {string} startTime 시작 시간
     * @returns 30분이 추가 된 time string
     */
    const add30MinutesInTimeRange = useCallback((startTime) => {
        const endTimeString = startTime;
        const [stringHour, stringMinute] = endTimeString.split(":");
        let [hour, minute] = [parseInt(stringHour), parseInt(stringMinute) + 30];
        hour += parseInt(minute / 60);
        minute %= 60;
        if (hour > 23) {
            return "23:59";
        }
        else {
            if (hour < 10) hour = `0${hour}`;
            if (minute < 10) minute = `0${minute}`;
            return `${hour}:${minute}`;
        }
    }, []);

    /**
     * parameter로 전달된 시간 사이 간격 반환
     * @param {*} start 시작 시간
     * @param {*} to 종료 시간
     * @returns 시간시간과 종료시간 차이
     */
    const timeDiff = useCallback((start, end) => {
        start = start.split(":");
        end = end.split(":");
        const startDate = new Date(0, 0, 0, start[0], start[1], 0);
        const endDate = new Date(0, 0, 0, end[0], end[1], 0);
        const diff = endDate.getTime() - startDate.getTime();
        // const hours = Math.floor(diff / 1000 / 60 / 60);
        // diff -= hours * 1000 * 60 * 60;
        // const minutes = Math.floor(diff / 1000 / 60);
        return diff;
    }, []);

    // 현재 form에서만 사용 될 validation
    // 자료 제목으로 사용 할 수 없는 문자가 있는 지 확인
    const childValidation = useCallback(({ start, end }) => {
        // 종료 시간이 더 큰(늦은) 경우 양수 반환
        const valid = timeDiff(start, end) >= 0;
        const state = valid;
        const message = valid ? '' : t('530');
        const errorCollection = { start: { state, message }, end: { state, message } };

        return { valid, errorCollection };
    }, [timeDiff, t]);

    useImperativeHandle(ref, () => (
        { ...ref.current, childValidation }
    ), [ref, childValidation]);

    return {
        timeRangeValidation,
        setTimeRangeValue,
        getTimeRangeValue,
        getContextDatetimeValue,
        add30MinutesInTimeRange,
    };
};
