import { useCallback } from "react";

export default function useTextboxHook(ref) {
    // HOC에서 공유 된 validation을 사용하기 위한 wrapper function
    const textValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.textValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setTextBoxValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getTextBoxValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    return {
        textValidation,
        setTextBoxValue,
        getTextBoxValue,
    };
};
