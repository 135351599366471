import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import Api from "../../Api";
import Map from "../common/Map";
import * as Config from "../common/MapConfig";
import common from "../../common/common";
import { useIsMount } from "../../common/customHook";
import Comment from "../common/Comment";
import ImageGallery from "react-image-gallery";
import { strings, utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import "react-image-gallery/styles/css/image-gallery.css";
import { POPUP as GeneralPopup, GROUP_STATE } from "../../common/defines";
import { PopupRequestReportLicense, PopupRequestedReportLicense } from "../common/RequestDataUseWindow";
import { PopupRemoveReport } from "../common/RemoveWindow";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Emblem from "../common/Emblem";
import "./DetailedReport.css";
import GeneralErrorWindow from './../common/GeneralErrorWindow';
import ImagePreviewWindow from "../common/ImagePreviewWindow";
import LoadingSpinner from "../common/LoadingSpinner";
import Modal from "../common/Modal";
import { PC, MobileOrTablet, IsTabletOrPC, IsMobile, IsWebview } from "../common/MediaQuery";
import { LinkCopyWindow } from "../common/LinkCopyWindow";
import CustomButtonLink from "../common/CustomButtonLink";
import ImagePreviewTable from "../common/ImagePreviewTable";
import dgLogger from "../../common/dgLogger";
import CheckForTempReportWindow from "../common/CheckForTempReportWindow";
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ImagePreview: 1004,
    DownloadRequest: 1005,
    LinkCopy: 1006,
    CheckForTempReport: 1007,
};

export default function DetailedReport() {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const [state, setState] = useState({
        report: null,
        report_form: [],
        user: null, // 현재 로그인 한 사용자 (로그인 하지 않았다면, null)
        formData: [],
        locations: [],
        files: [], // 자료에 첨부된 파일들
        downloadable: false,
        dataUse: null, // 데이터 사용권한 (로그인 하지 않았다면, null)
        popup: POPUP.None,
        popupTarget: null,
        grade: null,
        isAdmin: false,
        isDownloadLoading: false,
    });
    const isMount = useIsMount();
    const navigate = useNavigate();
    const { _id } = useParams();
    const basedOption = useRef(common.getDefaultMapSetting());
    const imageGalleryRef = useRef();
    const isTabletOrPC = IsTabletOrPC();
    const isMobile = IsMobile();
    const sumAnswers = useRef({});

    const isWebview = IsWebview();

    // convert iso date to 'YYYY-MM-DD' date
    let convertToLocaleDate = (date) => {
        // notes, redkur; 'fr-CA' formats string as 'YYYY-MM-DD'
        let localDate = new Date(date).toLocaleDateString('fr-CA');
        return localDate;
    };

    useEffect(() => {
        document.getElementById("App").className = "report-detail";
        const getContents = async () => {
            try {
                let reportTitle = "";
                const report = await Api.getReportsItem(_id);
                const report_form = await Api.getReportForm({
                    report_form_id: report.report_form_id,
                });
                const formData = report_form.report_form_item_ids.reduce((acc, id) => {
                    const form_item = report.report_form_item.find((form) => form._id === id);
                    const result = report.report_form_item_result.find((result) => result.report_form_item_id === id);

                    if (!(form_item && result)) {
                        return acc;
                    }

                    let _value = result.value;
                    if (form_item.type === "title") {
                        reportTitle = _value;
                    }
                    else {
                        if (form_item.type === "date") {
                            _value = convertToLocaleDate(result.value);
                        }

                        if (form_item.sub_items?.length) {
                            form_item.sub_items = common
                                .subItemsWithoutEmptyValue(form_item.sub_items, null)
                                .map((item, i) => ({ ...item, ...(form_item.i18n?.[common.getLang()].sub_items[i] || {}) }));
                        }

                        acc.push({
                            _id: form_item._id,
                            title: form_item.i18n?.[common.getLang()]?.fields?.title || form_item.fields.title,
                            description: form_item.i18n?.[common.getLang()]?.fields?.description || form_item.fields.description,
                            type: form_item.type,
                            required: form_item.fields.required,
                            value: _value,

                            // raw values
                            form: form_item,
                            result: result,
                        });
                    }

                    return acc;
                }, []);
                const files = formData.reduce((acc, cur) => { // 자료 첨부파일들 획득
                    if (cur.type === "file") acc = acc.concat(cur.value);
                    return acc;
                }, []);

                const locations = report.report_form_item_result.reduce((acc, cur) => {
                    if (cur.type !== "location") return acc;
                    acc.push({ lat: cur.value.latitude, lng: cur.value.longitude });
                    return acc;
                }, []);

                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    report: report,
                    report_form: report_form,
                    formData: formData,
                    files: files,
                    locations: locations,
                    reportTitle: reportTitle,
                }));
            } catch (err) {
                dgLogger.error(err)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: err.toString() }));
            }
        };

        getContents();
    }, [_id, isMount]);

    useEffect(() => {
        (async () => {
            if (context.loggedIn && state.report) {
                const user = await Api.getCurrentUserInfo();
                const payload = await Api.getFilteredReportLicense({ states: [utility.STATE.REVIEWING, utility.STATE.ACQUIRED], userId: user._id, reportId: _id, sort: { requested_date: -1 } });
                const acquireState = payload.licenses.length > 0 ? payload.licenses[0] : null;
                const downloadable = await Api.isDownloadableReport({ reportID: _id });
                const grade = await Api.getCurrentUserPermissionFromGroup({ groupId: state.report.participating_group });
                const isAdmin = utility.hasSiteAdminPermission((await Api.getCurrentUserMaxPermission()).grade);


                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    user: user,
                    dataUse: acquireState,
                    downloadable: downloadable,
                    grade: grade,
                    isAdmin: isAdmin,
                }));
            }
        })();
    }, [state.report, _id, context.loggedIn, isMount]);

    const isModifiable = useCallback(() => {
        if (!state.report) return false;
        // 자료를 등록한 사용자이면서, 그룹과 프로젝트가 삭제 되지 않은 경우 수정 가능
        // 프로젝트가 삭제되면 DB에서 lookup을 할 때 project 정보가 없어지는 것을 이용하여 구현
        return (state.user && state.report.registered_by === state.user?._id && state.report?.group_state === GROUP_STATE.active && state.report.project);
    }, [state.report, state.user]);

    const isRemovable = useCallback(() => {
        if (!state.report) return false;
        // 자료를 등록한 사용자이거나, 그룹 관리자 이거나, 사이트 관리자인 경우, 자료를 삭제할 수 있습니다.
        return (state.user && state.report.registered_by === state.user?._id) || utility.hasGroupManagerPermission(state.grade) || state.isAdmin;
    }, [state.report, state.grade, state.isAdmin, state.user]);

    const isPossibleRequestDataUse = useCallback(() => {
        if (!state.report) return false;
        if (state.downloadable) return false; // 다운로드 가능한 상태라면, 사용권한 요청이 불필요함.

        // 내가 올린 자료라면, 자료 사용권 요청을 하지 않습니다.
        if (state.report.registered_by === state.user?._id) return false;

        // 사이트 관리자가 아닌 로그인 사용자인데, 아직 사용권한이 history 가 없거나, acquired 가 아니라면, 사용권한 요청이 가능함.
        return !state.isAdmin && (!state.dataUse || state.dataUse?.state !== utility.STATE.ACQUIRED);
    }, [state.isAdmin, state.report, state.user, state.dataUse, state.downloadable]);

    const isPossibleDownloadRaw = useCallback(() => {
        if (!state.report) return false;

        return state.downloadable;
    }, [state.report, state.downloadable]);

    const popupDownloadRequest = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt="" />;
        const header = <div>{t('83')}</div>;
        const body = <div><LoadingSpinner isOpen={state.isDownloadLoading} />{t('102')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    setState({ ...state, isDownloadLoading: true });
                    Api.downloadReport(state.report._id, common.getLang())
                        .then(([name, data]) => {
                            const url = window.URL.createObjectURL(data);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", name);
                            link.style.cssText = "display:none";
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        })
                        .catch((e) => dgLogger.error(e)())
                        .finally(() => setState({ ...state, isDownloadLoading: false, popup: POPUP.None, popupTarget: null }));
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    /**
     * popup을 현재 state에 따라 띄워주는 함수
     */
    const popups = () => {
        if (state.popup === POPUP.Request)
            return (
                <PopupRequestReportLicense
                    onSubmit={(payload) => {
                        payload.reportIds = [_id];
                        payload.lang = common.getLang();
                        Api.requestReportLicense(payload)
                            .then(() => {
                                setState({ ...state, popup: POPUP.Requested });
                            })
                            .catch((e) => {
                                dgLogger.error(e)();
                            });
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None });
                    }}
                />
            );
        if (state.popup === POPUP.Requested)
            return (
                <PopupRequestedReportLicense
                    onRequestClose={() => navigate("/Mypage/RequestDataUse") }
                    onConfirm={() => navigate("/Mypage/RequestDataUse") }
                    // onConfirm={() => {
                    //     Api.getFilteredReportLicense({ states: [utility.STATE.REVIEWING, utility.STATE.ACQUIRED], userId: state.user._id, reportId: _id, sort: { requested_date: -1 } })
                    //         .then((payload) => {
                    //             let acquireState = null;
                    //             if (payload.licenses.length > 0) acquireState = payload.licenses[0];
                    //             setState({ ...state, popup: POPUP.None, dataUse: acquireState });
                    //         })
                    //         .catch((e) => dgLogger.error(e)());
                    // }}
                />
            );
        if (state.popup === POPUP.Remove) {
            return (
                <PopupRemoveReport
                    onConfirm={() => {
                        Api.removeReport(state.popupTarget /* report._id */)
                            .then(() => {
                                // vendor-ui-2의 file 정보 탐색
                                const formData = state.formData.find(data => data.type === "vendor-ui-2");
                                const keys = formData?.form?.sub_items.map((subItemForm) => {
                                    const subItemResult = formData.value.find(subItemResult => subItemResult._id === subItemForm._id);
                                    const subItemFileResult = subItemResult.value.find(value => value.type === "file");
                                    const file = subItemFileResult.value[0];

                                    return file.key;
                                }) || [];

                                Api.deleteImages([...keys, ...state.files.map(file => file.key)]);
                                const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                                const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
                                sessionStorage.setItem("prevPathname", prevPathname);
                                navigate(navigatePathname || "/", { replace: true });
                            })
                            .catch((e) => dgLogger.error(e)());
                    }}
                    onCancel={() => {
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />
            );
        }
        if (state.popup === POPUP.ImagePreview) {
            const {index, files} = state.popupTarget;

            return (
                <ImagePreviewWindow
                    type={state.popupTarget.type}
                    questions={state.popupTarget.questions}
                    answers={state.popupTarget.answers}
                    images={files.map((file) => file && ({ watermark: file.watermark_url, thumbnail: file.thumbnail_url }))}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    bboxes={state.popupTarget.bboxes || undefined}
                    index={index}
                />
            );
        }
        if (state.popup === POPUP.DownloadRequest) return popupDownloadRequest();
        if (state.popup === POPUP.LinkCopy) return <LinkCopyWindow onConfirm={() => setState({ ...state, popup: POPUP.None })} />;
        if (state.popup === POPUP.CheckForTempReport) return <CheckForTempReportWindow onCancel={() => setState({ ...state, popup: POPUP.None })} onClose={() => setState({ ...state, popup: POPUP.None })} report={state.report} />;
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const getFileFormValue = (data) => {
        return (
            <Row className="form-files gx-0">
                {data.value.map((file) => (
                    <Col key={file.key} className="col-auto file-wrap">
                        <div className="form-file">
                            <div className="image-wrap">
                                <img src={file.thumbnail_url} alt="" />
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        );
    };

    const getVendorUI2FormValue = (data) => {
        let sum = 0;
        let unit = "";

        const files = data?.form?.sub_items.reduce((acc, subItemForm) => {
            const subItemResult = data.value.find(subItemResult => subItemResult._id === subItemForm._id);
            if (subItemResult) {
                const subItemFileResult = subItemResult.value.find(value => value.type === "file");
                acc.push(subItemFileResult.value[0]);
            } else {
                acc.push(null);
            }
            return acc;
        }, []);

        const cols = data.form.sub_items.reduce((acc, subItemForm, index) => {
            const subItemResult = data.value.find(subItemResult => subItemResult._id === subItemForm._id);
            if (subItemResult) {
                const subItemTextbox = subItemForm.items.find(item => item.sub_item_type === "textbox");

                const subItemTextboxResult = subItemResult.value.find(value => value.type === "textbox");
                const subItemFileResult = subItemResult.value.find(value => value.type === "file");
                const file = subItemFileResult.value[0];
                sum += parseFloat(subItemTextboxResult.value || 0);
                unit || (unit = subItemTextbox.unit);
                acc.push(
                    <Col key={index} className="file-wrap col-auto">
                        <Row className="gx-0">
                            <Col>
                                {subItemForm.name}
                                {subItemTextboxResult.value || 0}
                                {subItemTextbox.unit}
                            </Col>
                        </Row>
                        <div className="form-file">
                            <div className="image-wrap">
                                <img src={file.thumbnail_url} alt=""
                                    onClick={() => setState({ ...state,
                                        popup: POPUP.ImagePreview,
                                        popupTarget: {
                                            index: index,
                                            files: files
                                        }
                                    })}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </Col>
                );
            }
            return acc;
        }, []);

        return (
            <div className="notosanskr-400 font-size-16 vendor-ui-2">
                <Row className="gx-0">
                    <Col>
                        {t('130')} {sum} {unit}
                    </Col>
                </Row>
                <Row className="form-files gx-0">{cols}</Row>
            </div>
        );
    };

    const getVendorUI4FormValue = (data) => {
        // answer (count) 를 내림차순 정렬
        const answers = data.value.map((v) => ({ _id: v._id, answer: v.count })).sort((a, b) => b.answer - a.answer);
        // question 은 내림차순 정렬된 answer 같은 순서로 정렬 (_id 값으로 찾아서 정렬함)
        const questions = answers.map((v) => data.form.sub_items.find((x) => x._id == v._id)).map((v) => ({ sub_item_type: v.sub_item_type, question: v.name, _id: v._id }));
        sumAnswers.current[data._id] = {};
        sumAnswers.current[data._id].current = [questions.map((question, index) => ({
            _id: question._id,
            answer: answers.find((v) => v._id == question._id)?.answer
        }))];
        return (
            <Row className="gx-0 notosanskr-400 font-size-16 vendor-ui-3">
                <ImagePreviewTable
                    ref={sumAnswers.current[data._id]}
                    index={0}
                    type={"view"}
                    questions={questions}
                    rowColor={false}
                />
            </Row>
        );
    };

    const getVendorUI3FormValue = (data) => {
        const value = data.value.reduce((acc, cur, index) => {
            if (cur) {
                cur.index = index;
                acc.push(cur);
            }
            return acc;
        }, []);
        const files = value.map(data => data.file);
        const answers = value.map(data => data.answers);
        const bboxes = value.map(data => ({imageSize: data.image_size, boundingBox: data.bounding_box}));
        sumAnswers.current[data._id] = {};
        // 개별 question 에 대해 사진들의 answer 합을 구하고, 내림차순 정렬
        sumAnswers.current[data._id].current = [data.form.sub_items.map((question, index) => ({
            _id: question._id,
            answer: answers.reduce((acc, cur) => {
                if (cur[index]) acc += cur[index]?.answer;
                return acc;
            }, 0)
        })).sort((a, b) => b.answer - a.answer)];
        // question 은 내림차순 정렬된 answer 같은 순서로 정렬 (_id 값으로 찾아서 정렬함)
        const questions = sumAnswers.current[data._id].current[0].map(v => data.form.sub_items.find(x => x._id == v._id));

        return (
            <Row className="gx-0 notosanskr-400 font-size-16 vendor-ui-3">
                <ImagePreviewTable
                    ref={sumAnswers.current[data._id]}
                    index={0}
                    type={"view"}
                    questions={questions}
                    rowColor={false}
                />
                <div className="gx-0 form-files"> {value.map((data, index) => (
                    <React.Fragment key={index}>
                        <Col
                            className="file-wrap col-auto"
                            onClick={() => {
                                setState({ ...state,
                                    popup: POPUP.ImagePreview,
                                    popupTarget: {
                                        index: index,
                                        files: files,
                                        questions: questions,
                                        answers: answers,
                                        bboxes: bboxes,
                                        type: "view",
                                    }
                                });
                            }}
                        >
                            <div className="form-file">
                                <div className="image-wrap">
                                    <img src={data.file?.thumbnail_url || `${process.env.PUBLIC_URL}/noimg@2x.png`} alt="" style={{ cursor: "pointer" }} />
                                </div>
                            </div>
                            <div className="w-80px" style={{wordBreak: "keep-all"}}>
                                {t('511', {'%1$d': data.index + 1, '%2$d': data.sum.toLocaleString()})}
                            </div>
                        </Col>
                    </React.Fragment>
                ))} </div>
            </Row>
        );
    };

    const getFormValue = (formData) => {
        switch (formData.type) {
            case "location": {
                const spinnerboxForm = formData.form.fields.has_extra_items && formData.form.extra_items.find(item => item.sub_item_type === "spinnerbox");
                const spinnerboxValue = formData.result.extra?.find(item => item.type === "spinnerbox");
                // const checkboxForm = formData.form.fields.has_extra_items && formData.form.extra_items.find(item => item.sub_item_type === "checkbox");
                // const checkboxValue = formData.result.extra?.find(item => item.type === "checkbox");
                // const checks = checkboxValue?.value?.map(value => checkboxForm.items.find(item => item._id === value._id)?.name);
                return <>
                    <div>{t('109')} {`${formData.value.latitude}, ${formData.value.longitude}`}</div>
                    {spinnerboxForm && spinnerboxValue && <div>{`${spinnerboxForm.name} ${spinnerboxValue.value} ${spinnerboxForm.unit}`}</div>}
                    {/* {checkboxForm && checks?.length > 0 && <div>{`${checkboxForm.name} ${checks.join(',')}`}</div>} */}
                </>;
            }
            case "checkbox": {
                const ids = formData.value.map((value) => value._id); // get list of _id
                const values = formData.form.sub_items.reduce((acc, cur) => {
                    if (ids.includes(cur._id)) {
                        if (cur.sub_item_type === "string") acc.push(cur.name);
                        else if (cur.sub_item_type === "custom_string") {
                            const text = formData.value.find((item) => item._id === cur._id);
                            acc.push(`${t('163')} ${text && text.custom_string ? text.custom_string : ""}`);
                        }
                    }
                    return acc;
                }, []);
                return values.map((value, i) => (
                    <React.Fragment key={i}>
                        {value}
                        <br />
                    </React.Fragment>
                ));
            }
            case "time_range": {
                return <>
                    <span>{formData.value.start} ~ {formData.value.end}</span>
                </>;
            }
            case "radiobox": {
                const ids = formData.value.map((value) => value._id); // get list of _id
                const values = formData.form.sub_items.reduce((acc, cur) => {
                    if (ids.includes(cur._id)) {
                        if (cur.sub_item_type === "custom_string") {
                            const text = formData.value.find((item) => item._id === cur._id);
                            acc.push(`${t('163')} ${text && text.custom_string ? text.custom_string : ""}`);
                        } else {
                            acc.push(cur.name);
                        }
                    }
                    return acc;
                }, []);
                return values.map((value, i) => (
                    <React.Fragment key={i}>{value}</React.Fragment>
                ));
            }
            case "spinnerbox": {
                return <>{formData.value}{formData.form.fields.unit}</>;
            }
            case "multi-spinnerbox": {
                const values = formData.value.map((value) => {
                    const sub_item = formData.form.sub_items.find(sub_item => sub_item._id === value._id);
                    return `${sub_item.name} ${value.value} ${sub_item.unit}`;
                });
                return values.map((value, i) => (
                    <div key={i}>{value}{" "}</div>
                ));
            }
            case "vendor-ui-2": {
                return isTabletOrPC ? getVendorUI2FormValue(formData) : <></>;
            }
            case "multi-textbox": {
                const values = formData.value.map((value) => {
                    const sub_item = formData.form.sub_items.find(sub_item => sub_item._id === value._id);
                    return `${sub_item.name} ${value.value} ${sub_item.unit}`;
                });
                return values.map((value, i) => (
                    <div key={i}>{value}{" "}</div>
                ));
            }
            case "file": {
                return isTabletOrPC ? getFileFormValue(formData) : <></>;
            }
            case "tag": {
                return formData.value.map((data, index) => {
                    return (
                        <div key={index} className="pr-13px d-inline-block">#{data}</div>
                    );
                });
            }

            case "ml_textbox" : {
                return (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                        {formData.value}
                    </div>
                );
            }

            case "vendor-ui-3" : {
                return isTabletOrPC ? getVendorUI3FormValue(formData) : <></>;
            }

            case "vendor-ui-4" : {
                return isTabletOrPC ? getVendorUI4FormValue(formData) : <></>;
            }

            case "typeahead" : {
                return <>{formData.value.map(data => data.name).join(", ")}</>;
            }

            default:
                // 'date', 'time', 'textarea' ...
                return <>{formData.value}</>;
        }
    };

    const getReportInfo = () => {
        return (
            <Col className="report-info">
                <Row className="gx-0">
                    <Col className="info">
                        {state.report && (
                            <React.Fragment key={state.report._id + isTabletOrPC}>
                                <Row className="gx-0 info-row-head align-items-center">
                                    <Col className="profile col-auto">
                                        <Emblem
                                            width={isTabletOrPC ? "93px" : "50px"}
                                            height={isTabletOrPC ? "93px" : "50px"}
                                            url={state.report.profile_picture && state.report.profile_picture.url}
                                            urlClass="profile-image"
                                            char={state.report.name}
                                            charClass={`avatar ${isTabletOrPC ? "avatar-93 pb-2" : "avatar-50 pb-1"} bg-e8ebf00`}
                                        />
                                    </Col>
                                    <Col>
                                        <Row className="gx-0">
                                            <Col className="text-start">
                                                <span className="project-owner notosanskr-600 font-size-16 lh-1.5 ls--0.8px c-white">{state.report.name}</span>
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 align-items-baseline">
                                            <Col className="project-desc text-start notosanskr-600 font-size-18 lh-1.89 ls-0.9px c-666 col-auto">{common.i18nGroupName(state.report.group)}</Col>
                                            <Col className="project-date text-start notosanskr-500 font-size-13 lh-2.62 ls--0.65px c-666 col-auto">
                                                {new Date(state.report.register_date).toLocaleString()}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="text-end share col-auto">
                                        <button
                                            onClick={() => {
                                                common.copyUrlToClipboard(window.location.href);
                                                setState({ ...state, popup: POPUP.LinkCopy });
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row className="gx-0">
                                    <Col className="mt-43px mt-11px:sm mr-35px mr-11px:sm mb-35px mb-11px:sm ml-35px ml-11px:sm text-start">
                                        <Row className="gx-0 notosanskr-500 font-size-24 lh-1 ls--1.2px c-333">
                                            <Col className="mb-24px mb-13px:sm">{state.reportTitle}</Col>
                                        </Row>

                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-00`}>{t('26')}</Col>
                                            <Col className="notosanskr-400 font-size-16">
                                                {[state.report.name, ...(state.report.coworker ? state.report.coworker.map((data) => data.name) : [])].join(", ")}
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-00`}>{t('38')}</Col>
                                            <Col className="notosanskr-400 font-size-16">{common.i18nGroupName(state.report.group)}</Col>
                                        </Row>
                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-00`}>{t('3')}</Col>
                                            <Col className="notosanskr-400 font-size-16">{state.report.project == strings.ko["188"] ? t("188") : state.report.project || t('512')}</Col>
                                        </Row>
                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000`}>{t('105')}</Col>
                                            <Col className="notosanskr-400 font-size-16">{new Date(state.report.register_date).toLocaleDateString()}</Col>
                                        </Row>
                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000`}>{t('106')}</Col>
                                            <Col className="notosanskr-400 font-size-16">{new Date(state.report.register_date).toLocaleTimeString()}</Col>
                                        </Row>
                                        {state.formData
                                            .filter((data) => !(data.form.essential && ["file", "typeahead"].includes(data.form.type))) // essential file(조사 사진) 및 essential typeahead(공동 조사자)는 제외함.
                                            .map((data) => (
                                                <React.Fragment key={data._id}>
                                                    <Row className={`gx-0 info-row ${data.type === "file" ? "pb-0 pe-0" : ""}`}>
                                                        <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                                        <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000`}>
                                                            {data.title}
                                                        </Col>
                                                        <Col className="notosanskr-400 font-size-16">{getFormValue(data)}</Col>
                                                    </Row>
                                                    {data.type === "vendor-ui-2" && isMobile && getVendorUI2FormValue(data)}
                                                    {data.type === "vendor-ui-3" && isMobile && getVendorUI3FormValue(data)}
                                                    {data.type === "vendor-ui-4" && isMobile && getVendorUI4FormValue(data)}
                                                    {data.type === "file" && isMobile && getFileFormValue(data)}
                                                    {data.type === "location" && (
                                                        <Map
                                                            style={{
                                                                height: "340px",
                                                                width: "100%",
                                                                marginLeft: "0%",
                                                                borderRadius: "6px",
                                                                border: "solid 1px #707070",
                                                                overflow: "hidden",
                                                                marginBottom: "11px",
                                                            }}
                                                            data={[{ lat: data.value.latitude, lng: data.value.longitude }]}
                                                            center={{
                                                                lat: basedOption.current.lat ? parseFloat(basedOption.current.lat) : Config.lat,
                                                                lng: basedOption.current.lng ? parseFloat(basedOption.current.lng) : Config.lng,
                                                            }}
                                                            zoom={basedOption.current.zoom ? parseInt(basedOption.current.zoom) : Config.zoom}
                                                            preventMarkerClick={true}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        <Row className="gx-0 info-row">
                                            <Col className="col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-000 mr-8px">•</Col>
                                            <Col className={`${isTabletOrPC ? "maxw-140px" : "maxw-100px"} w-100 mr-10px col-auto notosanskr-500 font-size-18 lh-1.33 ls--0.9px c-00`}>
                                                {t('112')}
                                            </Col>
                                            <Col className="notosanskr-400 font-size-16">{new Date(state.report_form.revision).toISOString()}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </Col>
        );
    };

    const getButtons = () => {
        return (
                <Col className="buttons text-end">
                    {context.loggedIn && isPossibleRequestDataUse() && (
                        <button
                            className="request-data-use-btn notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-white"
                            disabled={state.dataUse && state.dataUse.state === utility.STATE.REVIEWING}
                            onClick={() => setState({ ...state, popup: POPUP.Request })}
                        >
                            {t('72')}
                        </button>
                    )}

                    {context.loggedIn && !isWebview && isPossibleDownloadRaw() && (
                        <button
                            className="download-raw-btn notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-white"
                            onClick={() => {
                                setState({ ...state, popup: POPUP.DownloadRequest });
                            }}
                        >
                            {t('513')}
                        </button>
                    )}

                    {context.loggedIn && isModifiable() && (
                        <CustomButtonLink
                            className="modify-report-btn notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-white"
                            to="/CreateReport"
                            state={state}
                            onClick={(e) => {
                                if (localStorage.getItem(state.user._id)) {
                                    e.preventDefault();
                                    setState({ ...state, popup: POPUP.CheckForTempReport });
                                }
                            }}
                        >
                            {t('84')}
                        </CustomButtonLink>
                    )}

                    {context.loggedIn && isRemovable() && (
                        <button
                            className="remove-report-btn notosanskr-400 font-size-16 lh-1.5 ls--0.8px c-white"
                            onClick={() => {
                                setState({ ...state, popup: POPUP.Remove, popupTarget: _id });
                            }}
                        >
                            {t('85')}
                        </button>
                    )}
                </Col>
        );
    };

    if (state.popup === POPUP.GeneralError)
        return (
            <GeneralErrorWindow
                message={state.popupTarget}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    if (!state.report) return <LoadingSpinner isOpen={true} />;

    return (
        <div id="detailed-report">
            <Container>
                <span className="report-detail-sub-title">
                    <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff data">Data</span>
                    <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('2')}</span>
                </span>
            </Container>

            <PC>
                <Container style={{padding: "48px 0 15px 0"}}>
                    {state.user &&
                        <Row className="gx-0">
                            {getButtons()}
                        </Row>
                    }
                </Container>
            </PC>
            <MobileOrTablet>
                <div style={{ paddingBottom: "27px" }}>
                    {state.user &&
                        <div className="d-flex align-items-center" style={{ backgroundColor: "#f2f4f7", minHeight: "130px" }}>
                            <Container>
                                {getButtons()}
                            </Container>
                        </div>
                    }
                </div>
            </MobileOrTablet>

            <Container>
                {popups()}
                <Row className="gx-0">
                    <Col className="image-n-talk">
                        <Row className="gx-0 images">
                            <Col className="maxw-660px">
                                <ImageGallery
                                    ref={imageGalleryRef}
                                    showNav={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    thumbnailPosition={"bottom"}
                                    originalClass={".original"}
                                    onClick={() => setState({ ...state,
                                        popup: POPUP.ImagePreview,
                                        popupTarget: {
                                            index: imageGalleryRef.current.getCurrentIndex(),
                                            files: state.files
                                        }
                                    })}
                                    items={state.files.map((file) => ({
                                        original: file.watermark_url,
                                        thumbnail: file.thumbnail_url ? file.thumbnail_url : `${process.env.PUBLIC_URL}/noimg@2x.png`,
                                        originalWidth: "660px",
                                        originalHeight: isTabletOrPC ? "440px" : "224px",
                                        originalClass: "report-info-images-original",
                                        thumbnailWidth: "101px",
                                        thumbnailHeight: isTabletOrPC ? "87px" : "78px",
                                        thumbnailClass: "report-info-images-thumbnail",
                                    }))}
                                />
                            </Col>
                        </Row>
                        <MobileOrTablet>
                            <Row className="gx-0" style={{marginBottom: "27px"}}>
                                {getReportInfo()}
                            </Row>
                        </MobileOrTablet>
                        <Row className="gx-0 report-talk">
                            <Col>
                                <Comment
                                    _id={_id}
                                    getComment={Api.getReportComment}
                                    addComment={Api.addReportComment}
                                    removeComment={Api.removeReportComment}
                                    itemsCountPerPage={10}
                                    showTotalCount={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <PC>
                        {getReportInfo()}
                    </PC>
                </Row>
            </Container>
        </div>
    );
}
