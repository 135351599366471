import Modal from "./Modal";
import ReactModal from "react-modal";
import "./MailAuthenticationWindow.css";
import { InputText } from "../Input";
import { useTranslation } from "react-i18next";

const RequestMailCodeWindow = (props) => {
    const { t } = useTranslation();
    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={props.onRequestClose}
            className={props.className ? props.className : "default"}
            style={{ overlay: {}, content: {} }}
            portalClassName={"verify-mail-transfer"}
        >
            <div className="title nanumsquareeb-18 nanumsquareeb-16:sm">
                {t('294')}
                <div>
                    <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} onClick={props.onCancel} style={{ cursor: "pointer" }} alt=""/>
                </div>
            </div>

            <div className="body">
                <div className="mail notosanskr-24b notosanskr-20b:sm c-4270e0">{props.mail}</div>
                <div className="text-wrap notosanskr-15 notosanskr-12:sm">
                    {t('296').split('\n').map((v, i) => <div key={i}>{v}</div>)}
                    <div className="verify-mail-code-wrap">
                        <InputText
                            id="verify-mail-code"
                            name="verify-mail-code"
                        />
                    </div>
                </div>
                <div className="button-group">
                    <button className="send nanumsquareb-14 nanumsquareb-12:sm" onClick={props.onConfirm}>
                        {t('79')}
                    </button>
                    <button className="cancel nanumsquareb-14 nanumsquareb-12:sm" onClick={props.onCancel}>
                    {t('80')}
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};

const NotFoundMailWindow = (props) => {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>{t('297')}</div>;
    const body = <div>{t('298')}</div>;

    return (
        <Modal
            onRequestClose={props.onRequestClose}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const WrongMailCodeWindow = (props) => {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>{t('297')}</div>;
    const body = <>{t('299').split('\n').map((v, i) => <div key={i}>{v}</div>)}</>;

    return (
        <Modal
            onRequestClose={props.onRequestClose}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const TimeOutMailCodeWindow = (props) => {
    const { t } = useTranslation();

    const icon = <img src={process.env.PUBLIC_URL + `/pop_password_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_password_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_password_error@3x.png 3x`} alt="" />;
    const header = <div>{t('297')}</div>;
    const body = <div>{t('655')}</div>;

    return (
        <Modal
            onRequestClose={props.onRequestClose}
            onConfirm={props.onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { RequestMailCodeWindow, NotFoundMailWindow, WrongMailCodeWindow, TimeOutMailCodeWindow };
