import React, { useImperativeHandle, useCallback } from 'react';
import WithValueHandle from "./WithValueHandle";
import i18n from 'i18next';

const WithTimeRangeHandle = (WrapperComponent) => {
    const WithTimeRange = ({ ...otherProps }, ref) => {
        const timeRangeValidation = useCallback(({ start, end, required }) => {
            let valid = true;
            let errorCollection = { start: { state: valid, message: "" }, end: { state: valid, message: "" } };

            if (required) {
                if (!start) {
                    valid = false;
                    errorCollection['start'] = { state: valid, message: i18n.t('15') };
                }

                if (!end) {
                    valid = false;
                    errorCollection["end"] = { state: valid, message: i18n.t('15') };
                }
            }

            // flag가 true이고, 하위 컴포넌트의 validation function 이 있는 경우
            if (valid && ref.current.childValidation) {
                ({ valid, errorCollection } = ref.current.childValidation({ start, end }));
            }

            return { valid, errorCollection };
        }, [ref]);

        useImperativeHandle(ref, () => (
            { ...ref.current, timeRangeValidation }
        ), [ref, timeRangeValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithTimeRange));
};

export default WithTimeRangeHandle;
