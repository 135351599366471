import React, { useState, useEffect, useRef } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useNavigate, Link, useLocation } from "react-router-dom";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import Modal from "../common/Modal";
import Pagination from '../common/Pagination';
import OrganizationInfoWindow from './../common/OrganizationInfoWindow';
import { Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import "./RequestOrganization.css";
import { dummy } from "@ocean-knight/shared";
import { useIsMount } from "../../common/customHook";
import RequestOrganizationContent from "./RequestOrganizationContent";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import QueryString from 'qs';
import { REQUEST_ORGANIZATION_TAB_ID } from "@ocean-knight/shared/src/utility";

const ID = {
    organizationName: "request-organization-name",
    organizationFiles: "create-organization-files",
    organizationFilesInput: "create-organization-files-input",
    organizationGroupName: "create-organization-group",
    organizationUserName: "create-organization-username",
    organizationUserContact: "create-organization-user-contact",
    organizationAffiliation: "create-organization-affiliation",
    organizationUserPosition: "create-organization-user-position",
    organizationRequestCause: "create-organization-cause",
};

const POPUP = {
    ...GeneralPopup,
    Notification: 1010,
};

export default function RequestOrganization() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [REQUEST_ORGANIZATION_TAB_ID.request, REQUEST_ORGANIZATION_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : REQUEST_ORGANIZATION_TAB_ID.request,
        popup: POPUP.None,
        popupTarget: null,
        histories: [],
        groupList: [],  // 아직 단체 요청을 받지 않은 그룹 목록
        certificateFiles: [],

        reRenderKey: false // RequestOrganizationContent 를 re-render 할 toggle 변수 (toggle 시 re-render 수행함)
    });
    const isMount = useIsMount();
    const paginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });
    const [, setOrganization] = useState(false);

    useEffect(() => {
        const hasPermission = common.hasSiteMemberPermission(JSON.parse(sessionStorage.getItem("permissions")));
        if (!hasPermission) {
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t('539'),
            }));
            return;
        }

        const getContents = async () => {
            try {
                const organizationPermission = await Api.getOrganizationPermission();
                const groupList = await Api.getNotOrganizationGroupList(); // 아직 단체 요청을 받지 않은 그룹 목록만 획득함.

                const payload = await Api.getOrganizationHistoryMine({
                    currentPage: paginationOption.current.currentPage,
                    itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                });

                paginationOption.current.totalItemsCount = payload.totalItemsCount;

                if (!isMount.current) return;
                setOrganization(organizationPermission);
                setState((prev) => ({
                    ...prev,
                    groupList: groupList,
                    histories: payload.histories,
                    activeTab: REQUEST_ORGANIZATION_TAB_ID.request
                }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            };
        };
        getContents();
    }, [isMount, t]);

    const onSubmit = async () => {
        setState({ ...state, isLoading: true });
        const groupName = document.getElementById(ID.organizationGroupName);
        const group = groupName.options[groupName.selectedIndex];
        const name = document.getElementById(ID.organizationUserName);
        const contact = document.getElementById(ID.organizationUserContact);
        const affiliation = document.getElementById(ID.organizationAffiliation);
        const position = document.getElementById(ID.organizationUserPosition);
        const cause = document.getElementById(ID.organizationRequestCause);

        const histories = [];
        const groupList = [];

        try {
            const data = {
                groupId: group.value,
                name: name.value, // 실명
                contact: contact.value, // 신청자 연락처
                affiliation: affiliation.value, // 소속
                position: position.value, // 직급
                cause: cause.value
            };

            if (state.certificateFiles) data.certificate_files = await Api.uploadFiles(state.certificateFiles, true);

            await Api.requestOrgCreation(data);

            const groups = await Api.getNotOrganizationGroupList(); // 아직 단체 요청을 받지 않은 그룹 목록만 획득함.
            const payload = await Api.getOrganizationHistoryMine({
                currentPage: paginationOption.current.currentPage,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            });

            paginationOption.current.totalItemsCount = payload.totalItemsCount;
            histories.push(...payload.histories);
            groupList.push(...groups);
        } catch (e) {
            dgLogger.error(e)();
        } finally {
            setState({ ...state, isLoading: false });
            return [histories, groupList];
        };
    };

    const popupOrganizationRequest = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_data_apply.png`} srcSet={`${process.env.PUBLIC_URL}/pop_data_apply@2x.png 2x, ${process.env.PUBLIC_URL}/pop_data_apply@3x.png 3x`} alt=""/>;
        const header = <div>{t('676')}</div>;
        const body = <div><LoadingSpinner isOpen={state.isLoading} />{t('677')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={async () => {
                    const [histories, groupList] = await onSubmit();
                    setState({ ...state, histories: histories, groupList: groupList, popup: POPUP.Requested, popupTarget: null, reRenderKey: !state.reRenderKey });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupOrganizationRequested = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt="" />;
        const header = <div>{t('678')}</div>;
        const body = <div>{t('679').split('\n').map((v, i) => <div key={i}>{t}</div>)}</div>;

        const getOrganizationHistoryMine = () => {
            Api.getOrganizationHistoryMine({
                currentPage: paginationOption.current.currentPage,
                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            })
                .then((payload) => {
                    paginationOption.current.totalItemsCount = payload.totalItemsCount;

                    // open `history` tab
                    setState({ ...state, histories: payload.histories, certificateFiles: [], popup: POPUP.None, popupTarget: null, activeTab: REQUEST_ORGANIZATION_TAB_ID.histories });

                })
                .catch((e) => {
                    dgLogger.error(e)();
                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                });
        };

        return (
            <Modal
                onRequestClose={getOrganizationHistoryMine}
                onConfirm={getOrganizationHistoryMine}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupOrganizationRejectReason = (onClicked = undefined) => {
        const header = <div>{t('680')}</div>;
        const body = <div>{t('681')}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    if (onClicked) onClicked();
                    else setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                header={header}
                body={body}
                reason={state.popupTarget.state_reason}
            />
        );
    };

    const popupOrganizationRequestContent = (onClicked = undefined) => {
        return (
            <OrganizationInfoWindow
                organization={state.popupTarget}
                onClick={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.REVIEWING)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#87ac2f", cursor: "default" }}>
                    {t("378")}
                </button>
            );
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                    {t("377")}
                </button>
            );
        if (item.state === utility.STATE.REJECTED)
            return (
                <button
                    className="nanumsquare-15b lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t('379')}
                </button>
            );
    };

    const popups = () => {
        if (state.popup === POPUP.Request) return popupOrganizationRequest();
        if (state.popup === POPUP.Requested) return popupOrganizationRequested();
        if (state.popup === POPUP.RejectReason) return popupOrganizationRejectReason();
        if (state.popup === POPUP.RequestContent) return popupOrganizationRequestContent();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };


    return (
        <div id="request-organization">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-lg-auto notosanskr-40b first">{t('358')}</Col>
                <Col className="notosanskr-26 c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t('671')}</div>
                </Col>
            </Row>
            <Tabs activeKey={state.activeTab} onSelect={(k) => setState({ ...state, activeTab: k })} id="uncontrolled-tab" className="uncontrolled-tab">
                <Tab className={"request"} eventKey={REQUEST_ORGANIZATION_TAB_ID.request} title={t('358')}>
                    {state.groupList.length > 0 ? (
                        <React.Fragment>
                            <Row className="gx-0 notice">
                                <Col>{t('682')}</Col>
                            </Row>
                            <Row className="gx-0 request-form-title notosanskr-26b c-333">
                                <Col className="col-auto">{t('384')}</Col>
                                <Col>
                                    &nbsp;
                                    {process.env.NODE_ENV !== "production" && (
                                        <Button
                                            onClick={() => {
                                                let r = dummy.randomOrgInfo();
                                                document.getElementById(ID.organizationUserName).value = r.name;
                                                document.getElementById(ID.organizationAffiliation).value = r.affiliation;
                                                document.getElementById(ID.organizationUserPosition).value = r.position;
                                                document.getElementById(ID.organizationRequestCause).value = r.cause;
                                                document.getElementById(ID.organizationUserContact).value = r.contact;
                                            }}
                                        >
                                            Auto Fill
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <RequestOrganizationContent
                                key={state.reRenderKey}
                                ID={ID}
                                onSubmit={(certificateFiles) => {
                                    setState({ ...state, popup: POPUP.Request, popupTarget: null, certificateFiles: certificateFiles });
                                }}
                            />
                        </React.Fragment>
                    ) : (
                        <Row className="gx-0 notice">
                            <Col>{t('683')}</Col>
                        </Row>
                    )}
                </Tab>
                <Tab className={"histories"} eventKey={REQUEST_ORGANIZATION_TAB_ID.histories} title={t('373')}>
                    <Row className="item-row gx-0 align-items-center notosanskr-18-500">
                        <Col className="item first">{t('374')}</Col>
                        <Col className="item second">{t('375')}</Col>
                        <Col className="item fourth">{t('400')}</Col>
                        <Col className="item third">{t('376')}</Col>
                    </Row>
                    {state.histories.map((item) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center nanumsquareb-16">
                            <Col className="item first c-333">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item second c-333">
                                {
                                    item.group_state === "active" ?
                                        <Link to={`/Group/${item.group_id}`}>
                                            {common.i18nGroupName(item.group_name)}
                                        </Link>
                                        : <div>{common.i18nGroupName(item.group_name)}</div>
                                }
                            </Col>
                            <Col className="item third col-lg-auto button">
                                <button
                                    className="c-white nanumsquareb-15"
                                    onClick={() => {
                                        Api.getOrganizationHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {t('400')}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`} 
                                    alt="" />
                                </button>
                            </Col>
                            <Col className="item fourth">{processedState(item)}</Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getOrganizationHistoryMine({
                                currentPage: newPage,
                                itemsCountPerPage: paginationOption.current.itemsCountPerPage,
                            })
                                .then((payload) => {
                                    paginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    paginationOption.current.currentPage = newPage;
                                    setState((prev) => ({ ...prev, histories: payload.histories }));
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                                });
                        }}
                        page={paginationOption.current.currentPage}
                        itemsCountPerPage={paginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={paginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={paginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
