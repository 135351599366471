import React, { useCallback, useState } from "react";
import Api from "../../Api";
import TestComponent from "../TestComponent";
import { Container } from "react-bootstrap";
import common from "../../common/common";
import LoadingSpinner from "./LoadingSpinner";
import GeneralErrorWindow from "./GeneralErrorWindow";
import { useNavigate } from "react-router-dom";

export default function Dev() {
    const isSiteAdmin = common.hasSiteAdminPermission();
    const [state, setState] = useState({ isLoading: false });
    const navigate = useNavigate();
    const dropDatabase = useCallback (() => {
        setState({ ...state, isLoading: true });
        Api.devResetDatabase()
        .then(() => {
            alert("완료");
            setState({ ...state, isLoading: false });
        })
        .catch((e) => {
            alert("비정상");
            setState({ ...state, isLoading: false });
        });
    }, [state]);

    if (!isSiteAdmin && process.env.NODE_ENV === "production")
        return (
            <GeneralErrorWindow
                onClick={() => {
                    navigate("/");
                }}
            />
        );

    return (
        <Container>
            <LoadingSpinner isOpen={state.isLoading} />
            {process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET === "oceancloud.private" && (<button onClick={dropDatabase}>DB 초기화</button>)}
            <TestComponent></TestComponent>
            <a href="https://oceancloud.co.kr/">move to https://oceancloud.co.kr/</a>
            <br/>
            <input type="text" id="url_webview_test" className="font-size-20" />
            <input type="button"
                onClick={(e) => {
                    const url_value = document.getElementById("url_webview_test").value;
                    window.location = url_value;
                }}
                value="Navigate URL"
                className="font-size-20" />
        </Container>
    );
}
