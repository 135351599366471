import React from "react";
import ReactModal from "react-modal";
import { ListGroup } from "react-bootstrap";
import CustomCheckbox from "../../common/CustomCheckbox";
import "./WithdrawMemberWindow.css";
import { useTranslation } from "react-i18next";

const ID = {
    withdrawTarget: "member-manage-withdraw-targets",
    withdrawReason: "member-manage-withdraw-reason",
    withdrawWithBan: "member-manage-withdraw-with-ban",
};

const WithdrawMember = ({ targets, onConfirmed, onCancel = undefined, className = undefined, rejoin = false, title = undefined }) => {
    const { t } = useTranslation();
    if (!title) title = t('564');

    return (
        <ReactModal
            isOpen={true}
            // onRequestClose={onCancel}
            className={className ? className : "default"}
            style={{
                overlay: {},
                content: {
                    // top: "50%",
                    // left: "50%",
                    // right: "auto",
                    // bottom: "auto",
                    // marginRight: "-50%",
                    // transform: "translate(-50%, -50%)",
                },
            }}
            portalClassName={"member-manage-withdraw"}
        >
            <div className="title nanumsquareeb-18">
                {title}
                <div style={{ position: "absolute", right: "32px" }}>
                    <img
                        src={process.env.PUBLIC_URL + `/icon_close.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`}
                        onClick={onCancel}
                        alt=""
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
            <table className={"member-manage-withdraw-table"}>
                <thead className="nanumsquare-16">
                    <tr>
                        <td>
                            {t('438')}
                        </td>
                        {title === t('495') ? <td>{t('496')}</td> : <td>{t('441')}</td>}
                    </tr>
                </thead>
                <tbody className="notosanskr-14">
                    <tr>
                        <td className="align-top">
                            <ListGroup
                                id={ID.withdrawTarget}
                                style={{
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                }}
                                disabled
                            >
                                {targets.map((item, i) => (
                                    <ListGroup.Item key={item._id} className={i % 2 !== 0 ? "bg-white" : "bg-f2f4f7"}>
                                        {item.name}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </td>
                        <td className="align-top">
                            <div id={ID.withdrawReason} className="text-start">
                                <div>
                                    <CustomCheckbox type="radio" name={ID.withdrawReason} value="advertising-spam" className="d-flex align-items-center mb-25px custom-radio-wrap">
                                        {t('442')}
                                    </CustomCheckbox>
                                </div>
                                <div>
                                    <CustomCheckbox type="radio" name={ID.withdrawReason} value="undesirable-activity" className="d-flex align-items-center mb-25px custom-radio-wrap">
                                        {t('443')}
                                    </CustomCheckbox>
                                </div>
                                <div>
                                    <CustomCheckbox type="radio" name={ID.withdrawReason} value="violating-rule" className="d-flex align-items-center mb-25px custom-radio-wrap" defaultChecked={true}>
                                        {t('444')}
                                    </CustomCheckbox>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {rejoin ? (
                <div className="text-start rejoin notosanskr-16 lh-1.31 ls--0.8px">
                    <CustomCheckbox id={ID.withdrawWithBan}>
                        {t('319')}
                    </CustomCheckbox>
                </div>
            ) : (
                <div className="h-30px" />
            )}
            <div className="text-end button-group">
                <button
                    className="confirm nanumsquareb-14"
                    onClick={() => {
                        if (!onConfirmed) return;
                        const selectedRadio = document.querySelector(`input[name="${ID.withdrawReason}"]:checked`);
                        const withdrawReason = selectedRadio.closest(".custom-radio-wrap").textContent;

                        if (rejoin) {
                            const banned = document.getElementById(ID.withdrawWithBan).checked;
                            onConfirmed(withdrawReason, banned);
                        } else {
                            onConfirmed(withdrawReason);
                        }
                    }}
                >
                    {t('79')}
                </button>
                <button className="cancel nanumsquareb-14" onClick={onCancel}>
                    {t('80')}
                </button>
            </div>
        </ReactModal>
    );
};

export { WithdrawMember  };
