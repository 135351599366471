import React, { useState, useEffect } from "react";
import Api from "../../Api";
import { POPUP as GeneralPopup } from "../../common/defines";
import common from "../../common/common";
import { Row, Col } from "react-bootstrap";
import "./RequestDataUseContent.css";
import ImageDropZone2 from '../common/ImageDropZone2';
import { useIsMount } from "../../common/customHook";
import { InputText } from "../Input";
import { PopupValidateFile } from "../common/ValidateModal";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { useNavigate } from "react-router-dom";
import Timer from "../common/Timer";
import PhoneForm from "../common/PhoneForm";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 200; // 200MB

export default function RequestDataUseContent({
    ID, // html element's ID
    onSubmit, // 신청하기 버튼을 눌렀을 때의 callback
    onCancel, // 취소 버튼을 눌렀을 때의 callback
    onConfirm, // 확인 버튼을 눌렀을 때의 callback
    applicationInfo, // 정보를 보여줄 DataUse request 신청서 내용
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        files: [],
        userInfo: {},

        popup: POPUP.None,
        popupTarget: null,
    });
    const isMount = useIsMount();
    const [errors, setErrors] = useState({ nickname: '', name: '', email: '', cause: '' });

    useEffect(() => {
        const getContents = async () => {
            try {
                const userInfo = await Api.getCurrentUserInfo();

                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    userInfo: userInfo,
                }));
            } catch (e) {
                if (!isMount.current) return;
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            };
        };
        getContents();
    }, [isMount]);

    const onDropfiles = (acceptedFiles, fileRejections) => {
        // 모든 파일 업로드 가능
        const [, allFiles, valid] = common.validateOnDrop(acceptedFiles, fileRejections, state.files, MAX_FILES, MAX_SIZE);
        if (!valid.valid) {
            return setState(prev => ({...prev, popup: POPUP.ValidateFileField, }));
        }

        setState({...state, files:allFiles});
    };

    const validateFields = async () => {
        let flag = true;
        const errorCollection = {};

        const email = document.getElementById(ID.dataUseUserEmail);
        const name = document.getElementById(ID.dataUseUserName);
        const cause = document.getElementById(ID.dataUseRequestCause);

        common.validateEmailField(errorCollection, 'email', email, false) || (flag = false);
        common.validateInputField(errorCollection, 'name', name) || (flag = false);
        common.validateInputField(errorCollection, 'cause', cause) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const removeFileFromFileList = (elementId, targetFile) => {
        const dt = new DataTransfer();
        const input = document.getElementById(elementId);
        const { files } = input;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file !== targetFile)
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        input.files = dt.files; // Assign the updates list
    };

    const isValid = (elements, required) => {
        const [flag, errorCollection] = common.isValid(elements, required);
        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    /**
     *
     * @returns popup 상태에 따라 popup 호출
     */
     const popups = () => {
        if (state.popup === POPUP.ValidateFileField)
            return (
                <PopupValidateFile
                    maxFiles={MAX_FILES}
                    maxSizeInMB={MAX_SIZE / (1024 * 1024)}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                />
            );
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <div className="nanumsquareb-17 nanumsquareb-13:sm">
            {popups()}
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t('90')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText key={state.userInfo?.name} id={ID.dataUseName} disabled={true} defaultValue={applicationInfo?.nickname ||state.userInfo.name} placeholder={t('90')} className="w-75"/>
                    <div className="c-f00 mt-6px"></div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            <span className="check-icon"></span>
                            {t('91')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.dataUseUserName} name="name" disabled={applicationInfo} defaultValue={applicationInfo?.name || ""} onBlur={(e) => isValid([e.target])} maxLength={20} className="w-75"/>
                    <div className="c-f00 mt-6px">{errors?.name?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            <span className="check-icon"></span>
                            {t('92')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    <textarea id={ID.dataUseRequestCause} name="cause" disabled={applicationInfo} defaultValue={applicationInfo?.cause || ""} onBlur={(e) => isValid([e.target])} maxLength={500} />
                    <div className="c-f00 mt-6px">{errors?.cause?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t('93')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    <InputText id={ID.dataUseUserEmail} name="email" disabled={applicationInfo} defaultValue={applicationInfo?.email || ""} onBlur={(e) => isValid([e.target], false)}
                    // maxLength={20}
                    className="w-75"/>
                    <div className="c-f00 mt-6px">{errors?.email?.message}</div>
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t('94')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    <PhoneForm phoneId={ID.dataUseUserContact} font="nanumsquareb-17 nanumsquareeb-14:sm" disabled={applicationInfo} defaultValue={applicationInfo?.contact || ""} className="w-75" />
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title nanumsquareb-17 nanumsquareeb-14:sm c-000 col-lg" sm={12}>
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t('95')}
                        </Col>
                    </Row>
                </Col>
                <Col className="item">
                    {applicationInfo ? (
                        applicationInfo.files.map((file, i) => (
                            file && <div className="files-wrap align-middle" key={file._id}>
                                <a href={common.getStorageUrl(file.key, process.env.REACT_APP_NAVER_CLOUD_PRIVATE_BUCKET, 600)} target="_blank" rel="noopener noreferrer">
                                    {common.convertFileName(file.name, 40)}
                                </a>
                                <Timer time={600} />
                            </div>
                        ))
                    ) : (
                        <React.Fragment>
                            <div className="caution nanumsquarer-14 nanumsquarer-12:sm c-f00">
                                {t('96')}
                            </div>
                            <div className="file-group">
                                <ImageDropZone2
                                    className="create-data-use-files notosanskr-14 c-999"
                                    inputFileId={ID.dataUseFilesInput}
                                    disabled={state.files.length >= MAX_FILES}
                                    imgId={ID.dataUseFiles}
                                    ref={onDropfiles}
                                    accept=""
                                    multiple={true}
                                    placeholder={t('97')}
                                />
                            </div>
                            <div className="c-f00 mt-6px">{errors?.files?.message}</div>
                            {state.files.map((file, i) => (
                                <div className="files-wrap align-middle" key={i}>
                                    <span className="file-name nanumsquare-14 lh-1.43 ls--0.7px c-333 mr-15px">{common.convertFileName(file.name)}</span>
                                    <button
                                        className="remove-file"
                                        onClick={(e) => {
                                            removeFileFromFileList(ID.dataUseFilesInput, file);
                                            setState({ ...state, files: state.files.filter((certFile) => certFile !== file) });
                                        }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/19171.png`}
                                            srcSet={`${process.env.PUBLIC_URL}/19171@2x.png 2x,
                                            ${process.env.PUBLIC_URL}/19171@3x.png 3x`}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
            <Row className="confirm-btn-group" style={{ textAlign: "right" }}>
                <Col>
                    {onSubmit && onCancel ?
                        <>
                            <button
                                className="save-btn nanumsquareb-17 nanumsquareb-13:sm c-white"
                                onClick={async () => {
                                    if (!(await validateFields())) {
                                        common.scrollToInvalidElement();
                                        return;
                                    }
                                    onSubmit && onSubmit(state.files);
                                }}
                            >
                                {t('99')}
                            </button>
                            <button
                                className="cancel-btn nanumsquareb-17 nanumsquareb-13:sm c-white"
                                onClick={onCancel}
                            >
                                {t('80')}
                            </button>
                        </>
                        :
                        <button
                            className="confirm-btn nanumsquareb-17 nanumsquareb-13:sm c-white"
                            onClick={onConfirm}
                        >
                            {t('79')}
                        </button>
                    }
                </Col>
            </Row>
        </div>
    );
}
