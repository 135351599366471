import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

export default function LoginRecommendWindow ({onConfirm, onCancel}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_error@3x.png 3x`} alt="" />;
    const header = <div>{t('142')}</div>;
    const body = <>{t('143').split('\n').map((v, i) => <div key={i}>{v}</div>)}</>;

    return (
        <Modal
            onRequestClose={() => {
                if (onCancel) onCancel();
                else {
                    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                    const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
                    sessionStorage.setItem("prevPathname", prevPathname);
                    navigate(navigatePathname || "/", { replace: true });
                }
            }}
            onConfirm={onConfirm || (() => {
                navigate(`/Login/SignIn`);
            })}
            onCancel={() => {
                if (onCancel) onCancel();
                else {
                    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                    const navigatePathname = prevPathname.length > 1 ? prevPathname.splice(-2)[0] : "/";
                    sessionStorage.setItem("prevPathname", prevPathname);
                    navigate(navigatePathname || "/", { replace: true });
                }
            }}
            icon={icon}
            header={header}
            body={body}
        />
    );
}
