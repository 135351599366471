import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithOptionalHandle from "../hoc/WithOptionalHandle";
import useRadioHook from './RadioHook';
import RadioView from "./RadioView";
import common from '../../../common/common';

const Radio = forwardRef(({ _id, defaultValue, type, fields, sub_items, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return { ...fields, ...i18n[lang].fields };
            } else return fields;
    }, [fields, i18n]);
    const i18n_sub_items = useMemo(() => {
        const lang = common.getLang();
        if (i18n?.[lang]) {
            return sub_items.map((f) => ({ ...f, ...(i18n[lang].sub_items.find((x) => x.index == f.index) || {}) }));
        } else return sub_items;
    }, [sub_items, i18n]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        optionalValidation,
        setRadioValue,
        getRadioValue
    } = useRadioHook(ref);

    const formattedDefaultValue = useMemo(() => (
        defaultValue || []
    ), [defaultValue]);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setRadioValue(defaultValue);
        forceUpdate();
    }, [defaultValue, setRadioValue, forceUpdate]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getRadioValue();
        const { valid, errorCollection } = optionalValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getRadioValue, optionalValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // custom_string에 적용 될 handle
    const onBlurHandle = useCallback((e, subItemId) => {
        const currentValue = getRadioValue();
        if (currentValue) {
            const targetObject = currentValue.find(value => value._id === subItemId);
            targetObject["custom_string"] = e.target.value;
        }
        else {
            setRadioValue([{ _id: subItemId, "custom_string": e.target.value }]);
            validation();
        }
    }, [validation, setRadioValue, getRadioValue]);

    // 현재 element에서 값이 바뀌었을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    // radiobox에 적용 될 handle
    const onChangeHandle = useCallback((e) => {
        setRadioValue([{ _id: e.target.value }]);
        validation();
    }, [validation, setRadioValue]);

    const textDisabled = useCallback((subItemId) => {
        const currentValue = getRadioValue();
        if (currentValue) {
            return !currentValue.find(value => value._id === subItemId);
        }
        return true;
    }, [getRadioValue]);

    const textDefaultValue = useCallback((subItemId) => {
        const currentValue = getRadioValue();
        if (currentValue) {
            return currentValue.find(value => value._id === subItemId)?.["custom_string"] || "";
        }
    }, [getRadioValue]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        _id,
        title,
        description,
        type,
        defaultValue : formattedDefaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        sub_items: i18n_sub_items,
        onChangeHandle,
        onBlurHandle,
        textDisabled,
        textDefaultValue
    };

    return (
        <RadioView {...viewProps} />
    );
});

export default WithOptionalHandle(Radio);
