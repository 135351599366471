import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AppContext } from './AppContext';

import ga_init from './common/analytics'; // Google Analytics integration (Issue #1972)

import Navigation from './Components/Navigation';
import Home from './Components/Home';
import Report from './Components/Report';
import DetailedReport from './Components/Report/DetailedReport';
import Project from './Components/Project';
import DetailedProject from './Components/Project/DetailedProject';
import DetailedGroup from "./Components/DetailedGroup";
import CreateReport from './Components/CreateReport/CreateReport';
import CreateProject from './Components/Project/CreateProject';
import MyPage from './Components/Mypage';

import { Signup } from "./Components/Login/Signup";
import SignupEmail from './Components/Login/SignupEmail';
import SignIn from './Components/Login/SignIn';
import Password from './Components/Login/Password';
import ResetPassword from './Components/Login/ResetPassword';

import Introduction from './Components/More/Introduction';
import AntiEmail from "./Components/More/AntiEmail";
import PrivacyPolicy from './Components/More/PrivacyPolicy';
import Faq from './Components/More/Faq';
import AskQuestion from './Components/More/AskQuestion';

import Dev from './Components/common/Dev';
import ScrollToTop from "./ScrollToTop";
import Footer from "./Components/Footer";

import dgLogger from './common/dgLogger';
import { useMobile, usePC, useTablet } from './Components/common/MediaQuery';
import common from './common/common';

ga_init();

function App() {
    // null/*로그인/아웃 여부 알지 못함*/, true, false
    const [loggedIn, setLoggedIn] = useState(null);

    /**
     * Note. useContext를 쓸 때 주의할 사항은, Provider에 제공한 value가 달라지면
     *  useContext를 쓰고 있는 모든 컴포넌트가 리렌더링 된다는 것입니다.
     *  value 안에 다양한 변수들이 들어가게 될 경우, 그 중 하나라도 바뀌면 (객체로 묶여있으므로)
     *  전체가 리렌더링되는 것입니다. 따라서 잘못 쓰면 엄청난 렉을 유발할 수 있습니다.
     *  해결 방법은 자주 바뀌는 것들을 별도의 컨텍스트로 묶거나
     *  (컨텍스트는 여러 개 쓸 수 있습니다. Provider로만 잘 감싸주세요.),
     *  자식 컴포넌트들을 적절히 분리해서 shouldComponentUpdate, PureComponent,
     *  React.memo 등으로 감싸주는 것이 있습니다.
     *  see : https://www.zerocho.com/category/React/post/5fa63fc6301d080004c4e32b
     */
    const context = {
        loggedIn,
        setLoggedIn,
    };

    const onWindowError = (e) => {
        // window.onerror에서는 e.error를 사용
        // window.onunhandledrejection에서는 e.reason을 사용
        // 참고: https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event
        // 참고: https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
        dgLogger.error(e.error || e.reason)();
        e.preventDefault();

        // window.onerror에서는 true를 return해야 default behavior를 안 함
        return e.type === 'error';
    };

    useEffect(() => {
        if (process.env.REACT_APP_GLOBAL_TOP_BANNER === "true") {
            import('./App.banner-enabled.css');
        } else {
            import('./App.css');
            import('./App.background.css');
            // Note. 만약 조건별 다른 css 를 import 해야 한다면 다음과 같이 할 수 있음. 
            // if (common.getLang() == "ko") {
            //     import('./App.background.ko.css');
            // } else {
            //     import('./App.background.en.css');
            // }
        }
    }, []);

    useEffect(() => {
        window.removeEventListener('error', onWindowError);
        window.addEventListener('error', onWindowError);
        window.removeEventListener('unhandledrejection', onWindowError);
        window.addEventListener('unhandledrejection', onWindowError);
    }, []);

    // notes, redkur; for react router v6
    // https://ui.dev/react-router-pass-props-to-components/

    return (
        <AppContext.Provider value={context}>
            <Router>
                <div id="App">
                    {/* <BrowserView
                        style={{
                            minWidth: "1382px",
                            overflowX: "auto",
                        }}
                    > */}
                    <div className="background-img w-100">
                        {/*base64 encoded single dot. 실제 이미지는 css 에서 content 로 채움*/}
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==" alt="" />
                    </div>
                    <Navigation />
                    <ScrollToTop />
                    <Routes>
                        {/* Main Menu */}
                        <Route path="/" element={<Home/>}></Route>
                        <Route path="Report" element={<Report/>}></Route>
                        <Route path="Report/:view/:filter" element={<Report/>}></Route>
                        <Route path="Report/:view/:filter/:based" element={<Report/>}></Route>
                        <Route path="Report/:_id" element={<DetailedReport/>} />
                        <Route path="Project" element={<Project/>}></Route>
                        <Route path="Project/:_id" element={<DetailedProject/>} />
                        <Route path="Group/:_id" element={<DetailedGroup/>}></Route>
                        <Route path="CreateReport" element={<CreateReport/>}></Route>
                        <Route path="CreateProject" element={<CreateProject/>}></Route>
                        {/* Sub Menu */}
                        <Route path="Introduction" element={<Introduction/>}></Route>
                        <Route path="PrivacyPolicy" element={<PrivacyPolicy/>}></Route>
                        <Route path="antiemail" element={<AntiEmail/>}></Route>
                        <Route path="Faq" element={<Faq/>}></Route>
                        <Route path="AskQuestion" element={<AskQuestion/>}></Route>
                        {/* User Info */}
                        <Route path="MyPage/*" element={<MyPage/>}></Route>
                        <Route path="Login/SignIn" element={<SignIn/>}></Route>
                        <Route path="Login/Signup" element={<Signup/>}></Route>
                        <Route path="Login/Password" element={<Password/>}></Route>
                        <Route path="Login/ResetPassword" element={<ResetPassword/>}></Route>
                        <Route path="Login/SignupEmail" element={<SignupEmail/>}></Route>
                        {/* developer page */}
                        <Route path="Dev" element={<Dev/>}></Route>
                    </Routes>
                    <Footer />
                    {/* </BrowserView> */}
                    {/* <MobileView>
                        <div className="background-img min-vw-100 w-100 vh-100">
                            <img src={`${process.env.PUBLIC_URL}/main_visual01.png`} 
                                srcSet='
                                    `${process.env.PUBLIC_URL}/main_visual01@2x.png` 2x,
                                    `${process.env.PUBLIC_URL}/main_visual01@3x.png` 3x
                                '
                                alt="" />
                        </div>
                        <div id="mobile-view" className="w-100 vh-100 border-1" style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                            <Row className="navigation-bar text-center h-25 align-items-center">
                                <Col>
                                    <span className="main-menu">
                                        <span className="ocean c-fff">OCEAN</span>
                                        <span className="cloud c-fff">Cloud</span>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="h-50 text-center align-items-center">
                                <Col />
                                <Col className="col-auto nanumsquareeb-22-force p-4" style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
                                    <div>모바일에서는 사용이 불가합니다.</div>
                                    <div>PC에서 접속해주세요.</div>
                                </Col>
                                <Col />
                            </Row>
                        </div>
                    </MobileView> */}
                </div>
            </Router>
        </AppContext.Provider>
    );
}

export { App };
