import React, { useEffect, useState } from "react";
import { error as E } from "@ocean-knight/shared";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { POPUP as GeneralPopup } from "../../common/defines";
import "./Password.css";
import { RequestMailCodeWindow, NotFoundMailWindow, WrongMailCodeWindow } from "../common/MailAuthenticationWindow";
import common from "../../common/common";
import { InputText } from "../Input";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    TimeOutMailCode: 1013,
};

export default function Password() {
    const { t } = useTranslation();
    const [mail, setMail] = useState({ state: E.NONE, address: null });
    const [, setState] = useState({ popup: POPUP.None });
    const [errors, setErrors] = useState({ email: "" });

    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById("App").className = "login";
        let timer = null;
        if (mail.state === E.VERIFY && !timer) {
            timer = setTimeout(() => {
                setMail({ state: E.NONE, address: null });
                setState({ popup: POPUP.None, popupTarget: null });
            }, 1000 * 60 * 5); // 5 minutes
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        };
    }, [mail]);

    const verifyMail = () => {
        let email = document.getElementById("reset-pass-email");

        if (!isValid([email])) {
            common.scrollToInvalidElement();
            return;
        }

        axios
            .post("/v1/user/verify-reset-pass-email", {
                email: email.value,
                lang: common.getLang()
            })
            .then((res) => {
                if (res.data.code === E.FOUND) {
                    setMail({ state: res.data.payload.state, address: email.value });
                } else {
                    setMail({ state: E.NOTFOUND });
                }
            })
            .catch((err) => {
                dgLogger.error(err)();
                navigate("/", { replace: true });
            });
    };

    const verifyMailCode = () => {
        let element = document.getElementById("verify-mail-code");
        if (!element || !element.value) {
            dgLogger.warn("code is not defined")();
            return;
        }

        let code = element.value.trim();

        axios
            .post("/v1/user/verify-reset-pass-email-code", {
                code: code,
                email: mail.address,
            })
            .then((res) => {
                if (res.data.code === E.NOTFOUND) {
                    setMail({ state: E.WRONG_CODE, address: mail.address });
                    return;
                }

                // setMail({ state: E.VERIFIED, address: mail.address });
                navigate("/Login/ResetPassword", {
                    replace: true,
                    state: { state: E.VERIFIED, address: mail.address, code: code },
                });
            })
            .catch((err) => {
                dgLogger.error(err)();
                navigate("/", { replace: true });
            });
    };

    const popupVerifyMail = () => {
        // if (state.popup === POPUP.TimeOutMailCode) {
        //   return popupTimeOutMailCode();
        // }
        if (mail.state === E.VERIFY) {
            return popupRequestMailCode(mail.address);
        } else if (mail.state === E.NOTFOUND) {
            return popupNotFoundMail(mail.address);
        } else if (mail.state === E.WRONG_CODE) {
            return popupWrongMailCode(mail.address);
        }
    };

    const popupRequestMailCode = (mail) => {
        return (
            <RequestMailCodeWindow onRequestClose={() => setMail({ state: E.NONE, address: null })} onConfirm={verifyMailCode} onCancel={() => setMail({ state: E.NONE, address: null })} mail={mail} />
        );
    };

    const popupNotFoundMail = () => {
        return <NotFoundMailWindow onRequestClose={() => setMail({ state: E.NONE, address: null })} onConfirm={() => setMail({ state: E.NONE, address: null })} />;
    };

    const popupWrongMailCode = () => {
        return <WrongMailCodeWindow onRequestClose={() => setMail((prevState) => ({ ...prevState, state: E.VERIFY }))} onConfirm={() => setMail((prevState) => ({ ...prevState, state: E.VERIFY }))} />;
    };

    // 동작 변경으로 사용하지 않습니다.
    // const popupTimeOutMailCode = () => {
    //   return (
    //     <TimeOutMailCodeWindow
    //       onRequestClose={() => {
    //         setMail({ state: E.NONE, address: null });
    //         setState({ popup: POPUP.None, popupTarget: null });
    //       }}
    //       onConfirm={() => {
    //         setMail({ state: E.NONE, address: null });
    //         setState({ popup: POPUP.None, popupTarget: null });
    //       }}
    //     />
    //   );
    // };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    return (
        <Container>
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Find</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('291')}</span>
            </div>
            <div className="password-page">
                <div className="find-password notosanskr-500 font-size-30">{t('291')}</div>
                <div style={{ textAlign: "left" }} className="d-flex">
                    <InputText name="email" id="reset-pass-email" placeholder={t('293')} onBlur={(e) => isValid([e.target])} required={true} className="notosanskr-400 font-size-17" />
                    <button id="reset-pass-mail-button" className="notosanskr-400 font-size-18" onClick={verifyMail}>
                        {t('294')}
                    </button>
                </div>
                <div className="notosanskr-400 font-size-17 c-f00 mb-16px mt-10px">{errors.email?.message}</div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: "top" }}>
                                <img
                                    className="icon-waring"
                                    src={process.env.PUBLIC_URL + `/icon_warning.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/icon_warning@2x.png 2x, ${process.env.PUBLIC_URL}/icon_warning@3x.png 3x`}
                                    alt=""
                                />
                            </td>
                            <td style={{ transform: "translateY( -2px )" }}>
                                <span className="password-text notosanskr-400 font-size-16">
                                    {t('295').split('\n').map((v, i) => <div key={i}>{v}</div>)}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>{popupVerifyMail()}</div>
            </div>
        </Container>
    );
}
