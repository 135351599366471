import React, { useState, useRef, useEffect, useCallback } from "react";
import { useIsMount } from "../../common/customHook";
import Api from "../../Api";
import { Row, Col } from "react-bootstrap";
import Emblem from "../common/Emblem";
import "./ParticipantsSection.css";
import { IsPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";

export default function ParticipantsSection(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        participants : []
    });
    const isMount = useIsMount();
    const isPc = IsPC();
    const currentPage = useRef(1);
    const paginationOption = useRef({ itemsCountPerPage: props.itemsCountPerPage ? props.itemsCountPerPage : 4, pageRangeDisplayed: null, totalItemsCount: 0 }); // 추후 동작에 따라 ref가 아니라 일반 변수로 수정 할 수도 있음

    const getProjectParticipants = useCallback(async (page) => {
        const payload = await Api.getProjectParticipants({
            projectId: props.projectId,
            currentPage: page,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
            sort: { register_date: -1 },
        });
        currentPage.current = page;
        paginationOption.current.totalItemsCount = payload.totalItemsCount;
        return payload.participants;
    }, [props.projectId]);

    useEffect(() => {
        getProjectParticipants(currentPage.current)
            .then((participants) => {
                if (!isMount.current) return;
                setState(prev => ({...prev, participants: [...prev.participants, ...participants] }));
            })
            .catch(e => dgLogger.error(e)());
    }, [getProjectParticipants, isMount]);

    const moreClick = (e) => {
        e.target.disabled = true;

        const page = currentPage.current + 1;
        getProjectParticipants(page)
            .then((participants) => {
                setState({...state, participants: [...state.participants, ...participants] });
            })
            .catch((e) => {
                dgLogger.error(e)();
            })
            .finally(() => e.target.disabled = false);
    };

    return (
        <div id="participant-section">
            <Row className="gx-0 align-items-center top-bar">
                <Col className="notosanskr-300 font-size-30 title text-start ls--0.75px col-auto">
                    <Trans i18nKey={'248'} components={{tag: <span className="d-block:sm notosanskr-600 font-size-30"></span>}} />
                </Col>
                <Col>
                    <div className="line" />
                </Col>
                <Col className="count notosanskr-400 font-size-14 c-white col-auto text-center lh-0.95 ls--0.7px">
                    <span className="notosanskr-800 font-size-21 c-92c83d pe-2">{paginationOption.current.totalItemsCount}</span>{t('249')}
                </Col>
            </Row>

            <Row className="gx-0">
                {state.participants.map((participant, i) => (
                    <Col key={participant._id} className="profile col-auto">
                        <Emblem
                            width={`${isPc ? "170px" : "100%"}`}
                            height={`${isPc ? "170px" : "auto"}`}
                            url={participant.user.profile_picture ? participant.user.profile_picture.url : undefined}
                            urlClass="profile-image"
                            char={participant.user.name}
                            charClass={`avatar ${isPc ? "avatar-170" : "avatar-100%"} bg-e8ebf00 d`}
                        />
                        <div className="profile-name notosanskr-500 font-size-18 lh-1.56 ls--0.9px text-center">
                            {participant.user.name}
                        </div>
                    </Col>
                ))}
            </Row>

            {paginationOption.current.totalItemsCount > state.participants.length && (
                <Row key={state.participants.length} className="view-more-row gx-0 align-items-center">
                    <Col>
                        <div className="line" />
                    </Col>
                    <Col className="col-auto">
                        <button className="more notosanskr-400 font-size-18 lh-1.33 ls--0.9px c-white" onClick={moreClick}>
                            {t('4')}
                        </button>
                    </Col>
                    <Col>
                        <div className="line" />
                    </Col>
                </Row>
            )}
        </div>
    );
};
