import React, { forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

/**
 * @param props properties
    ```
    {
        imgId: file (parent) tag 의 id 값
        maxFiles: 최대 업로드 가능 파일 수
    }
    ```
 */
const ImageDropZone = forwardRef((props, onDrop) => {
    const { t } = useTranslation();
    const options = {
        accept: props.accept,
        maxFiles: props.maxFiles ? props.maxFiles : 10,
        onDrop : onDrop,
        multiple : typeof props.multiple === 'undefined'? true: props.multiple,
        disabled : props.disabled,
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

    return (
        <div id={props.imgId} style={{ display: "inline-block" }} {...getRootProps()}>
            <input id={props.inputFileId} {...getInputProps()} name={props.name}/>
            {isDragActive ? (
                <div className="drag-n-dropping" style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                }}>{props.droppingText !== undefined ? props.droppingText : t('558')}</div>
            ) : (
                <div className="drag-n-drop"
                    style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                    }}
                >
                    <div className={props.dropzoneClassName}>{props.droppableText !== undefined ? props.droppableText : t('559')}</div>
                </div>
            )}
        </div>
    );
});

export default ImageDropZone;
