import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { dummy } from "@ocean-knight/shared";
import { utility } from "@ocean-knight/shared";
import { useIsMount } from "../../common/customHook";
import Api from "../../Api";
import PostcodeWindow from "../PostcodeWindow";
import { POPUP as GeneralPopup } from "../../common/defines";
import { AppContext } from "../../AppContext";
import common from "../../common/common";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import PasswordConfirmForm from "../common/PasswordConfirmForm";
import PostcodeForm from "../common/PostcodeForm";
import PhoneForm from "../common/PhoneForm";
import { Button, Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import "./MyInfo.css";
import ProfileEmblem from "../common/ProfileEmblem";
import Emblem from "../common/Emblem";
import { InputText } from "../Input";
import { PopupValidateFile, PopupValidateImage } from "../common/ValidateModal";
import LoadingSpinner from "../common/LoadingSpinner";
import { PC, MobileOrTablet, IsPC, IsTabletOrPC } from "../common/MediaQuery";
import { mutate } from 'swr';
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";
import i18next from "i18next";

const ID = {
    mail: "my-info-email",
    nickname: "my-info-nickname",
    profile: "my-info-profile-image",
    postcodePopup: "my-info-postcode-popup",
    password: "my-info-password",
    passwordConfirm: "my-info-password-confirm",
    passwordMismatch: "my-info-password-mismatch",
    phone: "my-info-phone",
    address1: "my-info-address1",
    address2: "my-info-address2",
    about: "my-info-about",
    sns: "my-info-sns",

    updateUserInfo: "my-info-submit",
};

const POPUP = {
    ...GeneralPopup,
    WithdrawGroupMemberRequest: 1038,
    WithdrawGroupMemberRequested: 1039,
    WithdrawSiteMemberRequest: 1044,
    WithdrawSiteMemberRequested: 1045,
    UserInfoUpdated: 1048,
    UserInfoUpdate: 1049,
    ValidateFileField: 1050,
};

const MAX_SIZE = (1024 * 1024) * 200; // 200MB


export const PopupWithdrawSiteMemberRequest = ({onRequestClose, onConfirm, onCancel}) => {
    const icon = <img src={process.env.PUBLIC_URL + `/pop_member_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_member_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_member_withdraw@3x.png 3x`} alt="" />;
    const header = <div>{i18next.t('364')}</div>;
    const body = <div>{i18next.t('365').split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export const PopupWithdrawSiteMemberRequested = ({onRequestClose, onConfirm}) => {
    const icon = <img src={process.env.PUBLIC_URL + `/pop_member_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_member_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_member_withdraw@3x.png 3x`} alt="" />;
    const header = <div>{i18next.t('364')}</div>;
    const body = <div><Trans i18nKey={'366'} values={{"%s": "OCEAN Cloud"}} components={{tag: <span className="c-4270e0"></span>}} /></div>;

    return (
        <Modal
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export function MyInfo(props) {
    const { t } = useTranslation();
    const context = useContext(AppContext);
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        user: null,
        avatarUrl: null,
        isLoading: false,
    });
    const navigate = useNavigate();
    const isPc = IsPC();
    const isTabletOrPC = IsTabletOrPC();
    const isMount = useIsMount();
    const [errors, setErrors] = useState({ email: '', nickname: '', password: '', passwordConfirm: '', phone: '', address1: '' });

    useEffect(() => {
        if (!state.user && context.loggedIn) {
            Api.getCurrentUserInfo({ optPermissions: true, optGroups: true })
                .then((payload) => {
                    if (!isMount.current) return;

                    common.storePermissionsInfo(payload.optPermissions);
                    setState((prev) => ({ ...prev, user: payload, avatarUrl: payload.profile_picture ? payload.profile_picture?.url : null }));
                    // hasChangedFields();
                })
                .catch((e) => {
                    dgLogger.error(e)();
                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                });
        }
    }, [state.user, context.loggedIn, isMount]);

    const onSave = async (e) => {
        setState({ ...state, isLoading: true });
        const profile = document.getElementById(ID.profile).files[0];
        const email = document.getElementById(ID.mail).value;
        const phone = document.getElementById(ID.phone).value;
        const nickname = document.getElementById(ID.nickname)?.value;
        const password = document.getElementById(ID.password).value;
        const address1 = document.getElementById(ID.address1).value;
        const address2 = document.getElementById(ID.address2).value;
        const about = document.getElementById(ID.about).value;
        const sns = document.getElementById(ID.sns).value;

        try {
            const payload = {
                email: email,
                phone: phone,
                name: nickname,
                password: password,
                address1: address1,
                address2: address2,
                about: about,
                sns: sns,
            };

            if (profile && state.user.profile_picture) Api.deleteFiles([state.user.profile_picture?.key]); // 기존 image file 삭제
            if (profile) payload.profile_picture = (await Api.uploadFiles([profile]))[0];

            const updateResult = await Api.updateUserInfo(payload);
            if (!updateResult) {
                setErrors({ ...errors, nickname: { state: false, message: t('324') } });
                document.getElementById(ID.nickname).classList.add("invalid");
                setState({ ...state, popup: POPUP.None, popupTarget: null, isLoading: false });
                common.scrollToInvalidElement();
            }
            else {
                const user = await Api.getCurrentUserInfo({ optPermissions: true, optGroups: true });
                common.storePermissionsInfo(user.optPermissions);
                setState({ ...state, user: user, avatarUrl: user.profile_picture ? user.profile_picture?.url : null, popup: POPUP.UserInfoUpdated, popupTarget: null, isLoading: false });
            }
            // dgLogger.info("update user info success")();
        } catch (e) {
            dgLogger.error(e)();
            setState({ ...state, isLoading: false });
        }
    };

    const popupUserInfoUpdated = (e) => {
        // hasChangedFields();

        const icon = <img src={process.env.PUBLIC_URL + `/pop_data_change.png`} srcSet={`${process.env.PUBLIC_URL}/pop_data_change@2x.png 2x, ${process.env.PUBLIC_URL}/pop_data_change@3x.png 3x`} alt=""/>;
        const header = <div>{t('367')}</div>;
        const body = <div>{t('369')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    if (props.onUpdate) props.onUpdate();
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                    mutate("useUserinfo");
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupUserInfoUpdate = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_data_change.png`} srcSet={`${process.env.PUBLIC_URL}/pop_data_change@2x.png 2x, ${process.env.PUBLIC_URL}/pop_data_change@3x.png 3x`} alt=""  />;
        const header = <div>{t('367')}</div>;
        const body = <div><LoadingSpinner isOpen={state.isLoading} />{t('368')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={onSave}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupPostcode = () => {
        return (
            <div id={ID.postcodePopup}>
                <PostcodeWindow
                    onComplete={(address1, address2) => {
                        document.getElementById(ID.address1).value = address1;
                        document.getElementById(ID.address2).value = address2;
                        if (address1) document.getElementById(ID.address2).removeAttribute("disabled");

                        // hasChangedFields();
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={ () =>
                        setState({ ...state, popup: POPUP.None, popupTarget: null })
                    }
                />
            </div>
        );
    };

    const popupWithdrawGroupMemberRequest = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_withdraw@3x.png 3x`} alt="" />;
        const header = <div>{t('361')}</div>;
        const body = <div><Trans i18nKey={'362'} values={{"%s": state.popupTarget.name}} components={{tag: <span className="c-4270e0" />}} /></div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    Api.withdrawGroupMembers({
                        users: [state.user._id], // _id
                        group: state.popupTarget._id, // _id
                        reason: t('570'), // string (withdraw reason)
                        lang: common.getLang()
                    })
                        .then(() => {
                            Api.getCurrentUserInfo({ optPermissions: true, optGroups: true })
                                .then((payload) => {
                                    common.storePermissionsInfo(payload.optPermissions);
                                    setState({
                                        ...state,
                                        popup: POPUP.WithdrawGroupMemberRequested,
                                        user: payload,
                                        avatarUrl: payload.profile_picture ? payload.profile_picture?.url : null,
                                    });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                });
                        })
                        .catch((e) => dgLogger.error(e)());
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupWithdrawGroupMemberRequested = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_withdraw.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_withdraw@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_withdraw@3x.png 3x`} alt="" />;
        const header = <div>{t('361')}</div>;
        const body = <div><Trans i18nKey={'363'} values={{"%s": state.popupTarget.name}} components={{tag: <span className="c-4270e0" />}} /></div>;

        return (
            <Modal
                onRequestClose={() => {
                    props.renewPermissions();
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() => {
                    props.renewPermissions();
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Postcode) return popupPostcode();
        if (state.popup === POPUP.WithdrawGroupMemberRequest) return popupWithdrawGroupMemberRequest();
        if (state.popup === POPUP.WithdrawGroupMemberRequested) return popupWithdrawGroupMemberRequested();
        if (state.popup === POPUP.WithdrawSiteMemberRequest) return <PopupWithdrawSiteMemberRequest
            onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
            onConfirm={() => {
                Api.withdrawSiteMembers({
                    users: [state.popupTarget._id], // _id
                    reason: t('570'), // string (withdraw reason)
                    banned: false, // boolean (banned? or not)
                    lang: common.getLang()
                })
                    .then(() => {
                        setState({ ...state, popup: POPUP.WithdrawSiteMemberRequested });
                    })
                    .catch((e) => dgLogger.error(e)());
            }}
            onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
        />;
        if (state.popup === POPUP.WithdrawSiteMemberRequested) return <PopupWithdrawSiteMemberRequested
            onRequestClose={() => {
                Api.logout()
                    .then(() => {
                        // dgLogger.info("logout success")();
                        localStorage.removeItem("autoLogin");
                        context.setLoggedIn(false);
                        navigate("/");
                    })
                    .catch((e) => {
                        dgLogger.error(e)();
                        localStorage.removeItem("autoLogin");
                        context.setLoggedIn(false);
                        navigate("/");
                    });
            }}
            onConfirm={() => {
                Api.logout()
                    .then(() => {
                        // dgLogger.info("logout success")();
                        localStorage.removeItem("autoLogin");
                        context.setLoggedIn(false);
                        navigate("/");
                    })
                    .catch((e) => {
                        dgLogger.error(e)();
                        localStorage.removeItem("autoLogin");
                        context.setLoggedIn(false);
                        navigate("/");
                    });
            }}
        />;
        if (state.popup === POPUP.UserInfoUpdated) return popupUserInfoUpdated();
        if (state.popup === POPUP.UserInfoUpdate) return popupUserInfoUpdate();
        if (state.popup === POPUP.ValidateFileField) {
            const target = state.popupTarget;
            if (target.MIME_TYPE) {
                return (<PopupValidateImage
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                />);
            } else {
                return (
                    <PopupValidateFile
                        maxFiles={target.MAX_FILES}
                        maxSizeInMB={(target.MAX_SIZE) / (1024 * 1024)}
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                    />
                );
            }
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    if (!state.user) return <div>{popups()}</div>;

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements, true);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const onDuplicateNickname = async () => {
        try {
            const nickname = document.getElementById(ID.nickname).value.trim();
            if (!nickname) { return false; }

            if (nickname === state.user.name) return true;

            await Api.isDuplicatedUserName({ name: nickname });
            setErrors({ ...errors, nickname: null});
            document.getElementById(ID.nickname).classList.remove("invalid");
            return true;
        } catch (e) {
            dgLogger.error(e)();
            setErrors({ ...errors, nickname: { state: false, message: t('324') } });
            document.getElementById(ID.nickname).classList.add("invalid");
            return false;
        }
    };

    return (
        <div id="my-info">
            {popups()}

            <Row className="gx-0 align-items-center" style={{ marginBottom: "22px" }}>
                <Col className="notosanskr-600 font-size-40 c-black col-auto col-lg-auto">{t('360')}</Col>
                <Col>
                    {process.env.NODE_ENV !== "production" && (
                        <Button
                            style={{ border: "none" }}
                            onClick={() => {
                                let r = dummy.randomSignupInfo();
                                document.getElementById(ID.phone).value = r.phone;
                                document.getElementById(ID.address1).value = r.address1;
                                document.getElementById(ID.address2).value = r.address2;
                                document.getElementById(ID.about).value = r.about;
                                document.getElementById(ID.sns).value = r.sns;
                            }}
                        >
                            Auto Fill
                        </Button>
                    )}
                    {state.user.email === "oceancloud@devguru.co.kr" && (
                        <Button
                        style={{ marginLeft: "1rem", border: "none"}}
                        onClick={() => navigate("/dev")}
                        >Dev</Button>
                    )}
                </Col>
            </Row>
            <Row className="gx-0" style={{ border: "0px solid black" }}>
                <Col className={`${isPc ? "maxw-232px" : ""} text-break emblem-wrap`} sm={12}>
                    <Row className="gx-0">
                        <Col className="d-flex justify-content-center">
                            <ProfileEmblem
                                width={isTabletOrPC ? "151px" : "95px"}
                                height={isTabletOrPC ? "151px" : "95px"}
                                url={state.user && state.avatarUrl ? state.avatarUrl : undefined}
                                urlClass={"avatar"}
                                char={state.user && !state.avatarUrl ? state.user.name : undefined}
                                charClass={`avatar ${isTabletOrPC ? "avatar-151" : "avatar-95"} bg-secondary`}
                                profileId={ID.profile}
                                accept={"image/*"}
                                onChange={(e) => {
                                    // 이미지만 업로드 가능
                                    const [, , result] = common.validateOnDrop([e.target.files[0]], [], [], null, MAX_SIZE, ["image/"]);
                                    if (!result.valid) {
                                        setState(prev => ({ ...prev, popup: POPUP.ValidateFileField,
                                            popupTarget: {
                                                MAX_FILES: -1,
                                                MAX_SIZE: MAX_SIZE,
                                                MIME_TYPE: (!result.mimeType) ? "image/":null
                                            }
                                        }));
                                    } else {
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            // hasChangedFields();
                                            setState({ ...state, avatarUrl: reader.result });
                                        };
                                        reader.readAsDataURL(e.target.files[0]);
                                    }
                                }}
                            />
                        </Col>
                        <MobileOrTablet>
                            <Col className="d-flex">
                                <label className="info-user-name nanumsquareeb-20 d-flex align-items-center">{state.user.name}</label>
                            </Col>
                        </MobileOrTablet>
                    </Row>
                    <PC>
                        <Row>
                            <Col className="text-center">
                                <label className="info-user-name nanumsquareeb-24">{state.user.name}</label>
                            </Col>
                        </Row>
                    </PC>
                </Col>
                <Col style={{ marginLeft: isPc ? "49px" : "", border: "0px solid black" }}>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('284')}</Col>
                        <Col className="col-lg" sm={12}>
                            <InputText
                                id={ID.mail}
                                name="email"
                                defaultValue={state.user.email}
                                disabled={true}
                                style={{
                                    border: "0px",
                                    backgroundColor: "#fff",
                                    paddingLeft: "0px",
                                }}
                                className="notosanskr-400 font-size-16 c-333"
                            />
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('90')}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <InputText
                                id={ID.nickname}
                                name="nickname"
                                className="mb-0 w-100 maxw-482px"
                                placeholder={t('322')}
                                defaultValue={state.user.name}
                                onBlur={async (e) => {
                                    isValid([e.target]) && await onDuplicateNickname();
                                }}
                                maxLength={20}
                            />
                        {errors && <div className={`notosanskr-400 font-size-16 c-f00 mb-16px mt-10px errors`}>{errors?.nickname?.message}</div>}
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('327')}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <PhoneForm
                                phoneId={ID.phone}
                                defaultValue={state.user.phone}
                                errors={errors}
                                font={"notosanskr-400 font-size-16"}
                            />
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('329')}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <PostcodeForm
                                address1Id={ID.address1}
                                address1DefaultValue={state.user.address1}
                                address2Id={ID.address2}
                                address2DefaultValue={state.user.address2}
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.Postcode, popupTarget: null });
                                }}
                            // onChangeAddress2={() => {
                            //     hasChangedFields();
                            // }}
                            />
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('340')}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <textarea
                                id={ID.about}
                                name="about"
                                style={{ width: "100%", minHeight: "93px", maxWidth: "636px" }}
                                placeholder={t('341')}
                                defaultValue={state.user.about}
                                maxLength={50}
                            // onChange={() => hasChangedFields()}
                            />
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('342')}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <InputText
                                id={ID.sns}
                                name="sns"
                                style={{ width: "100%", maxWidth: "482px" }}
                                placeholder={t('343')}
                                defaultValue={state.user.sns}
                                maxLength={500}
                            // onChange={() => hasChangedFields()}
                            />
                        </Col>
                    </Row>
                    <Row className="info-item gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t(285)}</Col>
                        <Col className="col-lg notosanskr-400 font-size-16" sm={12}>
                            <PasswordConfirmForm passwordId={ID.password} confirmId={ID.passwordConfirm}
                                // onPasswordChange={(_) => hasChangedFields()}
                                // onPasswordConfirmChange={(_) => hasChangedFields()}
                                isValid={isValid}
                                errors={errors}
                                font={"notosanskr-400 font-size-16"}
                            />
                        </Col>
                    </Row>

                    <Row className="info-item info-item-bottom gx-0 align-items-center">
                        <Col className="info-item-title col-auto col-lg-auto notosanskr-500 font-size-17 col-lg" sm={12}>{t('238')}</Col>
                        <Col className="info-item-group col-lg" sm={12}>
                            {state.user.groups
                                .filter((item) => item.group)
                                .map((item, index) => {
                                    const permission = state.user.optPermissions.find((permission) => permission._id === item.permission);
                                    const group = state.user.optGroups.find((group) => group._id === item.group);
                                    return group && (
                                        <Row key={`${item.group}+${item.permission}+${index}`} className="gx-0 info-item-group-row align-items-center" xs="auto">
                                            <Col className="col-auto">
                                                <Emblem
                                                    width={"25px"}
                                                    height={"25px"}
                                                    url={group.emblem_picture ? group.emblem_picture?.url : undefined}
                                                    char={group.name}
                                                    charClass="avatar avatar-25 bg-e8ebf00"
                                                />
                                            </Col>
                                            <Col className="notosanskr-600 font-size-16 c-333 col-sm col-10 col-lg-auto" style={{ paddingLeft: "11px", paddingRight: "15px" }}>
                                                {`${common.i18nGroupName(group.name)} / ${utility.hasGroupManagerPermission(permission?.grade) ? `${t('464')}(${permission?.grade_name})` : `${t('493')}(${permission?.grade_name})`}`}
                                            </Col>
                                            <Col className="col-auto">
                                                {!group.built_in &&
                                                    <button
                                                        className="withdraw-group-btn"
                                                        onClick={() => {
                                                            setState({ ...state, popup: POPUP.WithdrawGroupMemberRequest, popupTarget: group });
                                                        }}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Col>
                    </Row>
                    <Row className="confirm-btn-group gx-0" style={{ textAlign: "right" }}>
                        <Col className="text-start">
                            <button
                                className="withdraw-member-btn notosanskr-400 font-size-18 c-white"
                                onClick={() => {
                                    setState({ ...state, popup: POPUP.WithdrawSiteMemberRequest, popupTarget: state.user });
                                }}
                                disabled={common.hasSiteAdminPermission()}
                            >
                                {t('364')}
                            </button>
                        </Col>
                        <Col className="text-end">
                            <button
                                id={ID.updateUserInfo}
                                onClick={async () => {
                                    const nickname = document.getElementById(ID.nickname);
                                    const password = document.getElementById(ID.password);
                                    const passwordConfirm = document.getElementById(ID.passwordConfirm);

                                    if (!(isValid([nickname, password, passwordConfirm]) && await onDuplicateNickname())) {
                                        common.scrollToInvalidElement();
                                        return;
                                    }
                                    setState({ ...state, popup: POPUP.UserInfoUpdate, popupTarget: null });
                                }}
                                className="save-btn notosanskr-400 font-size-18 c-white"
                            >
                                {t('265')}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
