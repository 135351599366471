import { useCallback } from "react";

export default function useSpinnerBoxHook(ref) {
    // HOC에서 공유 된 textValidation을 사용하기 위한 wrapper function
    const textValidation = useCallback(({ value, required, errorKey }) => {
        return ref.current?.textValidation?.({ value, required, errorKey });
    }, [ref]);

    // 현재 form에 알맞도록 value 설정
    const setSpinnerBoxValue = useCallback((value) => {
        ref.current?.setValue?.(value);
    }, [ref]);

    const getSpinnerBoxValue = useCallback(() => {
        return ref.current?.getValue?.();
    }, [ref]);

    return {
        textValidation,
        setSpinnerBoxValue,
        getSpinnerBoxValue,
    };
};
