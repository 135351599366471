import React, {useState, useEffect, useRef, useContext} from "react";
import Api from "../Api";
import { useIsMount } from "../common/customHook";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../AppContext";
import { Container, Row, Col } from "react-bootstrap";
import { ReportCard, updateGroupNameRowHeight } from "./Report/ReportCard";
import Emblem from "./common/Emblem";
import "./Home.css";
import { InputText } from "./Input";
import { IsPC ,IsMobile, IsTablet } from "./common/MediaQuery";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import CustomButtonLink from "./common/CustomButtonLink";
import Conditional from "./common/Conditional";
import dgLogger from "../common/dgLogger";
import common from "../common/common";
import { Trans, useTranslation } from "react-i18next";

export default function Home() {
    const { t } = useTranslation();

    const [state, setState] = useState({
        viewer : true,
        reportCollection : {
            report : 0,
            archivedProject : 0,
            activeProject: 0,
            investigator: 0,
            location: 0,
        },
        registers: [], // 등록된 자료들 (최대 10 개)
        projects: [], // 활성 프로젝트들 (최대 9 개)
        listOfProjects: [], // 3개씩 나누어 담은 project 리스트
    });
    const isPc = IsPC();
    const isTablet = IsTablet();
    const isMobile = IsMobile();

    const recentReportSwiperRef = React.useRef(null);
    const projectSwiperRef = React.useRef(null);

    const userGrade = useRef();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const context = useContext(AppContext);

    /**
     * 페이지 최초 접근 시 Editor에 표시 될 HTML Data와 user의 permission을 가져와 저장
     */
    useEffect(() => {
        document.getElementById("App").className = "";
        (async () => {
            try {
                const reportCollection = await Api.getReportCollection();
                const registers = await Api.getProjectRegister({ // 등록된 자료 획득
                    currentPage: 1,
                    itemsCountPerPage: 10,
                    sort: { register_date: -1 },
                });
                const activeProjects = await Api.getActiveProjectListAll({ // 활성 프로젝트 목록 획득
                    currentPage: 1,
                    itemsCountPerPage: 10,
                    sort: { start_date: -1 },
                    withHidden: false,
                });
                if (!isMount.current) return;
                setState((prev => ({
                    ...prev,
                    reportCollection: {...reportCollection, activeProject: activeProjects.totalItemsCount},
                    registers: registers.register,
                    projects: activeProjects.projects,
                    listOfProjects: activeProjects.projects.reduce((acc,cur,i) => {
                        const ch = Math.floor(i/3);
                        acc[ch] = [].concat((acc[ch]||[]),cur);
                        return acc;
                     }, []),
                })));
            } catch (e) {
                dgLogger.error(e)();
            }
        })();
    }, [isMount]);

    useEffect(() => {
        if (context.loggedIn) {
            Api.getCurrentUserMaxPermission()
                .then((permission) => {
                    if (!isMount.current) return;
                    userGrade.current = permission.grade_name;
                })
                .catch((e) => {
                    dgLogger.warn(e)(); // FIXME: 사용하지 않는 변수 userGrade와 관련된 exception (quirax) 코멘트는 유지하는게 좋을것 같습니다.
                });
        }
    }, [context.loggedIn, isMount]);

    const searchKeyword = (e) => {
        if (e.key === 'Enter' || e.type === 'click'){
            const keyword = encodeURIComponent(document.getElementById("keyword").value);
            navigate(`/Report/0/groups=&locations=&startDate=&endDate=&name=&myReport=false&keyword=${keyword}/page=1`);
        }
    };

    return (
        <div id="landing-page" className="mt-191px">
            <Container>
                <Row className="gx-0 title-row text-start mb-185px mb-100px:sm">
                    <Col
                        className="col-lg-auto"
                        style={{
                            maxWidth: "500px",
                        }}
                    >
                        <div className="title godob-35 c-white lh-0.86 lh-1.06:sm mb-30px">{t("17")}</div>
                        {/* <div className="sub-title nanumsquare-20b-force lh-1.5 ls--1px c-white"></div> */}
                        <div className="mt-10px">
                            <InputText className="c-333 notosanskr-400 font-size-14" id="keyword" onKeyPress={searchKeyword} />
                            <img
                                id="search-button"
                                onClick={searchKeyword}
                                src={process.env.PUBLIC_URL + `/icon_magnifying_glass.png`}
                                srcSet={`${process.env.PUBLIC_URL}/icon_magnifying_glass@2x.png 2x, ${process.env.PUBLIC_URL}/icon_magnifying_glass@3x.png 3x`}
                                alt=""
                            />
                        </div>
                    </Col>
                    <Col className="d-none d-md-flex">&nbsp;</Col>
                </Row>
                <Row className="gx-0 top-card text-start">
                    <div style={{ height: "0px" }}></div>
                    {/*TODO 구현되지 않은 항목 제거 #1193*/}
                    {/* <Col className="brief-card bg-white pt-46px pr-28px pl-46px">
                        <Row className="gx-0">
                            <Col className="nanumsquare-16-800-force lh-1.88 ls--0.8px c-4270e0">About Ocean Knights</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-24-800-force lh-1.25 ls--1.2px c-36c pb-28px">오션클라우드 소개</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-15b lh-1.4 ls--0.75px c-333 pb-20px">오션클라우드 최근활동을 기록합니다. 임의로 작성한 컨텐츠 영역입니다.</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="pb-45px">
                                <img
                                    className="top-card-icon"
                                    src={`${process.env.PUBLIC_URL}/main_icon_global.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/main_icon_global@2x.png 2x, ${process.env.PUBLIC_URL}/main_icon_global@3x.png 3x`}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="brief-card bg-4270e0 pt-46px pr-28px pl-46px">
                        <Row className="gx-0">
                            <Col className="nanumsquare-16-800-force lh-1.88 ls--0.8px c-white pr-18px">Introduction of Groups</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-24-800-force lh-1.25 ls--1.2px c-white pb-28px">그룹 소개</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-15b lh-1.4 ls--0.75px c-white pb-20px">오션클라우드 최근활동을 기록합니다. 임의로 작성한 컨텐츠 영역입니다.</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="pb-60px">
                                <img
                                    className="top-card-icon"
                                    src={`${process.env.PUBLIC_URL}/main_icon_group_over.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/main_icon_group_over@2x.png 2x, ${process.env.PUBLIC_URL}/main_icon_group_over@3x.png 3x`}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="brief-card bg-white pt-46px pr-46px pl-46px">
                        <Row className="gx-0">
                            <Col className="nanumsquare-16-800-force lh-1.88 ls--0.8px c-4270e0">Notice</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-24-800-force lh-1.25 ls--1.2px c-36c pb-28px">공지사항</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-15b lh-1.4 ls--0.75px c-333 pb-20px">오션클라우드 최근활동을 기록합니다. 임의로 작성한 컨텐츠 영역입니다.</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="pb-51px">
                                <img
                                    className="top-card-icon"
                                    src={`${process.env.PUBLIC_URL}/main_icon_gabage.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/main_icon_gabage@2x.png 2x, ${process.env.PUBLIC_URL}/main_icon_gabage@3x.png 3x`}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="brief-card bg-white pt-46px pr-46px pl-46px">
                        <Row className="gx-0">
                            <Col className="nanumsquare-16-800-force lh-1.88 ls--0.8px c-4270e0">Latest news</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-24-800-force lh-1.25 ls--1.2px c-36c pb-28px">최근 뉴스</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-15b lh-1.4 ls--0.75px c-333 pb-20px">오션클라우드 최근활동을 기록합니다. 임의로 작성한 컨텐츠 영역입니다.</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="pb-61px">
                                <img
                                    className="top-card-icon"
                                    src={`${process.env.PUBLIC_URL}/main_icon_notice.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/main_icon_notice@2x.png 2x, ${process.env.PUBLIC_URL}/main_icon_notice@3x.png 3x`}
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="brief-card bg-white pt-46px pr-46px pl-46px">
                        <Row className="gx-0">
                            <Col className="nanumsquare-16-800-force lh-1.88 ls--0.8px c-4270e0">Mobile App</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-24-800-force lh-1.25 ls--1.2px c-36c pb-28px">모바일 앱</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="nanumsquare-15b lh-1.4 ls--0.75px c-333 pb-20px">오션클라우드 최근활동을 기록합니다. 임의로 작성한 컨텐츠 영역입니다.</Col>
                        </Row>
                        <Row className="gx-0">
                            <Col>
                                <div className="pb-20px">
                                    <img
                                        className="top-card-icon"
                                        src={`${process.env.PUBLIC_URL}/main_google.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/main_google@2x.png 2x, ${process.env.PUBLIC_URL}/main_google@3x.png 3x`}
                                        alt=""
                                    />
                                </div>
                                <div className="pb-13px">
                                    <img
                                        className="top-card-icon"
                                        src={`${process.env.PUBLIC_URL}/main_app.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/main_app@2x.png 2x, ${process.env.PUBLIC_URL}/main_app@3x.png 3x`}
                                        alt=""
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </Container>
            <Container className="report-status-wrap-background-wrap">
                <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/bg_whale.png`}
                    srcSet={`${process.env.PUBLIC_URL}/bg_whale@2x.png 2x, ${process.env.PUBLIC_URL}/bg_whale@3x.png 3x`}
                    className="report-status-wrap-background"
                />
            </Container>
            <div className="report-status-wrap pt-125px pt-40px:sm">
                <Container>
                    <Row className="gx-0 text-start mb-106px mb-29px:sm">
                        <Col className="nanumsquare-800 font-size-50 nanumsquare-24-800-force:sm">{t("18")}</Col>
                    </Row>
                    <Row className="gx-0 report-status text-start">
                        <Col className="status col-auto">
                            <Link to="/Report">
                                <div className="image-wrap">
                                    <img className="status-card-icon" src={`${process.env.PUBLIC_URL}/main_data_count.png`} srcSet={`${process.env.PUBLIC_URL}/main_data_count@2x.png 2x`} alt="" />
                                </div>
                                <div className="notosanskr-400 font-size-18 nanumsquare-14:sm lh-1.67 ls--1.4px:sm minh-60px d-flex align-items-center">{t("19")}</div>
                                <div className="nanumsquare-800 font-size-48 nanumsquare-22-800-force:sm lh-0.86">{state.reportCollection.report}</div>
                            </Link>
                        </Col>
                        <Col className="status col-auto">
                            <Conditional elem={Link} condition={state.reportCollection.archivedProject > 0} to="/Project" state={{ hash: "#complete-project" }}>
                                <div className="image-wrap">
                                    <img
                                        className="status-card-icon"
                                        src={`${process.env.PUBLIC_URL}/main_data_complete.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/main_data_complete@2x.png 2x`}
                                        alt=""
                                    />
                                </div>
                                <div className="notosanskr-400 font-size-18 nanumsquare-14:sm lh-1.67 ls--1.4px:sm minh-60px d-flex align-items-center">
                                    <Trans i18nKey={'20'} components={{tag: <></>}} />
                                </div>
                                <div className="nanumsquare-800 font-size-48 nanumsquare-22-800-force:sm lh-0.86">{state.reportCollection.archivedProject}</div>
                            </Conditional>
                        </Col>
                        {isMobile && <div className="w-100" />}
                        <Col className="status col-auto">
                            <Conditional elem={Link} condition={state.reportCollection.activeProject > 0} to="/Project" state={{ hash: "#running-project" }}>
                                <div className="image-wrap">
                                    <img
                                        className="status-card-icon"
                                        src={`${process.env.PUBLIC_URL}/main_data_progress.png`}
                                        srcSet={`${process.env.PUBLIC_URL}/main_data_progress@2x.png 2x`}
                                        alt=""
                                    />
                                </div>
                                <div className="notosanskr-400 font-size-18 nanumsquare-14:sm lh-1.67 ls--1.4px:sm minh-60px d-flex align-items-center">{t("21")}</div>
                                <div className="nanumsquare-800 font-size-48 nanumsquare-22-800-force:sm lh-0.86">{state.reportCollection.activeProject}</div>
                            </Conditional>
                        </Col>
                        <Col className="status col-auto">
                            <div className="image-wrap">
                                <img
                                    className="status-card-icon"
                                    src={`${process.env.PUBLIC_URL}/main_data_investigator.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/main_data_investigator@2x.png 2x`}
                                    alt=""
                                />
                            </div>
                            <div className="notosanskr-400 font-size-18 nanumsquare-14:sm lh-1.67 ls--1.4px:sm minh-60px d-flex align-items-center">{t("22")}</div>
                            <div className="nanumsquare-800 font-size-48 nanumsquare-22-800-force:sm lh-0.86">{state.reportCollection.investigator}</div>
                        </Col>
                        <Col className="status col-auto">
                            <div className="image-wrap">
                                <img className="status-card-icon" src={`${process.env.PUBLIC_URL}/main_data_area.png`} srcSet={`${process.env.PUBLIC_URL}/main_data_area@2x.png 2x`} alt="" />
                            </div>
                            <div className="notosanskr-400 font-size-18 nanumsquare-14:sm lh-1.67 ls--1.4px:sm minh-60px d-flex align-items-center">{t("23")}</div>
                            <div className="nanumsquare-800 font-size-48 nanumsquare-22-800-force:sm lh-0.86">{state.reportCollection.location}</div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div
                style={{
                    width: "100%",
                    overflowX: "hidden",
                }}
            >
                <div className="main-recent-background">
                    <Container className="main-recent-content">
                        <Row className="gx-0 latest-reports text-start mb-114px mb-40px:sm">
                            <Col>
                                <Row className="gx-0 mt-136px mt-40px:sm mb-16px mb-5px:sm">
                                    <Col className="nanumsquare-800 font-size-50 nanumsquare-24-800-force:sm c-000 lh-1:sm minh-60px">{t("24")}</Col>
                                </Row>
                                <Row className="gx-0 mb-78px mb-20px:sm">
                                    <Col className="notosanskr-500 font-size-20 nanumsquare-13b:sm lh-1.5 ls--1px mb-14px:sm col-auto">
                                        {t('25')
                                            .split("\n")
                                            .map((v, i) => (
                                                <div key={i}>{v}</div>
                                            ))}
                                    </Col>
                                    <Col>{/*empty space*/}</Col>
                                    <Col className="text-start col-auto col-lg-auto">
                                        <CustomButtonLink className="reports-more notosanskr-500 font-size-18 nanumsquare-12b:sm lh-1.67 ls--0.9px c-white" to="/Report">
                                            {t("30")}
                                        </CustomButtonLink>
                                    </Col>
                                </Row>
                                <Row className="gx-0 mb-100px mb-29px:sm" onLoad={() => updateGroupNameRowHeight()}>
                                    <Col className="w-100 position-relative">
                                        <div className="swiper-wrap">
                                            {state.registers?.length > (isPc ? 5 : isTablet ? 3 : 2) && (
                                                <div className="left-chevron">
                                                    <button className="swiper-button-prev" onClick={() => recentReportSwiperRef.current.swiper.slidePrev()}>
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/main_arrow_left.png`}
                                                            srcSet={`${process.env.PUBLIC_URL}/main_arrow_left@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/main_arrow_left@3x.png 3x`}
                                                            alt=""
                                                        />
                                                    </button>
                                                </div>
                                            )}

                                            <Swiper
                                                key={state.registers?.length}
                                                slidesPerView={isPc ? 5 : isTablet ? 3 : 2}
                                                spaceBetween={isPc ? 35 : "5%"}
                                                loop={state.registers?.length > (isPc ? 5 : isTablet ? 3 : 2)}
                                                allowTouchMove={state.registers?.length > (isPc ? 5 : isTablet ? 3 : 2)} // 5개 초과의 경우에만 true 로 설정
                                                loopFillGroupWithBlank={true}
                                                className="recent-reported"
                                                navigation={{
                                                    prevEl: ".swiper-button-prev",
                                                    nextEl: ".swiper-button-next",
                                                }}
                                                ref={recentReportSwiperRef}
                                            >
                                                {state.registers.map((report, index) => (
                                                    <SwiperSlide key={report._id}>
                                                        <ReportCard
                                                            id={report._id}
                                                            key={report._id}
                                                            checkbox={false}
                                                            report_id={report._id}
                                                            registered_by={report.registered_by}
                                                            date={report.date}
                                                            register_date={report.register_date}
                                                            group_name={common.i18nGroupName(report.group_name)}
                                                            report_file_url={report.report_file_url}
                                                            title={report.title}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        {state.registers?.length > (isPc ? 5 : isTablet ? 3 : 2) && (
                                            <div className="right-chevron">
                                                <button className="swiper-button-next" onClick={() => recentReportSwiperRef.current.swiper.slideNext()}>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/main_arrow_right.png`}
                                                        srcSet={`${process.env.PUBLIC_URL}/main_arrow_right@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/main_arrow_right@3x.png 3x`}
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="overflow-hidden">
                <Container className="projects">
                    <Row className="gx-0 latest-projects text-start align-items-center position-relative">
                        <Col className="projects-description">
                            <Row className="gx-0">
                                <Col className="mb-auto d-none d-md-block">
                                    <div className="notosanskr-600 font-size-24 notosanskr-12b:sm pl-7px">PROJECT</div>
                                    <div className="nanumsquare-800 font-size-50 nanumsquare-24-800-force:sm pb-18px">{t("3")}</div>
                                </Col>
                                <Col className="mb-auto d-sm-block d-md-none pb-15px">
                                    <span className="nanumsquare-800 font-size-50 nanumsquare-24-800-force:sm">{t("3")}</span>
                                    <span className="notosanskr-600 font-size-24 notosanskr-12b:sm pl-7px">PROJECT</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="notosanskr-500 font-size-16 notosanskr-13-500:sm lh-1.5 ls--0.8px">{t("32")}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="projects-cards col-xl" md={12}>
                            <div className="position-relative swiper-wrap">
                                {state.projects?.length > (isPc ? 4 : isTablet ? 3 : 1) && (
                                    <div className="left-chevron">
                                        <button className="swiper-button-prev" onClick={() => projectSwiperRef.current.swiper.slidePrev()}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/29-1.png`}
                                                srcSet={`${process.env.PUBLIC_URL}/29-1@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/29-1@3x.png 3x`}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                )}
                                <Swiper
                                    key={state.projects?.length}
                                    slidesPerView={isPc ? 4 : isTablet ? 3 : 1}
                                    spaceBetween={33}
                                    loop={state.projects?.length > (isPc ? 4 : isTablet ? 3 : 1)}
                                    allowTouchMove={state.projects?.length > (isPc ? 4 : isTablet ? 3 : 1)} // 4개 초과의 경우에만 true 로 설정
                                    loopFillGroupWithBlank={true}
                                    className="list-of-group"
                                    navigation={{
                                        prevEl: ".swiper-button-prev",
                                        nextEl: ".swiper-button-next",
                                    }}
                                    ref={projectSwiperRef}
                                >
                                    {state.projects.map((project) => (
                                        <SwiperSlide key={project._id} className="mb-30px">
                                            <div key={project._id}>
                                                <Row className="gx-0">
                                                    <Col key={project._id} className="project-wrap">
                                                        <Link to={`/Project/${project._id}`}>
                                                            <div className="mb-18px">
                                                                <Emblem
                                                                    width={`${isPc ? "256px" : "256px"}`}
                                                                    height={`${isPc ? "256px" : "256px"}`}
                                                                    url={project.representative_picture ? project.representative_picture.url : undefined}
                                                                    urlClass="profile-image"
                                                                    char={project.name}
                                                                    charClass="avatar avatar-256 bg-e8ebf00"
                                                                />
                                                            </div>
                                                        </Link>
                                                        <div className="text-start notosanskr-600 font-size-27 notosanskr-18b:sm project-name">{project.name}</div>
                                                        <div className="text-start notosanskr-400 font-size-15 notosanskr-13:sm project-about lh-1.2 pl-2px">{project.about}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                {state.projects?.length > (isPc ? 4 : isTablet ? 3 : 1) && (
                                    <div className="right-chevron">
                                        <button className="swiper-button-next" onClick={() => projectSwiperRef.current.swiper.slideNext()}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/29-2.png`}
                                                srcSet={`${process.env.PUBLIC_URL}/29-2@2x.png 2x,
                                                ${process.env.PUBLIC_URL}/29-2@3x.png 3x`}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
