import React, { useState } from "react";
import Modal from "../common/Modal";
import ReactModal from "react-modal";
import "./MailTransferWindow.css";
import { InputText } from "../Input";
import ImageDropZone2 from "../common/ImageDropZone2";
import common from "../../common/common";
import { PopupValidateFile } from "../common/ValidateModal";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useTranslation } from "react-i18next";

const ID = {
    sendMailUsers: "member-manage-send-mail-users",
    sendMailTitle: "member-manage-send-mail-title",
    sendMailContent: "member-manage-send-mail-content",
    sendMailAttaches: "member-manage-send-mail-attaches",
};

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 25; // 25MB

const MailTransferWindow = (props) => {
    const { t } = useTranslation();
    const users = props.users;
    const [state, setState] = useState({
        files: [],

        popup: POPUP.None,
        popupTarget: null,
    });
    const [errors, setErrors] = useState({ mailTitle: "", mailContent: "" });

    const onDrop = (acceptedFiles, fileRejections) => {
        // 모든 파일 업로드 가능
        const [, allFiles, valid] = common.validateOnDrop(acceptedFiles, fileRejections, state.files, MAX_FILES, MAX_SIZE);
        if (!valid.valid) {
            return setState(prev => ({...prev, popup: POPUP.ValidateFileField, }));
        }

        setState({...state, files:allFiles});
    };

    const removeFileFromFileList = (elementId, targetFile) => {
        const dt = new DataTransfer();
        const input = document.getElementById(elementId);
        const { files } = input;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file !== targetFile)
            dt.items.add(file); // here you exclude the file. thus removing it.
        }

        input.files = dt.files; // Assign the updates list
    };

    const validateFields = () => {
        let flag = true;
        const errorCollection = {};
        const title = document.getElementById(ID.sendMailTitle);
        const content = document.getElementById(ID.sendMailContent);

        common.validateInputField(errorCollection, "mailTitle", title) || (flag = false);
        common.validateInputField(errorCollection, "mailContent", content) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    /**
     *
     * @returns popup 상태에 따라 popup 호출
     */
     const popups = () => {
        if (state.popup === POPUP.ValidateFileField)
            return (
                <PopupValidateFile
                    maxFiles={MAX_FILES}
                    maxSizeInMB={MAX_SIZE / (1024 * 1024)}
                    onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                />
            );
        // if (state.popup === POPUP.GeneralError)
        //     return (
        //         <GeneralErrorWindow
        //             message={state.popupTarget}
        //             onClick={() => {
        //                 navigate("/");
        //             }}
        //         />
        //     );
    };

    return (
        <>
            {popups()}
            <ReactModal
                isOpen={true}
                // onRequestClose={props.onCancel}
                className={props.className ? props.className : "default"}
                style={{ overlay: {}, content: {} }}
                portalClassName={"mail-transfer"}
            >
                <div className="title nanumsquareeb-18">{t('436')}
                    <div style={{ position: "absolute", right: "32px" }}>
                        <img src={process.env.PUBLIC_URL + `/icon_close.png`} srcSet={`${process.env.PUBLIC_URL}/icon_close@2x.png 2x, ${process.env.PUBLIC_URL}/icon_close@3x.png 3x`} onClick={props.onCancel} alt="" style={{ cursor: "pointer" }} />
                    </div>
                </div>

                <div className="body">
                    <div className="content">
                        <table className="mail-transfer-table">
                            <tbody>
                                <tr>
                                    <td className="item-title nanumsquarer-14">
                                        {t('448')}
                                    </td>
                                    <td className="item notosanskr-16">
                                        <div id={ID.sendMailUsers}>
                                            {users?.map((user) => (
                                                <span key={user._id}>{user.email}; </span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquarer-14">
                                        {t('275')}
                                    </td>
                                    <td className="item notosanskr-14">
                                        <InputText id={ID.sendMailTitle} name="title" required onBlur={(e) => {
                                            const errorCollection = {};
                                            common.validateInputField(errorCollection, "mailTitle", e.target);
                                            setErrors({ ...errors, ...errorCollection });
                                        }} />
                                        {errors?.mailTitle?.message && <div className="c-f00">{errors?.mailTitle?.message}</div>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquarer-14">
                                        {t('277')}
                                    </td>
                                    <td className="item notosanskr-14">
                                        <textarea id={ID.sendMailContent} name="contents" placeholder={t('278')} required onBlur={(e) => {
                                            const errorCollection = {};
                                            common.validateInputField(errorCollection, "mailContent", e.target);
                                            setErrors({ ...errors, ...errorCollection });
                                        }} />
                                        {errors?.mailContent?.message && <div className="c-f00">{errors?.mailContent?.message}</div>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="item-title nanumsquarer-14">
                                        {t('95')}
                                    </td>
                                    <td className="item">
                                        <div className="file-group d-flex flex-row">
                                            <ImageDropZone2
                                                className="mail-transfer-files notosanskr-14 c-999"
                                                inputFileId={"files-id"}
                                                disabled={state.files.length >= MAX_FILES}
                                                imgId={"files"}
                                                ref={onDrop}
                                                accept=""
                                                multiple={true}
                                                placeholder={t('279')}
                                            />
                                        </div>
                                        {state.files.map((file, i) => (
                                            <div className="files-wrap align-middle" key={i}>
                                                <span className="file-name nanumsquare-14 lh-1.43 ls--0.7px c-333 mr-15px">{common.convertFileName(file.name)}</span>
                                                <button
                                                    className="remove-file"
                                                    onClick={(e) => {
                                                        removeFileFromFileList("files-id", file);
                                                        setState({ ...state, files: state.files.filter((certFile) => certFile !== file) });
                                                    }}
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/19171.png`}
                                                        srcSet={`${process.env.PUBLIC_URL}/19171@2x.png 2x,
                                            ${process.env.PUBLIC_URL}/19171@3x.png 3x`}
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="button-group">
                            <button
                                id="send-email"
                                className="send nanumsquareb-14"
                                onClick={() => {
                                    if (!validateFields()) {
                                        common.scrollToInvalidElement();
                                        return;
                                    }
                                    props.onSend({
                                        users: users.map((user) => user._id),
                                        title: document.getElementById(ID.sendMailTitle).value,
                                        contents: document.getElementById(ID.sendMailContent).value,
                                        files: state.files,
                                    });
                                }}
                            >
                                {t('280')}
                            </button>
                            <button className="cancel nanumsquareb-14" onClick={props.onCancel}>
                                {t('80')}
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

const MailTransferCompletedWindow = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_success.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_success@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_success@3x.png 3x`} alt="" />;
    const header = <div>{t('449')}</div>;
    const body = <div>{t('450')}</div>;

    return (
        <Modal
            onRequestClose={props.onClose}
            onConfirm={props.onClose}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

const MailTransferFailureWindow = (props) => {
    const { t } = useTranslation();
    const icon = <img src={process.env.PUBLIC_URL + `/pop_mail_fail.png`} srcSet={`${process.env.PUBLIC_URL}/pop_mail_fail@2x.png 2x, ${process.env.PUBLIC_URL}/pop_mail_fail@3x.png 3x`} alt="" />;
    const header = <div>{t('451')}</div>;
    const body = (
        <>
            <div>{t('452')}</div>
            <div className="c-4270e0">{t('447')} {props.reason}</div>
        </>
    );

    return (
        <Modal
            onRequestClose={props.onClose}
            onConfirm={props.onClose}
            icon={icon}
            header={header}
            body={body}
        />
    );
};

export { MailTransferWindow, MailTransferCompletedWindow, MailTransferFailureWindow };
