import React, { useEffect, useState, forwardRef, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { InputText } from "../Input";
import "./ImagePreviewTable.css";
import { useTranslation } from "react-i18next";
import common from "../../common/common";

const ImagePreviewTable = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [countSum, setCountSum] = useState([]);

    useEffect(() => {
        ref.current = ref.current.map(answer => (answer ? answer : []));
        setCountSum(ref.current.map((answers, i) =>
            answers.reduce((acc, cur) => {
                if (cur?.answer) acc += cur.answer;
                return acc;
            }, 0)
        ));
    }, [ref]);

    return (
        <>
            <Row className="gx-0 image-preview-table notosanskr-400 font-size-16 bg-white" key={props.index}>
                <Row className="gx-0 title notosanskr-500 font-size-18">
                    <Col className="col-9">{t('516')}</Col>
                    <Col className="col-3 text-center">{t('517')}</Col>
                </Row>
                <div className="question-and-answer-wrap">
                    {props.questions.reduce((acc, question, questionIndex) => {
                        if (props.type === "edit" || !isNaN(ref.current[props.index][questionIndex]?.answer)) {
                            const answer = ref.current[props.index][questionIndex]?.answer;
                            acc.push(
                                <Row className="gx-0 question-and-answer"
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                    key={`${question._id}_${props.index}`}
                                >
                                    <Col className="col-9">{`${String.fromCharCode(65/*A*/ + questionIndex)}. ${question.question}`}</Col>
                                    <Col className="col-3">
                                        <InputText
                                            id={question._id}
                                            defaultValue={answer}
                                            disabled={props.type !== "edit"}
                                            className="answers"
                                            maxLength={4}
                                            onChange={(e) => e.target.value = e.target.value.replace(/\D/g, "")}
                                            onBlur={(e) => {
                                                const items_list = document.getElementsByClassName("answers");
                                                const items_array = Array.prototype.slice.call(items_list);
                                                ref.current[props.index][questionIndex] = {
                                                    _id: question._id,
                                                    answer: parseInt(e.target.value) || 0
                                                };
                                                countSum[props.index] = items_array.reduce((acc, item) => {
                                                    if (item.value) acc += parseInt(item.value);
                                                    return acc;
                                                }, 0);
                                                setCountSum([...countSum]);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        }
                        return acc;
                    }, [])}
                </div>
                <Row className="gx-0 sum notosanskr-500 font-size-18">
                    <Col className="col-9">{t('518')}</Col>
                    <Col className="col-3 text-center">{countSum[props.index]?.toLocaleString() || 0}</Col>
                </Row>
                {props.type === "edit" ? (
                <Row className="gx-0 button-group notosanskr-400 font-size-18 ">
                    <Col>
                        <button
                            className="confirm"
                            onClick={() => {
                                props.onConfirm(ref.current, countSum);
                            }}
                        >
                            {t('79')}
                        </button>
                        <button className="cancel" onClick={props.onCancel}>
                            {t('80')}
                        </button>
                    </Col>
                </Row>
            ) : undefined}
            </Row>
        </>
    );
});

export default ImagePreviewTable;
