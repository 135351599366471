import React, {  } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ProjectsSection.css";
import Emblem from "../common/Emblem";
import { IsPC } from "../common/MediaQuery";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import common from "../../common/common";

const PROJECT_STATE = {
    PLANNED : "PLANNED",
    ACTIVE : "ACTIVE",
    COMPLETED : "COMPLETED",
};

export { PROJECT_STATE };

export default function ProjectsSection(props) {
    const { t } = useTranslation();
    const isPc = IsPC();
    const itemPerLine = props.paginationOption.itemsCountPerPage;
    const listOfProjects = props.projects.reduce((acc, cur, i) => {
        if (i % itemPerLine === 0) acc.push([]);
        const index = Math.floor(i / itemPerLine);
        acc[index].push(cur);
        return acc;
    }, []);

    const RenderProjectState = (projectState) => {
        switch (projectState) {
            case PROJECT_STATE.PLANNED:
                return <span className="complete-badge c-white">
                    {t('221')}
                </span>;
            case PROJECT_STATE.ACTIVE:
                return (
                    <span className="active-badge c-white">
                        {t('220')}
                        <img src={`${process.env.PUBLIC_URL}/icon_refresh2.png`}
                        srcSet={`${process.env.PUBLIC_URL}/icon_refresh2@2x.png 2x, ${process.env.PUBLIC_URL}/icon_refresh2@3x.png 3x`} 
                        alt="" />
                    </span>
                );
            case PROJECT_STATE.COMPLETED:
                return <span className="complete-badge c-white">{t('222')}</span>;
            default:
                dgLogger.error("unknown project state : " + projectState)();
                return <></>;
        }
    };

    return (
        <div id="project-section">
            {listOfProjects.map((projects, i) => (
            <Row key={i} className="gx-0 project-group">
                {projects.map((project, index) => (
                    <Col key={project._id} className="project-card col-auto">
                        <Row className="gx-0">
                            <Col as={Link} className="image notosanskr-500 font-size-12"
                                style={{
                                    backgroundImage: `url("${project.representative_picture?.url || ""}")`,
                                }}
                                to={`/Project/${project._id}`}
                            >
                                {RenderProjectState(props.state)}

                                {/* <img
                                    src={project.representative_picture.url}
                                    alt="Profile"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                    }}
                                    onClick={() => navigate(`/Project/${project._id}`)}
                                /> */}
                            </Col>
                            <Col style={{ padding: `${isPc ? "18px 20px 18px 28px" : " 9px 10px 9px 10px"}` }}>
                                <Row className="gx-0 align-items-center">
                                    <Col className="col-auto" style={{width: `${isPc ? "97px" : "56px"}`}}>
                                        <Emblem
                                            width={`${isPc ? "97px" : "56px"}`}
                                            height={`${isPc ? "97px" : "56px"}`}
                                            url={project.optGroup.emblem_picture && project.optGroup.emblem_picture.url}
                                            char={project.optGroup.name}
                                            charClass={`avatar ${isPc ? "avatar-97" : "avatar-56"} bg-e8ebf00`}
                                        />
                                    </Col>
                                    <Col className={`${isPc ? "pl-20px" : "pl-5px"}`}>
                                        <Row className="mb-8px gx-0 align-items-center">
                                            <Col className="group-name notosanskr-400 font-size-13 text-truncate col-auto col-4 pe-3">
                                                <span className="button">{t('38')}</span>
                                            </Col>
                                            <Col className="group-name notosanskr-400 font-size-13 text-break">
                                                {common.i18nGroupName(project.optGroup.name)}
                                            </Col>
                                        </Row>
                                        <Row className="gx-0 align-items-center">
                                            <Col className="project-date notosanskr-400 font-size-13 c-666 col-auto col-4 pe-3">
                                                <span className="button">
                                                    {t('60')}
                                                </span>
                                            </Col>
                                            <Col className="project-date notosanskr-400 font-size-13 c-666 text-break">
                                                <span className="text-nowrap">{new Date(project.start_date).toLocaleDateString()}</span>
                                                &nbsp;~&nbsp;
                                                <span className="text-nowrap">{new Date(project.end_date).getFullYear() < 9999 && new Date(project.end_date).toLocaleDateString()}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ padding: `${isPc ? "0px 15px 0px 13px" : " 0px 10px 0px 10px"}` }}>
                                    <hr className={`${isPc ? "mt-19px mb-25px" : "mt-6px mb-3px"}`} style={{ borderTop: "1px dashed #A2AFCF" }} />
                                </Row>
                                <Row className="gx-0">
                                    <Col className="project-name notosanskr-700 font-size-21 text-break">
                                        {project.name}
                                    </Col>
                                </Row>
                                <Row className="gx-0">
                                    <Col className="project-desc notosanskr-400 font-size-15 c-333 text-break">
                                        {project.about}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                ))}
                </Row>
            ))}
        </div>
    );
}
