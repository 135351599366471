import React, { useEffect, useState, useCallback, useRef } from "react";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import { Row, Col } from "react-bootstrap";
import Emblem from "../common/Emblem";
import "./TopReporterSection.css";
import dgLogger from '../../common/dgLogger';
import common from "../../common/common";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

/**
 *
 * @param {*} props properties
    ```
    {
        projectId: "", // project._id
    }
    ```
 * @returns jsx
 */
export default function TopReporterSection(props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        reporters: [],
    });
    const isMount = useIsMount();
    const currentPage = useRef(1);
    const paginationOption = useRef({itemsCountPerPage: 5, pageRangeDisplayed: 10, totalItemsCount: 0});

    const getTopReporterList = useCallback(() => {
        const payload = {
            projectId: props.projectId,
            page: currentPage.current,
            itemsCountPerPage: paginationOption.current.itemsCountPerPage,
        };

        Api.getTopReporterList(payload)
            .then((payload) => {
                if (!isMount.current) return;
                paginationOption.current.totalItemsCount = payload.count;
                setState(prev => ({ ...prev, reporters: payload.reporters }));
            })
            .catch(e => {
                dgLogger.error(e)();
            });
    }, [isMount, props.projectId]);

    useEffect(() => {
        if (!props.projectId) return; // skip it
        getTopReporterList();
    }, [props.projectId, getTopReporterList]);

    const getReportListBasedUrl = useCallback((groupId, projectId, name) => {
        return common.getReportListBasedUrl({
            groups:[{value: groupId}],
            selectProjects: [{value: projectId}],
            name: encodeURIComponent(name)});
    }, []);

    return (
        <div id="top-reporter">
            <Row className="gx-0 align-items-center top-bar">
                <Col className="col-auto notosanskr-300 font-size-30 title ls--0.75px">
                    <Trans i18nKey={'251'} components={{tag: <span className="d-block:sm notosanskr-600 font-size-30"></span>}}/>
                </Col>
                <Col><div className="line"/></Col>
                <Col className="col-auto"><div className="dot"/></Col>
            </Row>
            {state.reporters.map((reporter, i) => (
                <Row key={reporter._id} className={`contributor gx-0 align-items-center ${i % 2 === 0 ? "bg-e9edf2" : "bg-f4f6f8"}`}>
                    <Col className="profile col-auto">
                        <Emblem
                            width={"56px"}
                            height={"56px"}
                            url={reporter.profile_picture ? reporter.profile_picture.url : undefined}
                            urlClass="profile-image"
                            char={reporter.name}
                            charClass="avatar avatar-56 bg-e8ebf00"
                        />
                    </Col>
                    <Col className="user text-start">
                        <Row className="gx-0">
                            <Col className="group notosanskr-600 font-size-14 lh-1.29 ls--0.7px c-666">
                                {reporter.groupName}
                            </Col>
                        </Row>
                        <Row className="gx-0">
                            <Col className="name notosanskr-700 font-size-21 lh-1.24 ls--0.53px c-000">
                                {reporter.name}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="count notosanskr-400 font-size-16 lh-1.5 ls--0.8px col-auto">
                        <Link to={getReportListBasedUrl(props.group, props.projectId, reporter.name)} className="text-decoration-none">
                            {i < 3 && <img className="medal-image" src={`${process.env.PUBLIC_URL}/icon_medal_${i}.png`}
                                srcSet={`${process.env.PUBLIC_URL}/icon_medal_${i}@2x.png 2x, ${process.env.PUBLIC_URL}/icon_medal_${i}@3x.png 3x`}
                                alt="" />}
                            <span className="notosanskr-800 font-size-26 lh-1.15 ls--0.65px c-4270e0">{reporter.reportCount}</span> {t('234')}
                        </Link>
                    </Col>
                </Row>
            ))}
        </div>
    );
}
