import React, { useState, useEffect, useCallback, useRef } from "react";
import Api from "../../../Api";
import ImageDropZone from "../../common/ImageDropZone";
import { useIsMount } from "../../../common/customHook";
import common from "../../../common/common";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./UpdateGroupInformation.css";
import ProfileEmblem from './../../common/ProfileEmblem';
import { POPUP as GeneralPopup } from "../../../common/defines";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { useNavigate } from "react-router-dom";
import Modal from "../../common/Modal";
import { InputText } from "../../Input";
import { PopupValidateFile, PopupValidateImage } from "../../common/ValidateModal";
import dgLogger from "../../../common/dgLogger";
import { Trans, useTranslation } from "react-i18next";

const POPUP = {
    ...GeneralPopup,
    ValidateFileField: 1004,
};

const ID = {
    pictures: "group-introduce-pictures",
    pictureDrop: "group-introduce-picture-drop",
    emblem: "group-introduce-emblem-image",

    updateGroupOwner: "update-group-owner",
    updateGroupResearch: "update-group-research",
    updateGroupRelLink: "update-group-link",
    updateGroupAbout: "update-group-about",
    updateGroupRequireJoinConfirm: "update-group-require-join-confirm",
};

const MAX_FILES = 10;
const MAX_SIZE = (1024 * 1024) * 200; // 200MB

export default function UpdateGroupInformation(props) {
    const { t } = useTranslation();
    const isMount = useIsMount();
    const navigate = useNavigate();
    const [state, setState] = useState({
        group: {},
        groupManagers: [],
        pictures: null, /*{ url: null, file: null, id: null, name: null }*/
        representativePicture: null, // 현재 사용자가 선택한 대표 사진 정보
        emblemUrl: null,
    });
    const [errors, setErrors] = useState({ owner: '', research: '', link: '', about: '', files: '' });
    const deletedFilesKey = useRef([]);

    useEffect(() => {

        const getContents = async () => {
            try {
                const groupInfo = await Api.getGroupItem({ _id: props._id });
                const managers = await Api.getGroupManagers({ _id: props._id });

                if (!isMount.current) return;
                const pictures = groupInfo.pictures.length ? groupInfo.pictures.reduce((acc, cur) => {
                    if (!cur.url) return acc; // exception handling
                    if (cur) acc.push({ url: cur?.url, file: { name: cur.name, size: cur.size }, key: cur.key });
                    return acc;
                }, []) : null;
                setState((prev) => ({
                    ...prev,
                    group: groupInfo,
                    groupManagers: managers,
                    pictures: pictures,
                    representativePicture: groupInfo.representative_picture,
                    emblemUrl: groupInfo.emblem_picture ? groupInfo.emblem_picture?.url : null,
                }));
            } catch (e) {
                dgLogger.error(e)();
            }
        };
        getContents();
    }, [isMount, props._id]);

    useEffect(() => {
        if (!state.pictures) return;
        const files = document.getElementsByClassName("update-group-pictures")[0];
        const errorCollection = {};

        if (!state.pictures.length) {
            errorCollection.files = { state: false, message: t('15') };
            files.classList.add("invalid");
        }
        else {
            errorCollection.files = { state: false, message: "" };
            files.classList.remove("invalid");
        }

        setErrors((prev) => ({ ...prev, ...errorCollection }));
    }, [state.pictures, t]);

    const onDropPictureFiles = useCallback((acceptedFiles, fileRejections) => {
        // 이미지 파일 업로드 가능
        const [newFiles, , result] = common.validateOnDrop(
            acceptedFiles,
            fileRejections,
            state.pictures?.map(picture => picture.file) || [],
            MAX_FILES,
            MAX_SIZE,
            ["image/"]);
        if (!result.valid) {
            return setState(prev => ({...prev,
                popup: POPUP.ValidateFileField,
                popupTarget: { // files 및 size 는 0 이하의 값일 경우, modal (popup) 에 에러메세지가 출력되지 않음.
                    MAX_FILES: MAX_FILES,
                    MAX_SIZE: MAX_SIZE,
                    MIME_TYPE: (!result.mimeType) ? "image/*" : null
                }
            }));
        }

        const readFile = (files, pictures) => {
            if (files.length === 0) {
                return setState((prev) => ({ ...prev, pictures: state.pictures?.concat(pictures) || pictures, representativePicture: state.representativePicture || pictures[0] }));
            }
            const file = files.shift();
            const reader = new FileReader();
            reader.onloadend = () => {
                const newPictures = [...pictures, { url: reader.result, file: file, key: Math.random().toString(36).substr(2, 11) }];
                readFile(files, newPictures);
            };
            reader.readAsDataURL(file);
        };
        (newFiles && newFiles.length > 0) && readFile(newFiles, []);
    }, [state.pictures, state.representativePicture]);

    const validateFields = async () => {
        let flag = true;
        const errorCollection = {};

        const files = document.getElementsByClassName("update-group-pictures")[0];
        const owner = document.getElementById(ID.updateGroupOwner); // 대표 매니저
        const research = document.getElementById(ID.updateGroupResearch);
        const link = document.getElementById(ID.updateGroupRelLink);
        const about = document.getElementById(ID.updateGroupAbout);

        common.validateFileField(errorCollection, 'files', files, state.pictures) || (flag = false);
        common.validateSelectField(errorCollection, 'owner', owner) || (flag = false);
        common.validateInputField(errorCollection, 'research', research) || (flag = false);
        common.validateInputField(errorCollection, 'link', link) || (flag = false);
        common.validateInputField(errorCollection, 'about', about) || (flag = false);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const onSubmit = async () => {
        try {
            const owner = document.getElementById(ID.updateGroupOwner).value.trim(); // 대표 매니저
            const research = document.getElementById(ID.updateGroupResearch).value.trim();
            const link = document.getElementById(ID.updateGroupRelLink).value.trim();
            const about = document.getElementById(ID.updateGroupAbout).value.trim();

            const require_confirm = document.querySelector(`input[name="${ID.updateGroupRequireJoinConfirm}-radio"]:checked`);
            const require_join_confirm = require_confirm ? require_confirm.value.trim() : true; // fallback

            const emblem = document.getElementById(ID.emblem).files[0];
            const emblemPicture = emblem && (await Api.uploadFiles([emblem]))[0];
            emblem && state.group.emblem_picture && Api.deleteFiles([state.group.emblem_picture.key]); // 현재 새로 upload 한 file이 있고, 적용 중인 emblem이 있는 경우 기존 emblem 삭제

            // Note. 대표 이미지가 선택되는 가능성은 다음의 두가지 입니다.
            // 1. 사용자가 새로 올린 이미지를 대표 이미지로 선택한 경우
            // 2. 사용자가 기존에 있는 이미지를 대표 이미지로 선택한 경우
            // 새로 올린 이미지는 서버에 저장된 id 를 갖는 정보가 아니므로, 다음의 두 단계에 걸쳐 선택된 대표 이미지를 찾아야 합니다.
            // 새로 올린 이미지가 대표 이미지라면, 서버에 파일을 전송하여 반환받은 fs.file 의 id 값이 대표이미지(id) 가 되어야 합니다.
            // 따라서, 현재 대표이미지의 index 를 기억한 다음, 서버로부터 반환받은 리스트 중에서 기억했던 index 에 해당하는 id 를 사용합니다.
            // 기존에 있는 이미지가 대표이미지라면, 단순히 id 를 매칭시켜서 동일한값이 있는지 찾아보는것으로 충분합니다.

            let representativePictureIndex = -1;
            let representativePicture;
            const filesToUpload = state.pictures?.reduce((acc, cur, i) => {
                if (cur.file.path) {
                    acc.push(cur.file);
                    if (state.representativePicture.key === cur.key) representativePictureIndex = i;
                }
                return acc;
            }, []);
            if (deletedFilesKey.current.length) {
                Api.deleteFiles(deletedFilesKey.current);
                deletedFilesKey.current = [];
            }

            let uploadedPictureIndex = 0;
            const uploadedPictures = filesToUpload.length === 0 ? [] : await Api.uploadFiles(filesToUpload, true);
            const pictures = state.pictures?.reduce((acc, cur, index) => {
                if (cur.file.path) {
                    // 파일을(새로) 업로드한 경우, 반환받은 file info (url, name) 값을 acc 에 저장
                    const file = uploadedPictures[uploadedPictureIndex];
                    acc.push({ name: file.name, size: file.size, key: file.key, mime: file.mime, url: file.url });
                    if (index === representativePictureIndex) representativePicture = { name: file.name, size: file.size, key: file.key, mime: file.mime, url: file.url };
                    uploadedPictureIndex++;
                } else {
                    dgLogger.assert(cur.file)();
                    acc.push(state.group.pictures[index]);
                    if (state.representativePicture.key === cur.key) representativePicture = cur;
                }
                return acc;
            }, []);

            const payload = await Api.updateGroupItem({
                _id: props._id,
                pictures: pictures,
                representative_picture: representativePicture, // representativePictureID 가 undefined 라면, 갱신하지 않음.
                emblem_picture: emblemPicture,
                owner: owner,
                research: research,
                link: link,
                require_join_confirm: require_join_confirm,
                about: about,
            });

            setState({
                ...state,
                group: payload,
                pictures: payload.pictures.reduce((acc, cur) => {
                    acc.push({ url: cur?.url, file: { name: cur.name, size: cur.size }, key: cur.key });
                    return acc;
                }, []),
                emblemUrl: payload.emblem_picture ? payload.emblem_picture.url : null,
                representativePicture: payload.representative_picture
            });
        } catch (e) {
            dgLogger.error(e)();
        }
    };

    const popupUpdateGroupInfo = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_new.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_new@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_new@3x.png 3x`} alt=""/>;
        const header = <div>{t('454')}</div>;
        const body = <div>{t('455')}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={async () => {
                    await onSubmit();
                    setState((prev) => ({ ...prev, popup: POPUP.Requested, popupTarget: null }));
                }}
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupUpdatedGroupInfo = () => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_group_new.png`} srcSet={`${process.env.PUBLIC_URL}/pop_group_new@2x.png 2x, ${process.env.PUBLIC_URL}/pop_group_new@3x.png 3x`} alt=""/>;
        const header = <div>{t('454')}</div>;
        const body = <div>{t('541')}</div>;

        return (
            <Modal
                onRequestClose={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={async () => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.Request) return popupUpdateGroupInfo();
        if (state.popup === POPUP.Requested) return popupUpdatedGroupInfo();
        if (state.popup === POPUP.ValidateFileField) {
            const target = state.popupTarget;
            if (target.MIME_TYPE) {
                return (
                    <PopupValidateImage
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                    />
                );
            } else {
                return (
                    <PopupValidateFile
                        maxFiles={target.MAX_FILES}
                        maxSizeInMB={(target.MAX_SIZE) / (1024 * 1024)}
                        onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null }) }
                    />
                );
            }
        }
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    return (
        <div id="update-group-info">
            {popups()}
            <Row className="gx-0">
                <Col className="col-auto emblem-wrap"><span className="pr-151px"/></Col> {/* empty space to align */}
                <Col className="notosanskr-600 font-size-26 c-333 text-start update-group-picture-title">{t('460')}</Col>
            </Row>
            <Row className="gx-0 mb-58px">
                <Col className="col-auto mb-auto emblem-wrap">
                    <ProfileEmblem
                        width={"151px"}
                        height={"151px"}
                        url={state.emblemUrl}
                        urlClass={"avatar"}
                        char={state.group?.name}
                        charClass={"avatar avatar-151 bg-e8ebf0"}
                        profileId={ID.emblem}
                        accept="image/*"
                        onChange={(e) => {
                            // 이미지 파일 업로드 가능
                            const [, , result] = common.validateOnDrop([e.target.files[0]], [], [], null, MAX_SIZE, ["image/"]);
                            if (!result.valid) {
                                return setState(prev => ({...prev, popup: POPUP.ValidateFileField,
                                    popupTarget: {
                                        MAX_FILES: MAX_FILES,
                                        MAX_SIZE: MAX_SIZE,
                                        MIME_TYPE: (!result.mimeType) ? "image/*" : null
                                    }
                                }));
                            } else {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setState({ ...state, emblemUrl: reader.result });
                                };
                                reader.readAsDataURL(e.target.files[0]);
                            }
                        }}
                    />
                </Col>
                <Col className="col-auto mr-12px">
                    <Row id={ID.pictures} className="gx-0">
                        <Col className="upload-zone col-auto">
                            <ImageDropZone
                                accept={"image/*"}
                                droppableText=""
                                droppingText=""
                                disabled={state.pictures?.length >= MAX_FILES}
                                maxFiles={MAX_FILES}
                                imgId={ID.pictureDrop}
                                inputFileId={ID.picturesInput}
                                dropzoneClassName="update-group-pictures"
                                ref={onDropPictureFiles}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="image-wrap">
                    <Row>
                        {state.pictures?.map((picture) => (
                            <Col key={picture.key} className="upload-images col-auto gx-0 mb-13px">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{picture.file?.name}</Tooltip>}>
                                    <div className="each-image">
                                        <div className="upload-image-wrap">
                                            {picture.key === (state.representativePicture?.key) && (
                                                <button disabled className="representative notosanskr-400 font-size-14 c-white">
                                                    {t('149')}
                                                </button>
                                            )}
                                            <button
                                                className="remove-picture c-white"
                                                onClick={() => {
                                                    // url이 https로 시작하면 cloud에 삽입 된 image로 판단하여 삭제 할 list에 추가
                                                    picture.url.startsWith("https") && deletedFilesKey.current.push(picture.key);
                                                    const pictures = state.pictures?.reduce((acc, cur) => {
                                                        dgLogger.assert(cur.file && picture.file)();
                                                        if (cur.file !== picture.file) acc.push(cur);
                                                        return acc;
                                                    }, []);

                                                    const representative_picture = pictures.find((picture) => picture.file === state.representativePicture.file) || (pictures.length && pictures[0]);
                                                    setState({ ...state, pictures: pictures, representativePicture: representative_picture });
                                                }}
                                            />
                                            <img src={picture.url} alt="Representative"
                                                onClick={() => setState({ ...state, representativePicture: picture })} />
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row className="gx-0 update-group-info-title">
                <Col className="notosanskr-600 font-size-26 c-333">
                    <Trans i18nKey={'214'} components={{tag: <span></span>}}/>
                </Col>
            </Row>

            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t('370')}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14"><InputText key={common.i18nGroupName(state.group?.name)} disabled={true} defaultValue={common.i18nGroupName(state.group?.name)} placeholder={t('370')} className="w-75"/></Col>
            </Row>

            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t('461')}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14">
                    <select id={ID.updateGroupOwner} name="groupManager" defaultValue={state.group?.owner} disabled={state.groupManagers?.length <= 1} onChange={(e) => isValid([e.target])} className="w-75">
                        {state.groupManagers?.length > 1 && (
                            <option value="" disabled>
                                {t('542')}
                            </option>
                        )}
                        {state.groupManagers?.map((manager) => (
                            <option key={manager._id} value={manager._id}>
                                {manager.name}
                            </option>
                        ))}
                    </select>
                    {errors?.owner?.message && <div className="notosanskr-17 c-f00 mt-10px">{errors?.owner?.message}</div>}
                </Col>
            </Row>

            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t('232')}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14">
                    <InputText key={state.group?.research} id={ID.updateGroupResearch} name="research" className="w-75" defaultValue={state.group?.research} onBlur={(e) => isValid([e.target])} />
                    {errors?.research?.message && <div className="notosanskr-17 c-f00 mt-10px">{errors?.research?.message}</div>}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>{t('231')}</Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14">
                    <InputText key={state.group?.rel_link} id={ID.updateGroupRelLink} name="link" className="w-75" defaultValue={state.group?.rel_link} onBlur={(e) => isValid([e.target])} />
                    {errors?.link?.message && <div className="notosanskr-17 c-f00 mt-10px">{errors?.link?.message}</div>}
                </Col>
            </Row>

            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            {t('390').split('\n').map((v, i) => <div key={i}>{v}</div>)}
                        </Col>
                    </Row>
                </Col>
                <Col as="div" id={ID.updateGroupRequireJoinConfirm} className="item permission align-items-center d-flex">
                    {state.group &&
                    <>
                        <div className="radio-wrap">
                            <input
                                type="radio"
                                id={`${ID.updateGroupRequireJoinConfirm}-required`}
                                name={`${ID.updateGroupRequireJoinConfirm}-radio`}
                                value={true}
                                defaultChecked={state.group.require_join_confirm}
                            />
                            <label htmlFor={`${ID.updateGroupRequireJoinConfirm}-required`} className="notosanskr-400 font-size-14 c-333">
                                {t('391')}
                            </label>
                        </div>
                        <div className="radio-wrap">
                            <input
                                type="radio"
                                id={`${ID.updateGroupRequireJoinConfirm}-pass`}
                                name={`${ID.updateGroupRequireJoinConfirm}-radio`}
                                value={false}
                                defaultChecked={!state.group.require_join_confirm}
                            />
                            <label htmlFor={`${ID.updateGroupRequireJoinConfirm}-pass`} className="notosanskr-400 font-size-14 c-333">
                                {t('392')}
                            </label>
                        </div>
                    </>}
                </Col>
            </Row>
            <Row className="item-row gx-0">
                <Col className="col-auto item-title notosanskr-500 font-size-17 c-000">
                    <Row className="gx-0 align-items-center h-100">
                        <Col>
                            <Trans i18nKey={'214'} components={{tag: <span></span>}}/>
                        </Col>
                    </Row>
                </Col>
                <Col className="item notosanskr-400 font-size-14">
                    <textarea
                        id={ID.updateGroupAbout}
                        name="about"
                        defaultValue={state.group?.about}
                        className="w-100"
                        onBlur={(e) => isValid([e.target])}
                        placeholder={t('393')}
                        maxLength={50}
                    ></textarea>
                    {errors?.about?.message && <div className="notosanskr-400 font-size-14 c-f00">{errors?.about?.message}</div>}
                </Col>
            </Row>

            <Row className="confirm-btn-group" style={{ textAlign: "right" }}>
                <Col>
                    <button
                        id={`update-group-info-confirm`}
                        className="save-btn notosanskr-400 font-size-18 c-white"
                        onClick={async () => {
                            if (!(await validateFields())) {
                                common.scrollToInvalidElement();
                                return;
                            }
                            setState({ ...state, popup: POPUP.Request, popupTarget: null });
                        }}
                    >
                        {t('265')}
                    </button>
                </Col>
            </Row>
        </div>
    );
}
