import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { InputText } from "../Input";
import CustomCheckbox from "./CustomCheckbox";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { strings } from "@ocean-knight/shared";
import { I18NTYPE } from "@ocean-knight/shared/src/utility";

const RadioCheckboxForm = (props /* sub_items */) => {
    const { t } = useTranslation();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // radiobox 와 checkbox item의 공통 된 form
    const createSubFormItem = ({ type = "string" /*기타의 경우 custom_string*/, name = "", _id = undefined }) => {
        return ({
            sub_item_type: type,
            name: name,
            _id: _id || `__${nanoid(32)}`,
        });
    };
    const otherOptionAvailable = useRef(true);

    useEffect(() => {
        if (!props.sub_items.length) {
            props.sub_items.push(createSubFormItem({ name: strings[props.dispLang]['551'] }));
            otherOptionAvailable.current = true;
            forceUpdate();
        }
        else {
            otherOptionAvailable.current = !(props.sub_items.find(item => item.sub_item_type === "custom_string"));
            forceUpdate();
        }
    }, [props.sub_items, forceUpdate, props.dispLang]);

    const addSubFormItem = ({ type = "string", name = "" }) => {
        if (type === "custom_string") otherOptionAvailable.current = false;
        props.sub_items.push(createSubFormItem({ type: type, name: name }));
        forceUpdate();
    };

    const removeSubFormItem = (index) => {
        const item = props.sub_items.splice(index, 1);
        // 삭제시, i18n 항목과 index 가 틀어질 수 있으므로, i18n 의 항목도 삭제 처리
        Object.keys(I18NTYPE).forEach(lang => props.i18n?.[lang] && props.i18n[lang].sub_items?.splice(index, 1));
        if (item[0].sub_item_type === "custom_string") otherOptionAvailable.current = true;
        forceUpdate();
    };

    return (
        <>
            {props.sub_items.map((form, index) => {
                return (
                    <Row className="gx-0" key={`${form._id}-${index}`}>
                        <Col className="col-auto">
                            <CustomCheckbox
                                type={props.type}
                                disabled={true}
                            >
                                <InputText
                                    key={props.dispLang}
                                    defaultValue={(form.sub_item_type === "custom_string") ? strings[props.dispLang]['163'] : form.name}
                                    name={`${props.index}_${index}`}
                                    onBlur={(e) => {
                                        props.isValid([e.target]);
                                        form.name = e.target.value;
                                    }}
                                    disabled={form.sub_item_type === "custom_string"}
                                    className={`${props.errors[`${props.index}_${index}`]?.message ? "invalid" : ""}`}
                                />
                            </CustomCheckbox>
                        </Col>
                        {index > 0 &&
                            <Col className="remove-button">
                                <img
                                    src={process.env.PUBLIC_URL + `/plus.png`}
                                    onClick={() => removeSubFormItem(index)}
                                    alt=""
                                />
                            </Col>
                        }
                        <div className="c-f00 mt-5px errors">{props.errors[`${props.index}_${index}`]?.message}</div>
                    </Row>
                );
            })}
            <Row className="gx-0">
                <Col>
                    <CustomCheckbox
                        type={props.type}
                        disabled={true}
                    >
                        <span className="option" onClick={() => addSubFormItem({ name: strings[props.dispLang]['544'].replace("#", props.sub_items.length + 1) })}>
                            {t('545')}
                        </span>
                        {otherOptionAvailable.current &&
                            <>
                                <span>
                                    {t('546')}
                                </span>
                                {/* custom_string 은 한글로 저장 처리. 화면에 render 할때만 i18n 처리 */}
                                <span className="other-option" onClick={() => addSubFormItem({ type: "custom_string", name: strings.ko['163'] })}>
                                    {t('547')}
                                </span>
                            </>
                        }
                    </CustomCheckbox>
                </Col>
            </Row>
        </>
    );
};

/**
 * @param {int} maxCount option의 개수
 * @param {int} max_item_count option의 default value
 * @param {function} onChange select tag의 onChange에서 사용 될 function
 * @param {Object} form 현재 form item
 * @returns select jsx
 */
const MaxCountRangeOption = (maxCount, max_item_count, form, onChange) => {
    const { t } = useTranslation();
    const maxCounts = [];
    for (let i = 1; i <= maxCount; i++) {
        maxCounts.push(i);
    }
    return (
        <Row className="gx-0">
            <Col className="col-auto">
            <select
                onChange={(e) => onChange(e)}
                defaultValue={`${max_item_count}`}
            >
                {maxCounts.map(maxCount => (
                    <option key={maxCount} value={maxCount}>
                        {maxCount}
                    </option>
                ))}
            </select>
            </Col>
            <Col className="col-auto">
            <CustomCheckbox
                type="checkbox"
                disabled={form.essential}
                onChange={(e) => {
                    form.fields.allow_empty_item = e.target.checked;
                }}
                defaultChecked={form.fields.allow_empty_item}
            >
                {t('548')} {/* 부분 입력 */}
            </CustomCheckbox>
            </Col>
        </Row>
    );
};

const FileForm = (props /* form */) => {
    const { t } = useTranslation();
    const changeFields = useCallback(({ drag_and_drop_description = t('97'), button_text = t('98'), max_item_count = 1, min_item_count = 0 }) => {
        props.form.fields.hasOwnProperty('drag_and_drop_description') || (props.form.fields.drag_and_drop_description = drag_and_drop_description);
        props.form.fields.hasOwnProperty('button_text') || (props.form.fields.button_text = button_text);
        props.form.fields.hasOwnProperty('max_item_count') || (props.form.fields.max_item_count = max_item_count);
        props.form.fields.hasOwnProperty('min_item_count') || (props.form.fields.min_item_count = min_item_count);
        props.form.fields.hasOwnProperty('allow_empty_item') || (props.form.fields.allow_empty_item = false);
    }, [props.form.fields, t]);

    useEffect(() => {
        changeFields({});
    }, [changeFields]);

    return (
        MaxCountRangeOption(props.maxCount, props.form.fields.max_item_count, props.form, (e) => props.form.fields.max_item_count = parseInt(e.target.value))
    );
};

const VendorUIForm = (props /* form */) => {
    // sub_item의 하위 items array는 back-end에서 추가 ( 현재는 동일한 array가 삽입 되기 때문 )
    const createSubFormItem = useCallback(({ type = "textbox+file", name = "" }) => {
        return ({
            sub_item_type: type,
            name: name,
        });
    }, []);

    const createSubItems = useCallback((lang = "ko") => {
        // const tmp = [...props.form.sub_items];
        const tmp = [];
        for (let i = 0; i <= props.maxCount; i++) {
            tmp.push(createSubFormItem({ name: `${strings[lang]['722']}${i} : ` }));
        }
        return tmp;
    }, [props.maxCount, createSubFormItem]);

    const subItmes = useRef({
        ko: createSubItems("ko"),
        en: createSubItems("en")
    });

    useEffect(() => {
        if (!props.form.sub_items.length) {
            props.form.sub_items = subItmes.current.ko.slice(0, 1);
            props.form.i18n.ko.sub_items = subItmes.current.ko.slice(0, 1);
            props.form.i18n.en.sub_items = subItmes.current.en.slice(0, 1);
        }
        props.form.fields.hasOwnProperty('max_item_count') || (props.form.fields.max_item_count = props.form.sub_items.length);
        props.form.fields.hasOwnProperty('min_item_count') || (props.form.fields.min_item_count = 0);
        props.form.fields.hasOwnProperty('allow_empty_item') || (props.form.fields.allow_empty_item = false);
    }, [props.form]);

    return (
        MaxCountRangeOption(props.maxCount, props.form.sub_items.length, props.form,
            (e) => {
                props.form.sub_items = subItmes.current.ko.slice(0, e.target.value);
                props.form.i18n.ko.sub_items = subItmes.current.ko.slice(0, e.target.value);
                props.form.i18n.en.sub_items = subItmes.current.en.slice(0, e.target.value);
                props.form.fields.max_item_count = parseInt(e.target.value);
            }
        )
    );
};

const SpinnerBoxForm = (props /* form.fields */) => {
    const { t } = useTranslation();

    return (
        <Row className="gx-0" style={{ marginBottom: "0" }}>
            <Col className="col-auto">
                <InputText disabled={true} />
            </Col>
            <Col className="col-auto unit">
                <InputText
                    key={props.dispLang}
                    placeholder={t('550')}
                    defaultValue={props.fields?.unit}
                    onBlur={(e) => {
                        props.fields.unit = e.target.value;
                    }}
                />
            </Col>
        </Row>
    );
};

const VendorUI3Form = (props /* form */) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const { t } = useTranslation();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        props.form.fields.hasOwnProperty('max_item_count') || (props.form.fields.max_item_count = 1);
        props.form.fields.hasOwnProperty('min_item_count') || (props.form.fields.min_item_count = 0);
        props.form.fields.hasOwnProperty('allow_empty_item') || (props.form.fields.allow_empty_item = false);

        setSelectedModel(props.models.find(v => v._id == props.form.fields.object_detection_model));
    }, [props.form.fields, props.form.sub_items, props.form.fields.object_detection_model, props.models]);

    useEffect(() => {
        if (!props.form.sub_items.length) {
            props.form.sub_items.push(createSubFormItem({ question: strings[props.dispLang]['551'] }));
            forceUpdate();
        }
        else {
            forceUpdate();
        }
    }, [props.form.sub_items, forceUpdate, props.dispLang]);

    const createSubFormItem = ({ type = "string" /*기타의 경우 custom_string*/, question = "", class_number = null, _id = undefined }) => {
        return ({
            sub_item_type: type,
            question: question,
            ...(class_number != null && { class_number: class_number }), // class_number 값이 없다면, key 를 생성하지 않음.
            _id: _id || `__${nanoid(32)}`,
        });
    };

    const addSubFormItem = ({ type = "string", question = "" }) => {
        props.form.sub_items.push(createSubFormItem({ type: type, question: question }));
        forceUpdate();
    };

    const removeSubFormItem = (index) => {
        props.form.sub_items.splice(index, 1);
        forceUpdate();
    };

    return (
        <>
            <div className="notosanskr-600 font-size-20 notosanskr-14:sm c-333" style={{ margin: "1rem 0" }}>{t('552')}</div>
            {MaxCountRangeOption(props.maxCount, props.form.fields.max_item_count, props.form, (e) => props.form.fields.max_item_count = parseInt(e.target.value))}
            <Row className="gx-0">
                <Col className="col-auto">
                    <div className="notosanskr-600 font-size-20 notosanskr-14:sm c-333" style={{ padding: "1.5rem 0 1rem" }}>
                        {t('516')}
                    </div>
                </Col>
                <Col className="col-3">
                    <div className="notosanskr-600 font-size-20 notosanskr-14:sm c-333" style={{ padding: "1.5rem 0 1rem" }}>
                        {t('645')}
                    </div>
                </Col>
                <Col className="col-auto"></Col>
            </Row>
            {props.form.sub_items.map((subItem, index) => {
                return (
                    <Row className="gx-0" key={`${subItem._id}-${index}`}>
                        <Col className="col-auto">
                            <InputText
                                defaultValue={subItem.question}
                                name={`${props.index}_${index}`}
                                onBlur={(e) => {
                                    props.isValid([e.target]);
                                    subItem.question = e.target.value;
                                }}
                                disabled={subItem.sub_item_type === "custom_string"}
                                className={`${props.errors[`${props.index}_${index}`]?.message ? "invalid" : ""}`}
                                maxLength={30}
                            />
                        </Col>
                        <Col className="col-3 select">
                            <select
                                className="object-detection-model-select"
                                onChange={(e) => {
                                    const class_number = selectedModel?.classes.findIndex(v => v == e.target.value);
                                    props.form.sub_items.forEach(v => {
                                        if (v.class_number == class_number) {
                                            delete v.class_number;
                                        }
                                    });
                                    subItem.class_number = class_number;

                                    forceUpdate();
                                }}
                            >
                                <option value="">Not set</option>
                                {selectedModel?.classes.map((v, i) => (
                                    <option key={v} value={`${v}`} selected={i == subItem.class_number}>
                                        {v}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col className="col-auto remove-button">{index > 0 && <img src={process.env.PUBLIC_URL + `/plus.png`} onClick={() => removeSubFormItem(index)} alt="" />}</Col>
                        <div className="c-f00 mt-5px errors" style={{ marginLeft: 0 }}>
                            {props.errors[`${props.index}_${index}`]?.message}
                        </div>
                    </Row>
                );
            })}
            <Row className="gx-0">
                <Col className="d-flex">
                    <span>
                        <span className="option" onClick={() => addSubFormItem({ question: strings[props.dispLang]['544'].replace("#", props.form.sub_items.length + 1) })}>
                        {t('545')}
                        </span>
                    </span>
                </Col>
            </Row>
        </>
    );
};

const VendorUI4Form = (props /* form */) => {
    const { t } = useTranslation();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const createSubFormItem = ({ type = "string", name = "", _id = undefined }) => {
        return {
            sub_item_type: type,
            name: name,
            _id: _id || `__${nanoid(32)}`,
        };
    };

    useEffect(() => {
        if (!props.sub_items.length) {
            props.sub_items.push(createSubFormItem({ name: strings[props.dispLang]['551'] }));
            forceUpdate();
        }
    }, [props.sub_items, forceUpdate, props.dispLang]);

    const addSubFormItem = ({ type = "string", name = "" }) => {
        props.sub_items.push(createSubFormItem({ type: type, name: name }));
        forceUpdate();
    };

    const removeSubFormItem = (index) => {
        const item = props.sub_items.splice(index, 1);
        forceUpdate();
    };

    return (
        <>
            {props.sub_items.map((subItem, index) => {
                return (
                    <Row className="gx-0" key={`${subItem._id || subItem.index}-${index}`}>
                        <Col className="col-auto">
                            <InputText
                                key={props.dispLang}
                                defaultValue={subItem.name}
                                placeholder={`${strings[props.dispLang]['544'].replace('#', index + 1)}`}
                                name={`${props.index}_${index}`}
                                onBlur={(e) => {
                                    props.isValid([e.target]);
                                    subItem.name = e.target.value;
                                }}
                                disabled={subItem.sub_item_type === "custom_string"}
                                className={`${props.errors[`${props.index}_${index}`]?.message ? "invalid" : ""}`}
                            />
                        </Col>
                        {index > 0 && (
                            <Col className="remove-button">
                                <img src={process.env.PUBLIC_URL + `/plus.png`} onClick={() => removeSubFormItem(index)} alt="" />
                            </Col>
                        )}
                        <div className="c-f00 mt-5px">{props.errors[`${props.index}_${index}`]?.message}</div>
                    </Row>
                );
            })}
            <Row className="gx-0">
                <Col>
                    <span className="option" onClick={() => addSubFormItem({ name:  strings[props.dispLang]['544'].replace("#", props.sub_items.length + 1) })}>
                    {t('545')}
                    </span>
                </Col>
            </Row>
        </>
    );
};

export {
    RadioCheckboxForm,
    FileForm,
    VendorUIForm,
    SpinnerBoxForm,
    VendorUI3Form,
    VendorUI4Form,
};
