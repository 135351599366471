import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { InputText } from "../Input";
import { useTranslation } from "react-i18next";

export default function Faq(){
    const { t } = useTranslation();
    const [state, setState] = useState({filter: "", data: []});

    /**
     * 임시로 state에 data를 저장, 추후 디자인, 기능 확정이 되면 api 호출하여 저장하는 방식으로 수정 예정
     */
    useEffect(() => {
        document.getElementById("App").className = "more";
        setState((prev) =>
            ({...prev,
                data: [
                    {
                        question: "enji는 왜 해결사인가요?",
                        answer: "모르는 것이 없기 때문입니다.",
                    },
                    {
                        question: "leo는 왜 개발 천재인가요?",
                        answer: "말하면 입만 아픕니다.",
                    },
                    {
                        question: "질문3",
                        answer: "답변3",
                    }
                ]
            })
        );
    }, []);

    /**
     * 출력 될 faq 내용을 table로 만들어주는 함수, 추후 다자인, 기능 확정이 되면 수정 예정
     *
     * @returns html tag
     */
    const showFaq = () => {
        return (state.data
            .filter(data => data.question.indexOf(state.filter) !== -1 || data.answer.indexOf(state.filter) !== -1)
            .map((data, index) => {
                return (
                    <tr key={index}>
                        <td>{data.question}</td>
                        <td>{data.answer}</td>
                    </tr>
                );
        }));
    };

    /**
     * 출력 될 faq 항목을 검색하는 함수, 추후 디자인, 기능 확정이 되면 수정이 될 수 있음
     */
    const searchFaq = (e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            const filter = document.getElementById('search').value;
            setState({
                ...state, filter: filter
            });
        }
    };

    return (
        <Container>
            <div className="more-sub-title">
                <span className="adobe-gothic-std-75 adobe-gothic-std-40:sm c-fff more">FAQ</span>
                <span className="notosanskr-24b notosanskr-14b:sm c-fff">{t('662')}</span>
            </div>
        <div style={{
            border: "solid 1px",
            paddingBottom: "1rem"
        }}>
            <InputText id="search" onKeyPress={searchFaq} />
            <button onClick={searchFaq}>{t('70')}</button>
            <table style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <thead>
                    <tr>
                        <th>{t('663')}</th>
                        <th>{t('664')}</th>
                    </tr>
                </thead>
                <tbody>
                        {showFaq()}
                </tbody>
            </table>
        </div>
        </Container>
    );
}
