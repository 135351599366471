/**
 * Issue #1972: 방문자 수 조회 기능 (Google Analytics)
 * Google Analytics 관련 함수 등 구현
 * 참고자료
 * - https://firebase.google.com/docs/web/setup?hl=ko#available-libraries
 * - https://firebase.google.com/docs/analytics/get-started?platform=web&authuser=4&hl=ko
 */

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as defaultLogEvent, setDefaultEventParameters } from 'firebase/analytics';
import dgLogger from './dgLogger';

// Initialization
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// default event parameters
const defaultEventParameters = {
    page_title: window.location.hostname   // page_title에 url hostname 대입: 도메인별 통계 분리 (#1972)
};

let analytics = null;

export default function init() {
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    setDefaultEventParameters(defaultEventParameters);

    dgLogger.info('Google Analytics has been initialized.')();
}

/**
 * Google Analytics에 로깅하는 function
 * @public
 * List of recommended event parameters can be found in [the GA4 reference documentation](https://developers.google.com/gtagjs/reference/ga4-events).
 * @example
 * ```jsx
 * import { logEvent } from './common/analytics';
 * function App() {
 *     logEvent('select_content', {
 *         content_type: 'image',
 *         content_id: 'P12453'
 *     })
 * };
 * ```
 */
export function logEvent(eventName, eventParams, options) {
    if (analytics === null) throw new Error('logEvent is called before init');

    dgLogger.debug('GA-LogEvent', {
        eventName: eventName || '',
        eventParams: eventParams || '',
        options: options || ''
    })();
    defaultLogEvent(analytics, eventName, eventParams, options);
}
