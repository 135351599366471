import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import WithTextHandle from "../hoc/WithTextHandle";
import useTextBoxHook from './TextBoxHook';
import TextBoxView from "./TextBoxView";
import common from '../../../common/common';

const TextBox = forwardRef(({ defaultValue, type, fields, i18n }, ref) => {
    const { title, description, required } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const {
        textValidation,
        setTextBoxValue,
        getTextBoxValue,
    } = useTextBoxHook(ref);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        setTextBoxValue(defaultValue);
    }, [defaultValue, setTextBoxValue]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const value = getTextBoxValue();
        const { valid, errorCollection } = textValidation({ value, required, errorKey: type });
        setErrors(errorCollection);

        return valid;
    }, [type, required, getTextBoxValue, textValidation]);

    // 현재 element에서 focus가 없어졌을 때 호출 되는 handler
    // 현재 form에 알맞은 validation 실행 후 값과 error 상태 수정
    const onBlurHandle = useCallback((e) => {
        setTextBoxValue(e.target.value);
        validation();
    }, [validation, setTextBoxValue]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        defaultValue,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        onBlurHandle
    };

    return (
        <TextBoxView {...viewProps} />
    );
});

export default WithTextHandle(TextBox);
