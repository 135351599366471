import React, { useState } from "react";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PasswordConfirmForm from "../common/PasswordConfirmForm";
import GeneralErrorWindow from '../common/GeneralErrorWindow';
import { Container } from "react-bootstrap";
import "./ResetPassword.css";
import Modal from "../common/Modal";
import common from "../../common/common";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
    const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [reset, setReset] = useState(false);
	const [mail, ] = useState({
		state: location.state ? location.state.state : undefined,
		address: location.state ? location.state.address : undefined,
		code: location.state ? location.state.code : undefined,
	});
	const [errors, setErrors] = useState({ password: '', passwordConfirm: '' });

	const confirmPassword = (e) => {
		const password = document.getElementById("reset-pass-password");
		const passwordConfirm = document.getElementById("reset-pass-password-confirm");

		if (!isValid([password, passwordConfirm])) {
			common.scrollToInvalidElement();
			return;
		}

		const payload = {
			email: mail.address,
			password: password.value,
			code: mail.code,
		};

		Api.resetPass(payload)
			.then(() => {
				setReset(true);
			})
			.catch((err) => {
				dgLogger.error(err)();
				navigate("/", { replace: true });
			});
	};

	const popupConfirmPassword = () => {
		if (reset) {
			const icon = <img src={process.env.PUBLIC_URL + `/pop_password_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_password_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_password_complete@3x.png 3x`} alt="" />;
			const header = <div>{t('307')}</div>;
			const body = <div>{t('308').split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

			return (
				<Modal
					onRequestClose={() => navigate("/Login/SignIn", { replace: true })}
					onConfirm={() => navigate("/Login/SignIn", { replace: true })}
					icon={icon}
					header={header}
					body={body}
				/>
			);
		}
	};

	const isValid = (elements) => {
		const [flag, errorCollection] = common.isValid(elements);

		setErrors({ ...errors, ...errorCollection });
		return flag;
	};

	return (
		<Container>
			{!location.state ?
				<GeneralErrorWindow
					onClick={() => {
						navigate("/");
					}}
				/>
				:
				<>
					<div className="login-sub-title">
						<span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Find</span>
						<span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('304')}</span>
					</div>
					<div className="reset-password-page">
						<div className="reset-password-text notosanskr-500 font-size-30">{t('304')}</div>
						<PasswordConfirmForm passwordId={"reset-pass-password"} confirmId={"reset-pass-password-confirm"} isValid={isValid} errors={errors} font="notosanskr-400 font-size-17" className="notosanskr-400 font-size-17" />
						<div style={{ textAlign: "right" }}>
							<button className="rest-password-confirm notosanskr-400 font-size-18" onClick={() => confirmPassword()}>{t('79')}</button>
						</div>
					</div>
					{popupConfirmPassword()}
				</>
			}
		</Container>
	);
}
