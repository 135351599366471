import "./CreateReport.css";

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import SelectWrapper from "./common/SelectWrapper";
import CustomButtonLink from "../common/CustomButtonLink";

import Title from "./Title/Title";
import TextBox from "./TextBox/TextBox";
import SpinnerBox from "./SpinnerBox/SpinnerBox";
import Textarea from "./Textarea/Textarea";
import Tag from "./Tag/Tag";
import File from "./File/File";
import VendorUI2 from "./VendorUI2/VendorUI2";
import VendorUI3 from "./VendorUI3/VendorUI3";
import VendorUI4 from "./VendorUI4/VendorUI4";
import Typeahead from "./Typeahead/Typeahead";
import Datetime from "./CustomDate/CustomDate";
import TimeRange from "./TimeRange/TimeRange";
import Radio from "./Radio/Radio";
import Checkbox from "./Checkbox/Checkbox";
import Location from "./Location/Location";
import { Trans, useTranslation } from "react-i18next";
import common from "../../common/common";

const componentMap = {
    title: Title,
    textbox: TextBox,
    spinnerbox: SpinnerBox,
    ml_textbox: Textarea,
    tag: Tag,
    file: File,
    "vendor-ui-2": VendorUI2,
    "vendor-ui-3": VendorUI3,
    "vendor-ui-4": VendorUI4,
    typeahead: Typeahead,
    date: Datetime,
    "time_range": TimeRange,
    radiobox: Radio,
    checkbox: Checkbox,
    location: Location,
};

const GenerateReportFromItemList = ({ childRefList, reportFormItemList, previousData, reportFormId, currentUserInfo }) => {
    const getItemResult = (id) => {
        const itemResult = previousData?.report_form_item_result?.find(result => result.report_form_item_id === id);
        return itemResult;
    };

    const getDefaultValue = (id) => {
        const itemResult = getItemResult(id);
        return itemResult?.value || "";
    };

    const getRepresentativePicture = (id) => {
        const itemResult = getItemResult(id);
        return itemResult?.representative_picture || null;
    };

    return (
        <>
            {reportFormItemList.map((item, index) => {
                const Component = componentMap[item.type];
                if (Component) {
                    return (
                        <Component
                            key={item._id + reportFormId.current}
                            ref={childRefList.current[index]}
                            defaultValue={getDefaultValue(item._id)}
                            defaultRepresentativePicture={getRepresentativePicture(item._id)}
                            currentUserInfo={currentUserInfo}
                            {...item}
                        />
                    );
                } else {
                    return <React.Fragment key={item._id} />;
                }
            })}
        </>
    );
};

export default function CreateReportView({
    progressbarRef,
    isLoading,
    groupList,
    projectList,
    reportFormItemList,
    childRefList,
    previousData,
    tempReport,
    groupSelectDisabled,
    projectSelectDisabled,
    reportFormId,
    currentUserInfo,
    isModifying,
    groupSelectOnChange,
    projectSelectOnChange,
    submitOnClick,
    tempReportSaveOnClick,
    tempReportRemoveOnClick,
    Popups
}) {
    const { t } = useTranslation();
    return (
        <Container className="create-report">
            <LoadingSpinner isOpen={isLoading} ref={progressbarRef} />;
            <Popups />
            <div>
                <span className="create-report-sub-title">
                    <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff report">Data</span>
                    <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t('144')}</span>
                </span>
            </div>
            <Row className="gx-0 report-form-main-title">
                <Col className="col-sm-auto report-form-main-title-text notosanskr-600 font-size-40 notosanskr-20b:sm">
                    {t('144')}
                </Col>
                <Col className="col-auto">
                    <CustomButtonLink
                        to="/Mypage/RequestGroupMember"
                        className="report-form-main-title-button notosanskr-400 font-size-16"
                    >
                        <span>
                            {t('145')}
                            <img src={`${process.env.PUBLIC_URL}/arrow@3x.png`} alt="" />
                        </span>
                    </CustomButtonLink>
                </Col>
            </Row>
            <Row className="gx-0 form-list notosanskr-400 font-size-14">
                <Col className="item-title notosanskr-500 font-size-17">{t('38')}</Col>
                <Col className="item-half-content group">
                    <SelectWrapper
                    key={groupList}
                    onChange={groupSelectOnChange}
                    list={groupList.map((v) => ({...v, name: common.i18nGroupName(v.name)}))}
                    placeholder={""}
                    disabled={groupSelectDisabled}
                    />
                </Col>

                <Col className="item-title notosanskr-500 font-size-17">{t('3')}</Col>
                <Col className="item-half-content project">
                    <SelectWrapper key={projectList} onChange={projectSelectOnChange} list={projectList} disabled={projectSelectDisabled} />
                </Col>
                <GenerateReportFromItemList
                    childRefList={childRefList}
                    reportFormItemList={reportFormItemList}
                    previousData={previousData}
                    reportFormId={reportFormId}
                    currentUserInfo={currentUserInfo}
                />
            </Row>
            <Row className="gx-0 safety-report notosanskr-400 font-size-14">
                <span><Trans i18nKey={'538'} components={{tag: <a href={t('643')} target="_blank" rel="noreferrer"/>}} /></span>
            </Row>
            {reportFormItemList.length > 0 &&
                <Row className="gx-0 report-form-submit-wrap">
                    {tempReport &&
                        <Col className="col-sm-6 col-6 col-md-auto report-form-delete-button-order">
                            <button
                                className="btn btn-alert report-form-delete-button notosanskr-400 font-size-16"
                                type="button" onClick={tempReportRemoveOnClick}>
                                {t('85')}
                            </button>
                        </Col>
                    }
                    <Col className="col-sm-6 col-6 col-md-auto report-form-snapshot-button-order">
                        <button
                            className="btn btn-primary report-form-snapshot-button notosanskr-400 font-size-16"
                            type="button" onClick={tempReportSaveOnClick}>
                            {t('635')}
                        </button>
                    </Col>
                    <Col className="col-sm-12 col-12 col-md-auto report-form-submit-button-order">
                        <button
                            className="btn btn-primary report-form-submit-button notosanskr-400 font-size-16"
                            type="button" onClick={submitOnClick}>
                            {!isModifying ? t('158') : t('537')}
                        </button>
                    </Col>
                </Row>
            }
        </Container>
    );
}
