import React, { useImperativeHandle, useCallback } from 'react';
import WithValueHandle from "./WithValueHandle";
import i18n from 'i18next';

const WithDateHandle = (WrapperComponent) => {
    const WithDate = ({ ...otherProps }, ref) => {
        const dateValidation = useCallback(({ value, required, errorKey }) => {
            let errorCollection = { [errorKey]: { state: true, message: "" } };
            let valid = true;

            if (required) {
                valid = Boolean(value);
                errorCollection = { [errorKey]: { state: valid, message: valid ? "" : i18n.t('15') } };
            }

            // valid가 true이고, 하위 컴포넌트의 validation function 이 있는 경우
            if (valid && ref.current.childValidation) {
                ({ valid, errorCollection } = ref.current.childValidation({ value, errorKey }));
            }

            return { valid, errorCollection };
        }, [ref]);

        useImperativeHandle(ref, () => (
            { ...ref.current, dateValidation }
        ), [ref, dateValidation]);

        return (
            <WrapperComponent ref={ref} {...otherProps} />
        );
    };

    return WithValueHandle(React.forwardRef(WithDate));
};

export default WithDateHandle;
